import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect  } from 'react-redux';
import { getProduct } from '../../actions/product';
const ProductCard = (props) =>{

    const [currentProduct, setCurrentProduct] = useState(false);
    const onClick = (e,id) => {
        props.getProduct(id)
        setCurrentProduct(id);
    }
    if(currentProduct!==false){
        return <Redirect to={`/inventory/v1.2/products/${currentProduct}`} />
        // return <Redirect to={`/products/product`} />
    }
    return(   
        <div className="col-xl-3 col-md-6 mb-4" style={{'cursor':'pointer'}} onClick={e => onClick(e,props.product._id) }>
            <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
                <div className="row no-gutters align-items-center" style = {{textAlign: 'center'}}>
                <div className="col mr-2">
                {/* src={props.product.image} */}
                    <div className="product-image">
                        <img 
                            style={{"maxWidth" : "100%","maxHeight" : "100px"}} alt="product image"
                            src={`${process.env.REACT_APP_SERVER_PUBLIC_PATH}${process.env.REACT_APP_IMAGE_PATH_PRODUCT}/${props.product.image}`}
                        />
                    </div>
                    <div className="font-weight-bold text-primary text-uppercase mt-2">{ props.product.brand}</div>
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                        Price:   <span className='text-primary'>{ props.product.price}</span>
                    </div>
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                    Tagline: <span className='text-primary'>{ props.product.tagline}</span>
                    </div>
                </div>
                <div className="col-auto">
                    <i className="fas fa-machine fa-2x text-gray-300"></i>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}

// product: PropTypes.object,
ProductCard.propTypes = {
    getProduct: PropTypes.func,
}

// const mapStateToProps = state => ({
//     product: state.product.product
// });
export default connect(null, { getProduct } )(ProductCard);
