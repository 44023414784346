import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTransactions } from '../../actions/canTransaction';

// Import React Table
import "bootstrap/dist/css/bootstrap.min.css"

import TableContainer from "./TableContainer"

const Transactions = ({location, transactions, getTransactions, pageList, pages, activePage}) => {

    const initialState = {
        vendingMachineId: '',
        startDate:'',
        endDate:'',
        phone:'',
        transactionId:'',
        latest: true
    };

    const [formData, setFormData] = useState(initialState);
    const [data, setData] = useState([])
    const [pageCount, setPage] = useState(0);
    const [currentPage, setActivePage] = useState(1);
    const [queryString, setQueryString] = useState("page=1&latest=true");

    useEffect(() => {
        if(transactions === undefined){
            let query = queryString;
            if(location.search){
                let phoneNumber = location.search.split("=");
                if(phoneNumber.length === 2 & phoneNumber[1].length === 10){
                    query += "&phone=" + phoneNumber[1];
                }
            }
            getTransactions(query);
        }else{
            let transactionData = [];
            transactions.forEach(transaction => {
                transactionData.push({
                    status: transaction.status,
                    returnCan: transaction.returnCan ? "Yes" : "No",
                    _id: transaction._id,
                    containerOpen: transaction.containerOpen ? "Yes" : "No",
                    containerId: transaction.containerId,
                    transactionId: transaction.transactionId,
                    time: new Date(transaction.time).toString().split("GMT")[0],
                    brand: transaction.product.brand,
                    brandId: transaction.product._id,
                    vendingMachineId: transaction.vendingMachine._id,
                    vendingMachineName: transaction.vendingMachine.name,
                    product: transaction.brand,
                    userName: transaction.user ? transaction.user.name : "deleted",
                    userId: transaction.user ? transaction.user._id : "deleted",
                    phone: transaction.user ? transaction.user.phone : "deleted",
                });
            });
            setQueryString(queryString.replace("page=" + currentPage, "page=" + activePage));
            setData(transactionData);
            setPage(pages);
            setActivePage(activePage);
        }
    }, [ transactions ] );


    const columns = useMemo(
        () => [
            {
                Header: 'Transaction Id',
                accessor: 'transactionId',
                disableSortBy: true,
            },
            {
                Header: 'User',
                accessor: 'userName',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Vending Machine',
                accessor: 'vendingMachineName',
            },{
                Header: 'Product',
                accessor: 'product',
            },
            {
                Header: 'Container Id',
                accessor: 'containerId',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },           
            {
                Header: 'Return Can',
                accessor: 'returnCan',
            },
            {
                Header: 'Container Open',
                accessor: 'containerOpen',
            },                     
            {
                Header: 'Time',
                accessor: 'time',
            },
        ],
        []
    );

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const {
        vendingMachineId,
        startDate,
        endDate,
        phone,
        transactionId,
        latest
    } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {
        e.preventDefault();
        const startDate = new Date(formData.startDate).getTime();
        const endDate = new Date(formData.endDate).getTime();
        if(endDate < startDate){
            return alert("Invalid date")
        }
        let queryString = "page=1&latest=" + formData.latest;
        if(formData.startDate && formData.endDate){
            queryString += "&startDate=" + formData.startDate + "&endDate=" + formData.endDate;
        }
        if(formData.vendingMachineId){
            queryString += "&vendingMachine=" + formData.vendingMachineId;
        }
        if(formData.transactionId){
            queryString += "&transactionId=" + formData.transactionId;
        }
        if(formData.phone){
            queryString += "&phone=" + formData.phone;
        }
        setQueryString(queryString);
        getTransactions(queryString);
    };

    const seachForm = () => {
        return(
            <form style={{width:"100%"}} onSubmit={ e => onSubmit(e) }>
                <div className="form-row">
                    <div className="col-4">
                        <input name='vendingMachineId' value={vendingMachineId} onChange={e => onChange(e)}
                        type="text" className="form-control" placeholder="Vending Machine ID"/>
                    </div>
                    <div className="col-4">
                        <input name='transactionId' value={transactionId} onChange={e => onChange(e)}
                        type="text" className="form-control" placeholder="Transaction ID"/>
                    </div>
                    <div className="col-4">
                        <input name='phone' value={phone} onChange={e => onChange(e)}
                        type="text" className="form-control" placeholder="Phone"/>
                    </div>
                    <div className="col-3">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">Start Date</label>
                        <input name='startDate' value={startDate} onChange={e => onChange(e)}
                        type="date" className="form-control" placeholder="Start Date"/>
                    </div>
                    <div className="col-3">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">End Date</label>
                        <input name='endDate' value={endDate} onChange={e => onChange(e)}
                        type="date" className="form-control" placeholder="End Date"/>
                    </div>
                    <div className="col-3">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">View By</label>
                        <select name='latest' value={latest} type="text" className="custom-select mr-sm-2"
                            onChange={e => onChange(e)}>
                            <option value="false">Old</option>
                            <option value="true">New</option>
                        </select>
                    </div>
                    <div className="col-3" style={{display: "flex",alignItems: "flex-end"}}>
                        <input type='submit' className='btn btn-primary mx-auto mt-2'/>
                    </div>
                </div>
            </form>
        )
    }

    const renderTransactionData = () => {        
        if(data !== undefined && data.length > 0 ){
            return(
                <TableContainer columns={columns} data={data} pages={pageCount} activePage={currentPage} queryString={queryString} getTransactions={getTransactions} />
            );
        }
    }

    return (
        <Fragment>
        <div className="container-fluid">
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'>Transactions</h3>
            </div>
            <div className='d-flex  mt-5 flex-wrap'>
                {  seachForm() }
            </div>
            <div className='d-flex  mt-5 flex-wrap'>                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Transactions</h6>
                    </div>                    
                    <div className="card-body">
                        <div className="table-responsive">
                            { renderTransactionData() }
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        </Fragment>
    )
}

Transactions.PpropTypes = {
    getTransactions : PropTypes.func.isRequired,
    transactions : PropTypes.array,
    pages : PropTypes.Number,
    activePage : PropTypes.Number
}

const mapStateToProps = state => ({
    transactions: state.canTransaction.transactions,
    pages: state.canTransaction.pages,
    pageList: state.canTransaction.pageList,
    activePage: state.canTransaction.activePage,
    loading: state.canTransaction.loading
})

export default connect( mapStateToProps, { getTransactions } )( Transactions );