import {
    GET_REPORT,
    DOWNLOAD_PING_REPORT,
    LIST_REGISTERED_VENDING_MACHINE_PRODUCT,
    SESSION_ID_FOR_TESTING,
    PING_DATA_DOWNLOAD,
    LOCK_DATA_DOWNLOAD,
    SESSION_RESULTS
} from '../actions/types';

const initialState = {
    results: null,
    loading: false,
    sessionId: "",
    lockData: [],
    pingData: [],
    sessionResults: []
};

export default ( state = initialState, { type, payload }) => {
    switch (type) {
        case LIST_REGISTERED_VENDING_MACHINE_PRODUCT:
            return {
                ...state,
                results: payload,
            };
        case SESSION_ID_FOR_TESTING:
            return {
                ...state,
                sessionId: payload
            }
            
        case PING_DATA_DOWNLOAD:
            return {
                ...state,
                pingData: payload
            }
        case LOCK_DATA_DOWNLOAD:
            return {
                ...state,
                lockData: payload
            }
        case SESSION_RESULTS:
            return {
                ...state,
                sessionResults: payload
            }
        default:
            return state;
    }
};