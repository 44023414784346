import axios from 'axios';
import { setAlert } from './alert';
import { 
    GET_ALL_ORDERS,
    GET_ORDER_REQUEST,
    CHECK_PAYMENT
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get all Orders

export const getOrders = (filters) => async dispatch => {

    try {
        const res = await axios.get(`${API_URL}/orders/all?${filters}`);
        if(res.data.success){
            dispatch({
                type: GET_ALL_ORDERS,
                payload: res.data
            });
            dispatch( setAlert('Successfully fetched orders', 'success') );
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth Orders' ) );
    }

}


// Get User Order

export const getOrder = (orderId) => async dispatch => {

    try {
        const res = await axios.get(`${API_URL}/orders/${orderId}`);
        if(res.data.success){
            dispatch({
                type: GET_ORDER_REQUEST,
                payload: res.data.order
            });
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth Orders' ) );
    }
}

// Update order
export const updateOrder = (payload, orderId) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.put(`${API_URL}/orders/${orderId}`, payload, config);
        if(res.data.success){
            dispatch({
                type: GET_ORDER_REQUEST,
                payload: res.data.order
            });
            dispatch(setAlert('Order Updated', 'success', 700));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
            dispatch({
                type: GET_ORDER_REQUEST,
                payload: res.data.order
            });
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update order', 'danger') );
    }
};

// Check Payment
export const verifyPayment = (payload) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res = await axios.put(`${API_URL}/payment/verify-payment`, payload, config);
        if(res.data.success){
            dispatch({
                type: GET_ORDER_REQUEST,
                payload: res.data.order
            });
            dispatch(setAlert('Payment Updated', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
            dispatch({
                type: GET_ORDER_REQUEST,
                payload: res.data.order
            });
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update order', 'danger') );
    }
};