import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAccountDeleteRequests } from '../../actions/accountDeletion';

// Import React Table
import {Container, Row, Col, Table} from "react-bootstrap";
import { Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"

const Deletion = ({deletionList, getAccountDeleteRequests }) => {

    const [queryString, setQueryString] = useState("page=1&latest=true");

    useEffect(() => {
        if(!deletionList){
            let query = queryString;
            setQueryString(query);
            getAccountDeleteRequests(query);
        }
    }, [ deletionList ] );

    const populateAllRequest = () => {
        if(!deletionList){
            return
        }
        return (
            deletionList.map((obj, index) => {
                return <tr style={{textAlign:"center"}} key = { index } >
                    <td>{ index + 1 }</td>
                    <td>{ obj.UserPhoneNumber }</td>
                    <td>{ obj.userId }</td>
                    <td>{ new Date(obj.requestTime).toString().split("GMT")[0] }</td>
                    <td>{ obj.requestProcessed ? "Yes" : "No" }</td>
                </tr>
            })
        );
    }

    return (
        <Fragment>
            <div className="d-flex flex-column justify-content-center">
                <div className='text-primary mt-5 d-flex flex-row'>
                    <Link className='btn btn-light text-primary my-1' to='/'>
                        Back
                    </Link>
                    {/* <h3 className='mx-auto'>Register Vending Machine</h3> */}
                </div>
                <div>
                    <div className='text-primary mt-2 d-flex flex-row'>
                        <h3 className='mx-auto'>Account Deletion Request</h3>
                    </div>
                    <br></br>
                    <Table striped bordered hover>
                        <thead>
                            <tr style={{textAlign:"center"}}>
                                <th>Index</th>
                                <th>Phone</th>                                
                                <th>userId</th>
                                <th>Requested On</th>
                                <th>requestProcessed</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            { populateAllRequest() }
                        </tbody>
                    </Table>
                </div>
            </div>
        </Fragment>
    )
}

Deletion.PpropTypes = {
    getAccountDeleteRequests:PropTypes.func.isRequired,
    accountDeletion: PropTypes.array
}

const mapStateToProps = state => ({
    deletionList: state.accountDeletion.deletionList,
    loading: state.accountDeletion.loading
});

export default connect( mapStateToProps, { getAccountDeleteRequests } )( Deletion );