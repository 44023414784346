import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {registerVendingMachine, updateRegisteredVendingMachine,
    getRegisteredVendingMachine, getAvailableVendingMachines} from '../../actions/vendingMachine';

const initialState = {
    serialNumber: '',
    authorised: false,
    vendingMachine:'',
    vendingMachineId: '',
    name:'',
    version:''
};

const RegistrationDetails = ({
    match,
    getAvailableVendingMachines,
    getRegisteredVendingMachine,
    updateRegisteredVendingMachine,
    registerVendingMachine,
    loading,
    registeredVendingMachine,
    vendingMachinesForRegistration
}) => {
    const [formData, setFormData] = useState(initialState);
    const { state } = useLocation();
    useEffect(() => {
        if(match.params.id !== 'new'){
            if (!registeredVendingMachine || (registeredVendingMachine.serialNumber !== match.params.id)){
                getRegisteredVendingMachine(match.params.id);
            } 
            const vendingMachineDetails = { ...initialState };
            if (registeredVendingMachine) {
                for (const key in registeredVendingMachine) {
                    if (key in vendingMachineDetails) vendingMachineDetails[key] = registeredVendingMachine[key];
                }
                setFormData(vendingMachineDetails);
            }
        }else{
            getAvailableVendingMachines();
        }
    }, [registeredVendingMachine]);
    
    
    const {
        serialNumber,
        authorised,
        vendingMachine,
        vendingMachineId,
        name,
        version
    } = formData;
    
    const isNewRegistration =  (match.params.id==='new')? true : false ;
    const onChange = (e) => {
        if(e.target.name === "vendingMachine"){
            let registrationData = { ...formData}
            for (const vendingMachine of vendingMachinesForRegistration) {
                if(vendingMachine._id === e.target.value){
                    registrationData["vendingMachineId"] = vendingMachine.vendingMachineId;
                    registrationData["name"] = vendingMachine.name
                    registrationData["vendingMachine"] = vendingMachine._id;
                    if(isNewRegistration){
                        registrationData.version = state.version;
                        registrationData.serialNumber = state.serialNumber;
                    }
                    break;
                }
            }
            setFormData(registrationData);
        }else{
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }

    const onSubmit = e => {
        e.preventDefault();
        if(isNewRegistration){
            if(!formData.vendingMachine){
                alert("Select Vending Machine");
                return;
            }
            registerVendingMachine(formData);
        }else{
            updateRegisteredVendingMachine({_id: registeredVendingMachine._id, authorised: formData.authorised} );
        }
    };

    const showVendingMachines = () => {
        return vendingMachinesForRegistration.map((vendingMachine, index) => {
            return (
                <option key={`vm-${index}`} value={vendingMachine._id}>{vendingMachine.name}</option>
            )
        });
    }

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    // return(
    //     <Fragment>
    //         <h3 className='mx-auto'>Register</h3>
    //     </Fragment>
    // )

    return (
        <Fragment>
            <div className='d-flex flex-column justify-content-center'>
                <div className='text-primary d-flex flex-row'>
                    <Link className='btn btn-sm btn-light text-primary '
                    to={isNewRegistration? '/inventory/v1.2/register-vending-machine': '/inventory/v1.2/list-registered-vending-machine' }>
                        Back
                    </Link>
                </div> 
                <div className='text-primary d-flex flex-row'>
                    <h3 className='mx-auto'>Register</h3>
                </div>
                <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "500px"}}>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle} className='mt-2 mr-2'>Seriall  Number</label>
                        <input
                            id='serialNumber'
                            className="form-control form-control-user" 
                            type='text'
                            // placeholder='Stock'
                            name='serialNumber'
                            value={isNewRegistration ? state.serialNumber: serialNumber }
                            readOnly
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Permission</label>
                        <select
                                className="form-control form-control-user"
                                value={authorised}
                                name='authorised'
                                onChange={e => onChange(e) }>
                                    <option value="true">Authorize</option>
                                    <option value="false">Unauthorize</option>
                                </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Vending Machine</label>
                        <select
                            id ='vendingMachine'
                            className="form-control form-control-user" 
                            type ='text'
                            value = {vendingMachine}
                            name ='vendingMachine'
                            onChange={e => onChange(e) }>
                                <option value="">Select Vending Machine</option>
                                { showVendingMachines() }
                        </select>
                        
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>VendingMachine Id</label>
                        <input
                            id='vendingMachineId'
                            className="form-control form-control-user" 
                            type='text'
                            name='vendingMachineId'
                            value={vendingMachineId}
                            readOnly
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Name</label>
                        <input
                            id='name'
                            className="form-control form-control-user" 
                            type='text'
                            name='name'
                            value={name}
                            readOnly
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Version</label>
                        <input
                            id='version'
                            className="form-control form-control-user" 
                            type='text'
                            name='version'
                            value={isNewRegistration? state.version: version}
                            readOnly
                        />
                    </div>
                    
                    {/* { !isNewProduct ?
                        <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'> Product Image</label>
                        <input className="form-control form-control-user"
                            id='image'
                            type='file'
                            name='image'
                            style={{'cursor': 'pointer'}} 
                            onChange={e => onChange(e)}
                        />
                        </div>:null
                    } */}
                    {/* { !isNewProduct && image ?
                        <div className='form-group d-flex mr-2'>
                            <div className='mx-auto' style={{'height': '150px', 'width': '150px' }} >
                                <img
                                    id='image-view' alt="Product Image"
                                    name='image-view'
                                    src={`${ process.env.REACT_APP_SERVER_PUBLIC_PATH }${ process.env.REACT_APP_IMAGE_PATH_PRODUCT  }/${image}`} 
                                    style={{'height': '100%', 'width': '100%'}}
                                />
                            </div>
                        </div>:null
                    } */}
                    <input type='submit' className='btn btn-primary mx-auto mt-2' value={isNewRegistration?'Add':'Update'} />
                </form>
            </div>
        </Fragment>
    );
}
RegistrationDetails.propTypes = {
    registerVendingMachine: PropTypes.func.isRequired,
    updateRegisteredVendingMachine: PropTypes.func.isRequired,
    getRegisteredVendingMachine: PropTypes.func.isRequired,    
    getAvailableVendingMachines: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    vendingMachinesForRegistration: PropTypes.array,
    registeredVendingMachine: PropTypes.object
};

const mapStateToProps = state => ({
    loading: state.product.loading,
    registeredVendingMachine: state.vendingMachine.registeredVendingMachine,
    vendingMachinesForRegistration: state.vendingMachine.vendingMachinesForRegistration
});

export default connect(
    mapStateToProps, 
    { registerVendingMachine, updateRegisteredVendingMachine, getRegisteredVendingMachine, getAvailableVendingMachines}
)(RegistrationDetails);
