import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Card from './Card';

import { createProfile, getProfile, updateProfile, addBrandProfileData } from '../../actions/profile';
import { getProducts } from '../../actions/product';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

const initialState = {
    name: '',
    gst : 0,
    cansLimit: 0,
    deposit: false,
    depositCount: 0
};

const brandDataState = {
    brand: "", product: "", price: 0, productType: "",
    bundle: {
        active: "",
        discountPrice: 0,
        minPurchase: 0,
        maxPurchase: 0,
        incrementBy: 0,
        priority: 0
    },
    unit: {
        active: "",
        discountPrice: 0,
        minPurchase: 0,
        maxPurchase: 0,
        incrementBy: 0,
        priority: 0
    },
}

const Profile = ({
    match,
    createProfile,
    getProfile,
    getProducts,
    getRegions,
    updateProfile,
    addBrandProfileData,
    profile,
    loading,
    products
}) => {
    const [formData, setFormData] = useState(initialState);
    const [brandNewData, setNewData] = useState(brandDataState);
    const [open, setOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [addNewProfile, setAddNewProfile] = useState(true)
    
    const handleOpen = (title, message) => {
        setDialogTitle(title);
        setDialogMessage(message);
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(match.params.id !== 'new'){
            setAddNewProfile(false)
            if(loading){
                let tempData = brandDataState;
                tempData.price = 0;
                tempData.brand = "";
                tempData.product = "";
                ["bundle", "unit"].forEach(type => {
                    tempData[type].active = "";
                    tempData[type].discountPrice = 0;
                    tempData[type].minPurchase = 0;
                    tempData[type].maxPurchase = 0;
                    tempData[type].incrementBy = 0;
                    tempData[type].priority = 0;
                });
                setNewData(tempData);
            }
            if (!profile || (profile._id !== match.params.id) || loading){
                getProfile(match.params.id);
                getProducts();
            } 
            const profileData = { ...initialState };
            if (profile) {
                for (const key in profile) {
                    if (key in profileData) profileData[key] = profile[key];
                }
                setFormData(profileData);
            }
        }
    }, [profile, products, loading]);

    const {
        name,
        gst,
        cansLimit,
        deposit,
        depositCount
    } = formData;

    const isNewProfile =  (match.params.id==='new')? true : false ;
    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onBrandDataChanged = (e, productType) => {
        if(!productType){
            let data = { ...brandNewData };
            data[e.target.name] =  e.target.name === "price" ?  Number(e.target.value) : e.target.value;
            if(e.target.name === "product"){
                data["brand"] = e.target[e.target.selectedIndex].text;
                data["productType"] = products[e.target.selectedIndex -1].productType;
            }
            setNewData(data);
        }else{
            let data = { ...brandNewData };
            data[productType][e.target.name] = e.target.name === "active"?  (e.target.value === 'true') : Number(e.target.value);
            setNewData(data);
        }
    }

    const onSubmit = e => {
        e.preventDefault();
        if(!formData.deposit){
            alert("Invalid Deposit Selected")
            return true;
        }
        if(isNewProfile){
            createProfile(formData );
        }else{
            updateProfile( profile._id, formData );
        }
    };

    const validate = e => {
        //Check brand
        if(!brandNewData.product){
            return [false, "Brand", "Need to select valid brand"];
        }

        //Check if brand already added
        if(profile && brandNewData.product){
            let brandAssigned = profile.brands.some(brand => brand.product === brandNewData.product);
            if(brandAssigned){
                return [false, "Brand", brandNewData.brand + " already added"];
            }
        }
        if(brandNewData.bundle.active === "" || brandNewData.unit.active === ""){
            return [false, "Status", "Select valid status"];
        }

        if(brandNewData.bundle.minPurchase > brandNewData.bundle.maxPurchase ||
            brandNewData.unit.minPurchase > brandNewData.unit.maxPurchase){
            return [false, "Max Purchase", "Should be greater than minimum purchase"];
        }

        if(brandNewData.bundle.discountPrice > brandNewData.price ||
            brandNewData.unit.discountPrice > brandNewData.price){
                return [false, "Discount Price", "Should be lesser than product price"];
            }
        return [true, "", ""];
    }

    const addBrandData = e => {
        e.preventDefault();
        const [isValid, title, message] = validate();
        if(!isValid){
            handleOpen(title, message)
        }else{
            addBrandProfileData(profile._id, brandNewData);
        }
    };

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const populateBrandData = () => {
        if(!isNewProfile && profile._id === match.params.id){
            if(Object.keys(profile).length){
                return profile.brands.map((brand, index) => {
                    return (
                        <Card key={index} brand={brand} profileId={profile._id} handleOpen={handleOpen} brands={profile.brands} />
                    );
                });
            }            
        }
    }

    const showProducts = () => {
        return products.map((product, index) => {
            return (
                <option key={`product-${index}`} value={product._id}>{product.brand}</option>
            )
        });
    }

    return (
        <Fragment>
            <div className='d-flex flex-column justify-content-center'>
                <div className='text-primary d-flex flex-row'>
                    <Link className='btn btn-sm btn-light text-primary ' to='/inventory/v1.2/profiles'>
                        Back
                    </Link>
                </div>
                <div className='text-primary d-flex flex-row'>
                    <h3 className='mx-auto'>Profile { addNewProfile ? "":"-" + profile.name }</h3>
                </div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                        {dialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Ok
                        </Button>                        
                    </DialogActions>
                </Dialog>
                <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } >
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle} className='mt-2 mr-2'>Name</label>
                        <input
                            id='name'
                            className="form-control form-control-user" 
                            type='text'
                            placeholder='Profile Name'
                            name='name'
                            value={name}
                            onChange={e => onChange(e) }
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>GST</label>
                        <input
                            id='gst'
                            className="form-control form-control-user" 
                            type='text'
                            name='gst'
                            value={gst}
                            onChange={e => onChange(e) }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Cans Limit</label>
                        <input
                            id='cansLimit'
                            className="form-control form-control-user" 
                            type='text'
                            name='cansLimit'
                            value={cansLimit}
                            onChange={e => onChange(e) }
                        />
                    </div>
                    <div className="form-group d-flex mr-2">
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Deposit</label>
                        <select
                            className="form-control form-control-user"
                            value={deposit}
                            name='deposit'
                            onChange={e => onChange(e) }>
                                <option disabled value="">Select Free Deposit</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Deposit Count</label>
                        <input
                            id='depositCount'
                            className="form-control form-control-user"
                            type='text'
                            name='depositCount'
                            value={depositCount}
                            onChange={e => onChange(e) }
                        />
                    </div>
                    
                    <input type='submit' className='btn btn-primary mx-auto mt-2' value={isNewProfile?'Add':'Update'} />
                </form>
                { !isNewProfile ?
                    <div className='manage-profile'>
                        <div style={{marginTop: "3%", marginBottom: "0%", textAlign: "center"}}>
                            <h4> Manage Profile</h4>
                        </div>
                        <div className='d-flex  mt-5 flex-wrap'>
                            { populateBrandData() }
                            <div style={{width: "25%", padding: "2%"}}>
                                <div className="card border-left-primary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="no-gutters align-items-center">
                                            <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => addBrandData(e) } >
                                                <div className="form-group d-flex">
                                                    <label className='mt-2 mr-2'>Brand :</label>
                                                    <select style={{ width: '150px' }}
                                                        className="form-control form-control-user ml-auto"
                                                        value={brandNewData.product}
                                                        name='product'
                                                        onChange={e => onBrandDataChanged(e, null) }>
                                                            <option value="">Select Product</option>
                                                            { showProducts() }
                                                    </select>
                                                </div>

                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Price</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='price'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Price'
                                                        name='price'
                                                        value={brandNewData.price}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, null) }
                                                    />
                                                </div>
                                                {/* Bundle */}
                                                <div style={{textAlign: "center", margin: "20px" }}>
                                                    <h5>Bundle</h5>
                                                </div>
                                                <div className="form-group d-flex">
                                                    <label className='mt-2 mr-2'>Status</label>
                                                    <select
                                                        style={{ "width" : "150px" }}
                                                        className="form-control form-control-user ml-auto"
                                                        value={brandNewData.bundle.active}
                                                        name='active'
                                                        onChange={e => onBrandDataChanged( e, "bundle" ) }>
                                                            <option value="">Select Status</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Dissable</option>
                                                    </select>
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Discount Price</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='bundleDiscountPrice'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Discount Price'
                                                        name='discountPrice'
                                                        min='0'
                                                        value={brandNewData.bundle.discountPrice}
                                                        onChange={ e => onBrandDataChanged(e, "bundle")}
                                                    />
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Min Purchase</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='bundleMinPurchase'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Min Purchase'
                                                        name='minPurchase'
                                                        value={brandNewData.bundle.minPurchase}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, "bundle")}
                                                    />
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Max Purchase</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='bundleMaxPurchase'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Max Purchase'
                                                        name='maxPurchase'
                                                        value={brandNewData.bundle.maxPurchase}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, "bundle")}
                                                    />
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Increment By</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='bundleIncrementBy'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Increment'
                                                        name='incrementBy'
                                                        value={brandNewData.bundle.incrementBy}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, "bundle")}
                                                    />
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Priority</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='priority'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Priority'
                                                        name='priority'
                                                        value={brandNewData.bundle.priority}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, "bundle")}
                                                    />
                                                </div>

                                                {/* Unit */}
                                                <div style={{textAlign: "center", margin: "20px" }}>
                                                    <h5>Unit</h5>
                                                </div>
                                                <div className="form-group d-flex">
                                                    <label className='mt-2 mr-2'>Status</label>
                                                    <select 
                                                        style={{ "width" : "150px" }}
                                                        className="form-control form-control-user ml-auto"
                                                        value={brandNewData.unit.active}
                                                        name='active'
                                                        onChange={e => onBrandDataChanged( e, "unit") }>
                                                            <option value="">Select Status</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Dissable</option>
                                                    </select>
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Discount Price</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='unitDiscountPrice'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Discount Price'
                                                        name='discountPrice'
                                                        value={brandNewData.unit.discountPrice}
                                                        min='0'
                                                        onChange={ e => onBrandDataChanged(e, "unit")}
                                                    />
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Min Purchase</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='unitMinPurchase'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Min Purchase'
                                                        name='minPurchase'
                                                        value={brandNewData.unit.minPurchase}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, "unit")}
                                                    />
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Max Purchase</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='unitMaxPurchase'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Max Purchase'
                                                        name='maxPurchase'
                                                        value={brandNewData.unit.maxPurchase}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, "unit")}
                                                    />
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Increment By</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='unitIncrementBy'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Increment'
                                                        name='incrementBy'
                                                        value={brandNewData.unit.incrementBy}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, "unit")}
                                                    />
                                                </div>
                                                <div className='form-group d-flex'>
                                                    <label className='mt-2 mr-2'>Priority</label>
                                                    <input
                                                        style={{ 'width' : '150px' }}
                                                        id='unitPriority'
                                                        className="form-control form-control-user ml-auto"
                                                        type='number'
                                                        placeholder='Priority'
                                                        name='priority'
                                                        value={brandNewData.unit.priority}
                                                        min='1'
                                                        onChange={ e => onBrandDataChanged(e, "unit")}
                                                    />
                                                </div>
                                                <input type='submit' className='btn btn-primary mx-auto mt-2' value="Add Brand" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        </Fragment>
    );
}

Profile.propTypes = {
    createProfile: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    getProducts : PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    updateProfile: PropTypes.func.isRequired,
    addBrandProfileData: PropTypes.func.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = state => ({
    loading: state.profile.loading,
    profile: state.profile.profile,
    products: state.product.products
});

export default connect( mapStateToProps, { createProfile, getProfile, getProducts, updateProfile, addBrandProfileData } )(Profile);
