import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getVendingMachineRegisterRequests, registerVendingMachine,
     deleteVendingMachineRequest } from '../../actions/vendingMachine';

// Import React Table
import {Container, Row, Col, Table} from "react-bootstrap";
import { Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"

const RegisterVM = ({ getVendingMachineRegisterRequests, deleteVendingMachineRequest, registrationList }) => {

    useEffect(() => {
        if(registrationList === null){
            getVendingMachineRegisterRequests();
        }
    }, [ registrationList ] );

    const deleteRequest = (e, serialNumber) => {
        deleteVendingMachineRequest({"serialNumber": serialNumber});
    }

    const registerRequest = (e, serialNumber) => {
        console.log(serialNumber);
    }

    const populateAllRequest = () => {
        if(registrationList === null){
            return
        }
        return (
            registrationList.map((obj, index) => {
                return <tr style={{textAlign:"center"}} key = { index } >
                    <td>{ obj.serialNumber}</td>
                    <td>{ obj.version }</td>
                    <td>{ obj.dateTime }</td>
                    <td>
                        {/* <Button className={"btn btn-primary shadow-sm ml-auto btn-success"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => registerRequest(e, obj.serialNumber) }>
                            Register
                        </Button> */}
                        <Link className="btn btn-primary" to={{pathname: "/inventory/v1.2/list-registered-vending-machine/new",
                            state:{serialNumber: obj.serialNumber, version: String(obj.version)}}}>
                            Register
                        </Link>
                    </td>
                    <td>
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-danger"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => deleteRequest(e, obj.serialNumber) }>
                            Delete
                        </Button>
                    </td>
                </tr>
            })
        );
    }

    return (
        <Fragment>
            <div className="d-flex flex-column justify-content-center">
                <div className='text-primary mt-5 d-flex flex-row'>
                    <Link className='btn btn-light text-primary my-1' to='/'>
                        Back
                    </Link>
                    {/* <h3 className='mx-auto'>Register Vending Machine</h3> */}
                </div>
                <div>
                    <div className='text-primary mt-2 d-flex flex-row'>
                        <h3 className='mx-auto'>Register Vending Machine</h3>
                    </div>
                    <br></br>
                    <Table striped bordered hover>
                        <thead>
                            <tr style={{textAlign:"center"}}>
                                <th>Serial Number</th>
                                <th>Version</th>
                                <th>Date</th>
                                <th>Register</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            { populateAllRequest() }
                        </tbody>
                    </Table>
                </div>
            </div>
        </Fragment>
    )
}

RegisterVM.PpropTypes = {
    getVendingMachineRegisterRequests:PropTypes.func.isRequired,
    registerVendingMachine:PropTypes.func.isRequired,
    deleteVendingMachineRequest: PropTypes.func.isRequired,
    registrationList: PropTypes.array
}

const mapStateToProps = state => ({
    registrationList: state.vendingMachine.registrationList,
    loading: state.vendingMachine.loading
});

export default connect( mapStateToProps, { getVendingMachineRegisterRequests,
     registerVendingMachine, deleteVendingMachineRequest } )( RegisterVM );