import { 
    GET_ORDER_REQUEST,
    GET_ALL_ORDERS
} from '../actions/types';

const initialState = {
    orders: undefined,
    order: null,
    loading: true,
    pageList : [],
    activePage: 0,
    pages: 0
};

export default ( state = initialState, { type, payload }) => {
    switch (type) {
        case GET_ALL_ORDERS:
            return {
                ...state,
                orders: payload.orders,
                pages: payload.pages,
                pageList: payload.pageList,
                activePage: payload.activePage,
                loading: false
            };

        case GET_ORDER_REQUEST:
            return {
                ...state,
                order: payload,
                loading: false
            };

        default:
            return state;
    }
};