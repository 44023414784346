import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect  } from 'react-redux';
import { getRegion } from '../../actions/region';

const RegionCard = (props) =>{

    const [currentRegion, setCurrentRegion] = useState(false);
    const onClick = (e,id) => {
        props.getRegion(id)
        setCurrentRegion(id);
    }
    if(currentRegion!==false){
        return <Redirect to={`/regions/${currentRegion}`} />
    }
    return(
        <div className="col-xl-3 col-md-6 mb-4" style={{'cursor':'pointer'}} onClick={e => onClick(e, props.region._id) }>
            <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
                <div className="row no-gutters align-items-center" style = {{textAlign: 'center'}}>
                <div className="col mr-2">                            
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                        <span className='text-primary'>{ props.region.region}</span>
                    </div>                    
                </div>
                <div className="col-auto">
                    <i className="fas fa-machine fa-2x text-gray-300"></i>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}

RegionCard.propTypes = {
    getRegion: PropTypes.func,
}

export default connect(null, { getRegion } )(RegionCard);
