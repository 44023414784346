import { 
    GET_ORDERS_DOWNLOAD,
    GET_TRANSACTIONS_DOWNLOAD
 } from '../actions/types';


 const initialState = {
    loading: true,
    transactions: [],
    orders: []
};

export default function( state = initialState, action){
    const { type, payload } = action;
    switch (type) {
        case GET_ORDERS_DOWNLOAD:
            return{
                ...state,
                orders : payload,
                transactions : [],
                loading : false,
            };
        case GET_TRANSACTIONS_DOWNLOAD:
            return{
                ...state,
                transactions : payload,
                orders : [],
                loading : false
            };        
        default:
            return state;
    }
}