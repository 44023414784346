import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import vendingMachine from './vendingMachine'
import product from './product'
import order from './order';
import user from './user';
import profile from './profile';
import region from './region';
import inventory from './inventory';
import canTransaction from './canTransaction';
import penalty from './penalty';
import downloads from './downloads';
import transactionLedger from './transactionLedger';
import test from './test';
import sales from './sales';
import accountDeletion from './accountDeletion';

export default combineReducers({
    alert,
    auth,
    vendingMachine,
    product,
    order,
    user,
    profile,
    region,
    inventory,
    canTransaction,
    penalty,
    downloads,
    transactionLedger,
    test,
    sales,
    accountDeletion
});