import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken'

import {
    GET_PROFILE,
    GET_PROFILES,
    GET_PROFILE_REQUEST,
    UPDATE_PROFILE_DATA
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get Profiles
export const getProfiles = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    dispatch({
        type : GET_PROFILE_REQUEST,
        payload: true
    });
    
    
    try {
        const res =  await axios.get(`${API_URL}/product-profiles/profiles`);

        if(res.data.success){            
            dispatch({
                type : GET_PROFILES,
                payload: res.data.productProfiles
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed to fetch Products', 'danger'));
    }
}

// Get a Profile
export const getProfile = id => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    dispatch({
        type : GET_PROFILE_REQUEST,
        payload: true
    });
    try {
        const res =  await axios.get(`${API_URL}/product-profiles/${id}`);
  
        if(res.data.success){  
            dispatch({
                type : GET_PROFILE,
                payload: res.data.productProfile
            });
            dispatch( setAlert('Successfully fetched Product details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get Product details', 'danger'));
    }
};


// Update Brand Profile Data
export const updateBrandProfileData = (profileId, objectId, formData) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.put(`${API_URL}/product-profiles/${profileId}/${objectId}`, formData, config);
        if(res.data.success){ 
            dispatch(setAlert('Profile Data Updated', 'success'));
            dispatch(getProfile(profileId));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to update product', 'danger') );
    }
};

// Create profile
export const createProfile = (formData) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.post(`${API_URL}/product-profiles`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Profile Created', 'success'));
        }else{
            dispatch(setAlert(res.data.error, 'danger'));
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update product', 'danger') );
    }
};

// Update profile
export const updateProfile = (id, formData) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };  
    try {
        const res =  await axios.put(`${API_URL}/product-profiles/${id}`, formData, config);
        if(res.data.success){ 
            dispatch(setAlert('Profile Updated', 'success'));
            dispatch(getProfile(id));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to update product', 'danger') );
    }
};



// Update profile
export const deleteBrandProfileData = (profileId, objectId,) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };  
    try {
        const res =  await axios.delete(`${API_URL}/product-profiles/${profileId}/${objectId}`, config);
        if(res.data.success){
            dispatch(setAlert('Profile Data Deleted', 'success'));
            dispatch(getProfile(profileId));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update product', 'danger') );
    }
};

// Add Brand Profile Data
export const addBrandProfileData = (profileId, profileData) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.post(`${API_URL}/product-profiles/${profileId}`, profileData, config);
        if(res.data.success){
            dispatch({
                type : UPDATE_PROFILE_DATA
            });
            dispatch(setAlert('Profile Data Added', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update product', 'danger') );
    }
};
