import {
    GET_ACCOUNT_DELETION_REQUEST
} from '../actions/types';

const initialState = {
    accountDeletion: undefined,
    loading: true,
    pageList : [],
    activePage: 0,
    pages: 0
};

export default ( state = initialState, { type, payload }) => {
    switch (type) {

        case GET_ACCOUNT_DELETION_REQUEST:
            return {
                ...state,
                deletionList: payload.requests,
                pages: payload.pages,
                pageList: payload.pageList,
                activePage: payload.activePage,
                loading: false
            };
        default:
            return state;
    }
};