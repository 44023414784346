import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken'
import {
    ADD_VENDING_MACHINE,
    GET_VENDING_MACHINE,
    GET_ALL_VENDING_MACHINES,
    GET_VENDING_MACHINE_STOCK,
    GET_VENDING_MACHINE_LIVE_STATUS,
    VENDING_MACHINE_REGISTRATION,
    LIST_REGISTERED_VENDING_MACHINE,
    AVAILABLE_VENDING_MACHINE,
    REGISTERED_VENDING_MACHINE
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get all vending machine
export const getVendingMachines = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.get(`${API_URL}/vending-machines`,config);
        if(res.data.success){  
            dispatch( setAlert('Success', 'success') );          
            dispatch({
                type : GET_ALL_VENDING_MACHINES,
                payload: res.data.vendingMachines
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed to get vending meachines detials', 'danger'));
    }
}

// Get a vending machine
export const getVendingMachine = id => async dispatch => {
    try {
        const res =  await axios.get(`${API_URL}/vending-machines/${id}`);
        if(res.data.success){     
            dispatch({
                type : GET_VENDING_MACHINE,
                payload: res.data.vendingMachine
            });
            dispatch( setAlert('Successfully fetched Vending Machine details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get vending machine details', 'danger'));
    }
};


// Add Vending Machine
export const addVendingMachine = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }

    try {
        const res =  await axios.post(`${API_URL}/vending-machines`, formData, config);
        if(res.data.success){
            dispatch({
                type: ADD_VENDING_MACHINE,
                payload: res.data.data
            });
            dispatch(setAlert('Vending Machine Registered', 'success'));
            dispatch(getVendingMachines());
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to register a vending machine', 'danger') );
    }
};


export const updateVendingMachine = (id,formData) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };  
    try {
        const res =  await axios.put(`${API_URL}/vending-machines/${id}`, formData, config);
        if(res.data.success){            
            // dispatch({
            //     type: UPDATE_VENDING_MACHINE,
            //     payload: res.data.data
            // });
            dispatch(setAlert('Vending Machine Updated', 'success'));
            dispatch(getVendingMachines());
            dispatch(getVendingMachine(id));

        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to update vending machine', 'danger') );
    }
};

//Get VM stock info
export const getVendingMachinesStock = id => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.get(`${API_URL}/vending-machines/stock?vendingMachine=${id}`,config);
        if(res.data.success){  
            dispatch( setAlert('Success', 'success') );          
            dispatch({
                type : GET_VENDING_MACHINE_STOCK,
                payload: res.data
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed to get vending machines detials', 'danger'));
    }
};

// Get active vending machine's live status
export const getVendingMachineLiveStatus = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.get(`${API_URL}/vending-machines/live-status`, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : GET_VENDING_MACHINE_LIVE_STATUS,
                payload: res.data.liveStatus
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed to get vending machines status', 'danger'));
    }
}

// Get vending machine's which are to be registered from RedisDB
export const getVendingMachineRegisterRequests = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.get(`${API_URL}/vending-machines/register-request`, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : VENDING_MACHINE_REGISTRATION,
                payload: res.data.allRequests
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed to get vending machines list', 'danger'));
    }
}

// Get vending machine's which are already registered
export const getAllRegisteredVendingMachines = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.get(`${API_URL}/vending-machines/all-registered-vending-machines`, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : LIST_REGISTERED_VENDING_MACHINE,
                payload: res.data.vendingMachines
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed to get vending machines list', 'danger'));
    }
}

// Register A Vending Machine To IMEI Serial Code
export const registerVendingMachine = (formData) => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.post(`${API_URL}/vending-machines/register-request`, formData, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : REGISTERED_VENDING_MACHINE,
                payload: res.data.vendingMachine
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed / Already Registered', 'danger'));
    }
}

// Remove Vending Machine Register Request From redisDB
export const deleteVendingMachineRequest = (formData) => async dispatch => {
    const config = { headers: { 'Content-Type': 'application/json' }, data: {serialNumber: formData.serialNumber} };
    try {
        const res =  await axios.delete(`${API_URL}/vending-machines/register-request`, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : VENDING_MACHINE_REGISTRATION,
                payload: res.data.allRequests
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to remove vending machine request', 'danger') );
    }
};

// Update Registered Vending Machine To IMEI Serial Code
export const updateRegisteredVendingMachine = (formData) => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.put(`${API_URL}/vending-machines/registered-vending-machine`, formData, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : VENDING_MACHINE_REGISTRATION,
                payload: res.data.vendingMachine
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to remove vending machine request', 'danger') );
    }
};

// Get Registered Vending Machine From DB
export const getRegisteredVendingMachine = (serialNumber) => async dispatch => {
    // const config = { headers: { 'Content-Type': 'application/json' }, data: {serialNumber: serialNumber} };
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.get(`${API_URL}/vending-machines/registered-vending-machine?serialNumber=${serialNumber}`, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : REGISTERED_VENDING_MACHINE,
                payload: res.data.vendingMachine
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to remove vending machine request', 'danger') );
    }
};

// Delete Registered Vending Machine From DB
export const deleteRegisteredVendingMachine = (formData) => async dispatch => {
    const config = { headers: { 'Content-Type': 'application/json' }, data: {serialNumber: formData.serialNumber} };
    try {
        const res =  await axios.delete(`${API_URL}/vending-machines/registered-vending-machine`, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : LIST_REGISTERED_VENDING_MACHINE,
                payload: res.data.vendingMachines
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to remove vending machine request', 'danger') );
    }
};

// Get Available Vending Machines For Registration
export const getAvailableVendingMachines = () => async dispatch => {
    try {
        const res =  await axios.get(`${API_URL}/vending-machines/available-vending-machines-registration`);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : AVAILABLE_VENDING_MACHINE,
                payload: res.data.vendingMachines
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to remove vending machine request', 'danger') );
    }
};
