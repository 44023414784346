import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addUser, getUser, updateUser, addNote,
    updateDeposit, updateCans, updateCansLimit } from '../../actions/user';
import { Row, Col, Table, Button} from "react-bootstrap";

const initialState = {
    name: '',
    role:'',
    phone: '',
    email: '',
    hasPenalty: false,
    suspend: false,
};

const depositInitialState = {deposit:0, securityDeposit:0, depositBought:0,
    depositUsed:0, collectSecurityDeposit: false, freeDeposit:0, receivedFreeDeposit: false };

const User = ( props ) => {
    const [ formData, setFormData ] = useState(initialState);
    const [ depositData, setDepositData ] = useState(depositInitialState);
    const [ cansData, setCansData ] = useState({});
    const [ penaltyData, setPenaltyData ] = useState({});
    const [ notes, setNotes ] = useState("");
    const [ cansLimit, setCansLimit ] = useState(0);
    const [ openedCans, setOpenedCans ] = useState(0);

    useEffect(() => {
        if( props.match.params.id !== 'new' ){
            if(!props.user ||  props.user._id !== props.match.params.id){
                // Get User details of a id
                props.getUser( props.match.params.id )
            }
            setFormData({ ...props.user });
            setDepositData({ ...props.deposit });
            setPenaltyData({ ...props.penalties });
            if( props.cans ){
                let cans = {}
                props.cans.current.vendingMachines.map((obj) => {
                    let brandObj = { name: obj.vendingMachine.name, brands:{} };
                    obj.brands.map((brand) => {
                        brandObj.brands[brand._id] = {brand: brand.brand, product: brand.product, totalCans: brand.totalCans, difference: 0};
                    });
                    cans[obj._id] = brandObj;
                });
                setCansData(cans);
                setCansLimit(props.cans.cansLimit);
                setOpenedCans(props.cans.openedCans);
            }
            setNotes(props.note);
        }
    }, [ props.user, props.cans ]);

    const {
        name,
        role,
        phone,
        email,
        hasPenalty,
        suspend,
    } = formData;

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const { deposit, securityDeposit, depositBought, depositUsed,
        collectSecurityDeposit, receivedFreeDeposit, freeDeposit } = depositData;

    const isNewUser  =  (props.match.params.id==='new')? true : false ;
    
    const onChange = (e, type) =>{
        if(type === "deposit"){
            if(e.target.name === "collectSecurityDeposit" || e.target.name === "receivedFreeDeposit"){
                setDepositData({ ...depositData, [e.target.name]: Boolean(e.target.value)});
            }else{
                setDepositData({ ...depositData, [e.target.name]: e.target.value === "" ? 0 : Number(e.target.value) });
            }
        }else{
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }

    const onChangeCansLimitData = (e, type) => {
        if(type === "cansLimit"){
            setCansLimit(e.target.value);
        }else{
            setOpenedCans(e.target.value);
        }
    }

    const onChangeNote = (e) =>{
        setNotes(e.target.value);
    }

    const onCanChange = (e, brandId, vendingMachineId) => {
        let cans = { ...cansData };
        cans[vendingMachineId]["brands"][brandId].totalCans = Number(e.target.value);
        setCansData(cans);
    }

    const updateCanData = (brandId, vendingMachineId, cans, vmArrayIndex, brandIndex) => {
        let prevCanCount = props.cans.current.vendingMachines[vmArrayIndex]["brands"][brandIndex].totalCans;
        let diffrences = cans - prevCanCount;
        const brand = props.cans.current.vendingMachines[vmArrayIndex].brands[brandIndex].product;
        const vendingMachine = props.cans.current.vendingMachines[vmArrayIndex].vendingMachine._id;
        let canData = { totalCans: props.cans.totalCans + diffrences, cans: cans,
             vendingMachine: vendingMachine, brand: brand };
        props.updateCans(props.user._id, canData);
    }
    
    const onSubmit = e => {
        e.preventDefault();
        if(isNewUser){
            props.addUser(formData );
        }else{
            props.updateUser( props.user._id, formData );
        }
    };

    const updateDeposit = e => {
        e.preventDefault();
        props.updateDeposit( props.deposit._id, props.user._id, depositData );
    }

    const updateDailyCansLimit = e => {
        e.preventDefault();
        props.updateCansLimit(props.user._id, {"cansLimit": cansLimit, "openedCans": openedCans});
    }

    const renderUserData = () => {
        return (
            <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "400px"}}> 
                    <div className='form-group d-flex mr-2'>
                        <label className='mt-2 mr-2'>Name</label>
                        <input
                            id='name'
                            className="form-control form-control-user" 
                            type='text'
                            placeholder='Name'
                            name='name'
                            value={name ? name : ""}
                            onChange={e => onChange(e) }
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap'}} className='mt-2 mr-2'>Phone</label>
                        <input
                            id='phone'
                            className="form-control form-control-user" 
                            type='number'
                            placeholder='phone'
                            name='phone'
                            value={phone ? phone : 0}
                            onChange={e => onChange(e) }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap'}} className='mt-2 mr-2'>Email</label>
                        <input
                            id='email'
                            className="form-control form-control-user" 
                            type='email'
                            placeholder='email'
                            name='email'
                            value={ email ? email : "" }
                            onChange={e => onChange(e) }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap'}} className='mt-2 mr-2'>Penalty</label>
                        <select
                            disabled
                            className="form-control form-control-user"
                            value={ hasPenalty }
                            name='hasPenalty'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap'}} className='mt-2 mr-2'>Suspend</label>
                        <select
                            className="form-control form-control-user"
                            value={ suspend }
                            name='suspend'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <input type='submit' className='btn btn-primary mx-auto' value={isNewUser?'Add':'Update'} />
                </form>
        );
    }

    const renderDailyCansData = () => {
        return (
            <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => updateDailyCansLimit(e) } style={{"width": "400px"}}>
                    <div className='form-group d-flex mr-2'>
                        <label className='mt-2 mr-2'>Opened Cans</label>
                        <input
                            id='openedCans'
                            className="form-control form-control-user"
                            type='number'
                            placeholder='Opened Cans'
                            name='openedCans'
                            value={openedCans ? openedCans : 0}
                            onChange={e => onChangeCansLimitData(e, "openedCans") }
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap'}} className='mt-2 mr-2'>Max Cans</label>
                        <input
                            id='cansLimit'
                            className="form-control form-control-user"
                            type='number'
                            placeholder='Cans Limit'
                            name='cansLimit'
                            value={cansLimit ? cansLimit : 0}
                            onChange={e => onChangeCansLimitData(e, "cansLimit") }
                        />
                    </div>
                    <input type='submit' className='btn btn-primary mx-auto' value='Update' />
                </form>
        );
    }

    const renderDepositData = () => {
        return (
            <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => updateDeposit(e) } style={{"width": "400px"}}>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle}  className='mt-2 mr-2'>Deposit &#8377;</label>
                        <input
                            id='deposit'
                            className="form-control form-control-user" 
                            type='number'
                            placeholder='Deposit'
                            name='deposit'
                            value={deposit ? deposit : 0}
                            onChange={e => onChange(e, "deposit") }
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle}  className='mt-2 mr-2'>Security Deposit &#8377;</label>
                        <input
                            id='securityDeposit'
                            className="form-control form-control-user" 
                            type='number'
                            placeholder='Security Deposit'
                            name='securityDeposit'
                            value={securityDeposit ? securityDeposit : 0}
                            onChange={e => onChange(e, "deposit") }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle}  className='mt-2 mr-2'>Can Deposit Bought</label>
                        <input
                            id='depositBought'
                            className="form-control form-control-user"
                            type='number'
                            placeholder='Deposit Bought'
                            name='depositBought'
                            value={ depositBought ? depositBought : 0 }
                            onChange={e => onChange(e, "deposit") }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle}  className='mt-2 mr-2'>Can Deposit Used</label>
                        <input
                            id='depositUsed'
                            className="form-control form-control-user"
                            type='number'
                            placeholder='Deposit Used'
                            name='depositUsed'
                            value={ depositUsed ? depositUsed : 0 }
                            onChange={e => onChange(e, "deposit") }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle}  className='mt-2 mr-2'>Collect Security Deposit</label>
                        <select
                            className="form-control form-control-user"
                            value={ collectSecurityDeposit }
                            name='collectSecurityDeposit'
                            onChange={e => onChange(e, "deposit") }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle}  className='mt-2 mr-2'>Received Free Deposit</label>
                        <select
                            id="receivedFreeDeposit"
                            className="form-control form-control-user"
                            value={ receivedFreeDeposit }
                            name='receivedFreeDeposit'
                            onChange={e => onChange(e, "deposit") }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle}  className='mt-2 mr-2'>Free Deposit</label>
                        <input
                            id='freeDeposit'
                            className="form-control form-control-user"
                            type='number'
                            placeholder='Deposit Used'
                            name='freeDeposit'
                            value={ freeDeposit ? freeDeposit : 0 }
                            onChange={e => onChange(e, "deposit") }
                        />
                    </div>
                    <input type='submit' className='btn btn-primary mx-auto' value={isNewUser?'Add':'Update'} />
                </form>
        );
    }

    const renderTableData = (brands, vendingMachineId, vmArrayIndex) => {
        return (
            Object.keys(brands).map((value, index) => {
                return <tr style={{textAlign:"center"}} key = { value } >
                    <td>{ brands[value].brand }</td>
                    {/* <td>{ brands[value].totalCans }</td> */}
                    <td>{ <input
                            id={'totalCans-' + value}
                            className="form-control form-control-user text-center"
                            type='number'
                            placeholder='Total Cans'
                            name='totalCans'
                            // value={ brands[value].totalCans }
                            value = { cansData[vendingMachineId]["brands"][value].totalCans }
                            onChange={e => onCanChange(e, value, vendingMachineId) }
                            required
                        /> }</td>
                    <td>{  <Button onClick = { () => updateCanData( value, vendingMachineId, brands[value].totalCans, vmArrayIndex, index) } color="primary"> Update </Button> }</td>
                </tr>
            })
        )
    }

    const renderCanData = () => {
        return (
            Object.keys(cansData).map((value, index) => {
                return <div key = {index}>
                    <h5>Vending Machine : {cansData[value].name}</h5>
                    <Table striped bordered hover>
                        <thead>
                            <tr className="text-center">
                                <th>Brand</th>
                                <th style={{width:"500px"}}>Cans</th>
                                <th>Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            { renderTableData(cansData[value].brands, value, index) }
                        </tbody>
                    </Table>
                </div>
            })
        )
    }

    const updateNote = () => {
        props.addNote(props.user._id, {note: notes});
    }

    const renderPenaltyData = () => {
        if(!penaltyData){
            return;
        }
        return (
            <Table striped bordered hover>
                <thead>
                    <tr className="text-center">
                        <th>Amount</th>
                        <th>Quantity</th>
                        <th>Paid</th>
                        <th>Canceled</th>
                        <th>Message</th>
                        <th>Added On</th>
                        <th>Updated On</th>
                        <th>Manage</th>
                    </tr>
                </thead>
                <tbody>
                    { Object.keys(penaltyData).map((value, index) => {
                        return <tr key={index} className="text-center">
                            <td>{penaltyData[index].amount}</td>
                            <td>{penaltyData[index].quantity}</td>
                            <td>{penaltyData[index].paid ? "Yes" : "No"}</td>
                            <td>{penaltyData[index].canceled ? "Yes" : "No"}</td>
                            <td>{penaltyData[index].message}</td>
                            <td>{new Date(penaltyData[index].createdAt).toString().split("GMT")[0]}</td>
                            <td>{new Date(penaltyData[index].updatedAt).toString().split("GMT")[0]}</td>
                            <td><a className="btn btn-sm btn-primary ml-auto"
                                href={ '/inventory/v1.2/penalty/'+ penaltyData[index]._id }> Manage </a></td>
                        </tr>
                    }) }
                </tbody>
            </Table>
        )
    }

    return (
        <Fragment>
          <div className='d-flex flex-column justify-content-center'>
                <div className='text-primary d-flex flex-row'>
                    <Link className='btn btn-sm btn-light text-primary ' to='/inventory/v1.2/users'>
                        Back
                    </Link>
                </div>
                <Row>
                    <Col>
                        <div className='text-primary d-flex flex-row'>
                            <h3 className='mx-auto'>User</h3>
                        </div>
                        { renderUserData() }
                    </Col>
                    <Col>
                        <div className='text-primary d-flex flex-row'>
                            <h3 className='mx-auto'>Daily Cans Limit</h3>
                        </div>
                        { renderDailyCansData() }
                    </Col>
                    <Col>
                        <div className='text-primary d-flex flex-row'>
                            <h3 className='mx-auto'>Deposit</h3>
                        </div>
                        { renderDepositData() }
                    </Col>
                </Row>
                <Row style={{marginTop :"5%"}}>
                    <div className='text-primary d-flex flex-row'>
                        <h3 className='mx-auto'>Orders & Transactions</h3>
                    </div>
                    <div className="text-center">
                        <Link to={{pathname : `/inventory/v1.2/Orders?phone=${props.user ? props.user.phone: null} `}}
                            target = "_blank"  style={{'width':'200px', marginBottom: "2%"}}
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-5 ml-1 ">
                                View Orders
                        </Link>
                        <Link to={{pathname : `/inventory/v1.2/transactions?phone=${props.user ? props.user.phone: null} `}}
                            target = "_blank"  style={{'width':'200px', marginBottom: "2%"}}
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-5 ml-1 ">
                                View Transactions
                        </Link>
                        <Link to={{pathname : `/inventory/v1.2/ledger?id=${props.user ? props.user._id: null} `}}
                            target = "_blank"  style={{'width':'200px', marginBottom: "2%"}}
                            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-5 ml-1 ">
                                View Can Ledger
                        </Link>
                    </div>
                </Row>
                <Row style={{marginTop :"5%"}}>
                    <div className='text-primary d-flex flex-row'>
                        <h3 className='mx-auto'>Cans</h3>
                    </div>
                    <div className='text-primary d-flex flex-row'>
                        <h6 className='mx-auto'>Total Cans : {props.cans ? props.cans.totalCans : null}</h6>
                    </div>
                    { renderCanData() }
                </Row>
                <Row style={{marginTop :"5%"}}>
                    <div className='text-primary d-flex flex-row'>
                        <h3 className='mx-auto'>Penalty</h3>
                    </div>
                    <Link  to={{pathname : "/inventory/v1.2/penalty/new",
                        state: {user: props.user ? props.user._id: null} }} style={{'width':'200px', marginBottom: "2%"}}
                        className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-5 ml-1 ">
                            Add Penalty
                    </Link>
                    { renderPenaltyData() }
                </Row>
                <Row style={{marginTop :"5%"}}>
                    <div className='text-primary d-flex flex-row'>
                        <h3 className='mx-auto'>Notes</h3>
                    </div>
                    <div className='form-group d-flex mr-2' style={{padding:"2%"}}>
                        <textarea
                            style={{height:"100px"}}
                            id='notes'
                            className="form-control form-control-user"
                            type='text'
                            placeholder='Notes'
                            name='notes'
                            value={notes}
                            onChange={e => onChangeNote(e) }
                        />
                    </div>
                    <div style={{textAlign:'center'}}>
                        <Button onClick={updateNote} color="primary"> Update </Button>
                    </div>
                </Row>
            </div>  

        </Fragment>
    );

}

User.propTypes = {
    getUser: PropTypes.func.isRequired,
    addUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    updateDeposit: PropTypes.func.isRequired,
    updateCans: PropTypes.func.isRequired,
    updateCansLimit: PropTypes.func.isRequired,
    addNote: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    loading: state.user.loading,
    user: state.user.user,
    deposit: state.user.deposit,
    cans: state.user.cans,
    penalties: state.user.penalties,
    note: state.user.note
});

export default connect( mapStateToProps, { addUser, updateUser, getUser,
    addNote, updateDeposit, updateCans, updateCansLimit }) ( User );
