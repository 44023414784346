export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_ALL_VENDING_MACHINES = 'GET_ALL_VENDING_MACHINES';
export const GET_VENDING_MACHINE = 'GET_VENDING_MACHINE';
export const GET_VENDING_MACHINE_STOCK = 'GET_VENDING_MACHINE_STOCK';
export const ADD_VENDING_MACHINE = 'ADD_VENDING_MACHINE';
export const UPDATE_VENDING_MACHINE = 'UPDATE_VENDING_MACHINE';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_PRODUCT_REQUEST ='GET_PRODUCT_REQUEST';
export const OPEN_CONTAINER='OPEN_CONTAINER';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ALL_ORDER_REQUEST = 'GET_ALL_ORDER_REQUEST';
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ALL_USER_REQUEST = 'GET_ALL_USER_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const GET_USER_BY_QUERY = 'GET_USER_BY_QUERY';
export const GET_CONTAINERS = 'GET_CONTAINERS';
export const GET_REGIONS = 'GET_REGIONS';
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const DELETE_PROFILE_DATA = 'DELETE_PROFILE_DATA';
export const GET_REGION = 'GET_REGION';
export const GET_REGION_REQUEST = 'GET_REGION_REQUEST';
export const UPDATE_REGION = 'UPDATE_REGION';
export const ADD_CONTAINERS = 'ADD_CONTAINERS';
export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export const REFUND_CAN = 'REFUND_CAN';
export const UPDATE_PENALTY_REQUEST = 'UPDATE_PENALTY_REQUEST';
export const ADD_PENALTY_REQUEST = 'ADD_PENALTY_REQUEST';
export const GET_PENALTY_REQUEST = 'GET_PENALTY_REQUEST';
export const UPDATE_USER_CANS = 'UPDATE_USER_CANS';
export const ADD_STOCK = 'ADD_STOCK';
export const REMOVE_STOCK = 'REMOVE_STOCK';
export const GET_ORDERS_DOWNLOAD = 'GET_ORDERS_DOWNLOAD';
export const GET_TRANSACTIONS_DOWNLOAD = 'GET_TRANSACTIONS_DOWNLOAD';
export const GET_VENDING_MACHINE_LIVE_STATUS = 'GET_VENDING_MACHINE_LIVE_STATUS';
export const GET_INVENTORY_STATUS = 'GET_INVENTORY_STATUS';
export const GET_CAN_STOCK = 'GET_CAN_STOCK';
export const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
export const CHECK_PAYMENT = 'CHECK_PAYMENT';
export const GET_LEDGER = 'GET_LEDGER';
export const VENDING_MACHINE_REGISTRATION = 'VENDING_MACHINE_REGISTRATION';
export const LIST_REGISTERED_VENDING_MACHINE = 'LIST_REGISTERED_VENDING_MACHINE';
export const REGISTERED_VENDING_MACHINE = 'REGISTERED_VENDING_MACHINE';
export const AVAILABLE_VENDING_MACHINE = 'AVAILABLE_VENDING_MACHINE';
export const GET_TRANSACTION_LEDGER = 'GET_TRANSACTION_LEDGER';
// export const GET_REPORT = 'GET_REPORT';
// export const DOWNLOAD_PING_REPORT = 'DOWNLOAD_PING_REPORT';
export const LIST_REGISTERED_VENDING_MACHINE_PRODUCT = 'LIST_REGISTERED_VENDING_MACHINE_PRODUCT';
export const SESSION_ID_FOR_TESTING = 'SESSION_ID_FOR_TESTING';
export const PING_DATA_DOWNLOAD = "PING_DATA_DOWNLOAD";
export const LOCK_DATA_DOWNLOAD = "LOCK_DATA_DOWNLOAD";
export const SESSION_RESULTS = "SESSION_RESULTS";
export const GET_SALES = "GET_SALES";
export const DOWNLOAD_SALES = "DOWNLOAD_SALES";
export const GET_ACCOUNT_DELETION_REQUEST = "GET_ACCOUNT_DELETION_REQUEST";