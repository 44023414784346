import {
    GET_TRANSACTIONS_REQUEST,
    GET_ALL_TRANSACTIONS,
    UPDATE_TRANSACTIONS,
    OPEN_CONTAINER,
    REFUND_CAN,
    GET_LEDGER
} from '../actions/types';

const initialState = {
    transactions: undefined,
    transaction: null,
    loading: true,
    pageList : [],
    activePage: 0,
    pages: 0,
    products: []
};

export default ( state = initialState, { type, payload }) => {
    switch (type) {
        case GET_ALL_TRANSACTIONS:
            return {
                ...state,
                transactions: payload.transactions,
                pages: payload.pages,
                pageList: payload.pageList,
                activePage: payload.activePage,
                loading: false
            };

        case GET_TRANSACTIONS_REQUEST:
            return {
                ...state,
                transaction: payload,
                loading: false
            };

        case UPDATE_TRANSACTIONS:
            return {
                ...state,
                loading: false
            };
        
        case OPEN_CONTAINER:
            return {
                ...state,
                loading: false
            };
        
        case REFUND_CAN:
            return {
                ...state,
                loading: false
            };

        case GET_LEDGER:
            return {
                ...state,
                ledger: payload.transactions,
                products: payload.products,
                pages: payload.pages,
                activePage: payload.activePage,
                loading: false
            };

        default:
            return state;
    }
};