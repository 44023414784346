import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';

const  Navbar = (props) => {
    const authLinks = (
        <React.Fragment>
            <ul className="navbar-nav mx-auto">

                <li className="nav-item active" key='vending-machine'>
                    <Link className="nav-link" to="/inventory/v1.2/"> Vending Machine </Link>
                </li>
                <li className="nav-item" key='products'>
                    <Link className="nav-link" to="/inventory/v1.2/Products"> Products </Link>
                </li>
                <li className="nav-item" key='profiles'>
                    <Link className="nav-link" to="/inventory/v1.2/Profiles"> Profiles </Link>
                </li>
                <li className="nav-item" key='orders'>
                    <Link className="nav-link" to="/inventory/v1.2/Orders"> Orders </Link>
                </li>
                <li className="nav-item" key='transactions'>
                    <Link className="nav-link" to="/inventory/v1.2/transactions"> Transactions </Link>
                </li>
                <li className="nav-item" key='users'>
                    <Link className="nav-link" to="/inventory/v1.2/Users"> Users </Link>
                </li>
                <li className="nav-item" key='redis'>
                    <Link className="nav-link" to="/inventory/v1.2/Redis"> Redis </Link>
                </li>
                <li className="nav-item" key='live-status'>
                    <Link className="nav-link" to="/inventory/v1.2/live-status"> Live Status </Link>
                </li>
                <li className="nav-item" key='inventory-status'>
                    <Link className="nav-link" to="/inventory/v1.2/inventory-status"> Inventory </Link>
                </li>
                <li className="nav-item" key='cans-in-stock'>
                    <Link className="nav-link" to="/inventory/v1.2/cans-in-stock"> Stock </Link>
                </li>
                <li className="nav-item" key='transaction-ledger'>
                    <Link className="nav-link" to="/inventory/v1.2/transaction-ledger"> TXN Ledgers </Link>
                </li>
                <li className="nav-item" key='user-ledger'>
                    <Link className="nav-link" to="/inventory/v1.2/ledger"> User Ledgers </Link>
                </li>
                <li className="nav-item" key='test-vending-machine'>
                    <Link className="nav-link" to="/inventory/v1.2/test-vending-machine"> Test </Link>
                </li>
                <li className="nav-item" key='downloads'>
                    <Link className="nav-link" to="/inventory/v1.2/downloads"> Downloads </Link>
                </li>
                <li className="nav-item" key='sales'>
                    <Link className="nav-link" to="/inventory/v1.2/sales"> Sales </Link>
                </li>
                <li className="nav-item" key='account-deletion'>
                    <Link className="nav-link" to="/inventory/v1.2/account-deletion"> Account Deletion </Link>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto mr-2">
                <li className="nav-item active">
                    <a onClick={props.logout} href="#!">
                        <i className="fas fa-sign-out-alt mr-1"></i>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </React.Fragment>
    );

    const guestLinks = (
        <ul className="navbar-nav ml-auto mr-2">
            <li className="nav-item active">
                <Link className="nav-link" to="/inventory/v1.2/login">Log In </Link>
            </li>
            {/* <li className="nav-item">
                <Link className="nav-link" to="/Register">Register</Link>
            </li> */}
        </ul>
    );

    return(
        <React.Fragment>

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <Link className="navbar-brand" to="/">CANMAN</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {!props.auth.loading &&
                        <React.Fragment>
                            {props.auth.isAuthenticated? authLinks : guestLinks}
                        </React.Fragment>
                    }
                    
                </div>
            </nav>

        </React.Fragment>
    )
}

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect( mapStateToProps, { logout } )(Navbar);
