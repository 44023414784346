import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLedger } from '../../actions/canTransaction';

// Import React Table
import "bootstrap/dist/css/bootstrap.min.css"

import TableContainer from "./TableContainer"; 

const Ledger = ({location, ledger, products, getLedger, pageList, pages, activePage}) => {

    const initialState = {
        vendingMachineId: '',
        product:'',
        startDate:'',
        endDate:'',
        latest: true
    };

    const [formData, setFormData] = useState(initialState);
    const [data, setData] = useState([])
    const [pageCount, setPage] = useState(0);
    const [currentPage, setActivePage] = useState(1);
    const [queryString, setQueryString] = useState("page=1&latest=true");
    const [userId, setUserId] = useState("");

    useEffect(() => {
        if(ledger === undefined){
            let query = queryString;
            if(location.search){
                let userId = location.search.split("=");
                if(userId.length === 2 & userId[1].length === 24){
                    query += "&userId=" + userId[1];
                    setQueryString(query);
                    setUserId(userId[1]);
                }
            }
            getLedger(query);
        }else{
            let ledgerData = [];
            ledger.forEach(ledgerObj => {
                ledgerData.push({
                    transaction: ledgerObj.transaction ? ledgerObj.transaction.transactionId : ledgerObj.order ? ledgerObj.order.orderId : "",
                    type: ledgerObj.transaction ? "transaction" : ledgerObj.order? "order":"",
                    transactionId : ledgerObj.transaction ? ledgerObj.transaction._id : ledgerObj.order ? ledgerObj.order._id : "",
                    time: new Date(ledgerObj.time).toString().split("GMT")[0],
                    brand: ledgerObj.product.brand,
                    vendingMachineId: ledgerObj.vendingMachine._id,
                    vendingMachineName: ledgerObj.vendingMachine.name,
                    credit: ledgerObj.credit,
                    debit: ledgerObj.debit,
                    total: ledgerObj.total,
                    message: ledgerObj.message,
                    user: ledgerObj.user.name,
                    userId: ledgerObj.user._id
                });
            });
            setQueryString(queryString.replace("page=" + currentPage, "page=" + activePage));
            setData(ledgerData);
            setPage(pages);
            setActivePage(activePage);
        }
    }, [ ledger, products ] );


    const columns = useMemo(
        () => [
            {
                Header: 'Transaction / Order',
                accessor: 'transaction',
                disableSortBy: true,
            },
            {
                Header: 'User',
                accessor: 'user',
            },
            {
                Header: 'Vending Machine',
                accessor: 'vendingMachineName',
            },
            {
                Header: 'Product',
                accessor: 'brand',
            },
            {
                Header: 'Credit',
                accessor: 'credit'
            },
            {
                Header: 'Debit',
                accessor: 'debit'
            },
            {
                Header: 'Total',
                accessor: 'total'
            },
            {
                Header: 'Time',
                accessor: 'time',
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
        ],
        []
    );

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const {
        vendingMachineId,
        product,
        startDate,
        endDate,
        latest
    } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {
        e.preventDefault();
        const startDate = new Date(formData.startDate).getTime();
        const endDate = new Date(formData.endDate).getTime();
        if(endDate < startDate){
            return alert("Invalid date")
        }
        let queryString = "page=1&latest=" + formData.latest;
        if(formData.startDate && formData.endDate){
            queryString += "&startDate=" + formData.startDate + "&endDate=" + formData.endDate;
        }
        if(formData.vendingMachineId){
            queryString += "&vendingMachine=" + formData.vendingMachineId;
        }
        if(formData.product){
            queryString += "&product=" + formData.product;
        }
        if(userId){
            queryString += "&userId=" + userId;
        }
        setQueryString(queryString);
        getLedger(queryString);
    };

    const showProducts = () => {
        return products.map((product, index) => {
            return (
                <option key={`product-${index}`} value={product._id}>{product.brand}</option>
            )
        });
    }

    const seachForm = () => {
        return(
            <form style={{width:"100%"}} onSubmit={ e => onSubmit(e) }>
                <div className="form-row">
                    <div className="col-2">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">Vending Machine</label>
                        <input name='vendingMachineId' value={vendingMachineId} onChange={e => onChange(e)}
                        type="text" className="form-control"/>
                    </div>
                    <div className="col-2">
                        <label style={{paddingTop:"2%"}} className='mt-2 mr-2'>Product :</label>
                        <select
                            className="form-control"
                            value={ product }
                            name='product'
                            onChange={e => onChange(e)}>
                                <option value="">Select Product</option>
                                { showProducts() }
                        </select>
                    </div>
                    <div className="col-2">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">Start Date</label>
                        <input name='startDate' value={startDate} onChange={e => onChange(e)}
                        type="date" className="form-control" placeholder="Start Date"/>
                    </div>
                    <div className="col-2">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">End Date</label>
                        <input name='endDate' value={endDate} onChange={e => onChange(e)}
                        type="date" className="form-control" placeholder="End Date"/>
                    </div>
                    <div className="col-2">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">View By</label>
                        <select name='latest' value={latest} type="text" className="custom-select mr-sm-2"
                            onChange={e => onChange(e)}>
                            <option value="false">Old</option>
                            <option value="true">New</option>
                        </select>
                    </div>
                    <div className="col-2" style={{display: "flex",alignItems: "flex-end"}}>
                        <input type='submit' className='btn btn-primary mx-auto mt-2'/>
                    </div>
                </div>
            </form>
        )
    }

    const renderLedgerData = () => {        
        if(data !== undefined && data.length > 0 ){
            return(
                <TableContainer columns={columns} data={data} pages={pageCount} activePage={currentPage}
                    queryString={queryString} getLedger={getLedger} />
            );
        }
    }

    return (
        <Fragment>
        <div className="container-fluid">
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'>Can Ledger</h3>
            </div>
            <div className='d-flex  mt-5 flex-wrap'>
                {  seachForm() }
            </div>
            <div className='d-flex  mt-5 flex-wrap'>                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Ledger</h6>
                    </div>                    
                    <div className="card-body">
                        <div className="table-responsive">
                            { renderLedgerData() }
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        </Fragment>
    )
}

Ledger.PpropTypes = {
    getLedger : PropTypes.func.isRequired,
    ledger : PropTypes.array,
    pages : PropTypes.Number,
    activePage : PropTypes.Number
}

const mapStateToProps = state => ({
    ledger: state.canTransaction.ledger,
    products: state.canTransaction.products,
    pages: state.canTransaction.pages,
    pageList: state.canTransaction.pageList,
    activePage: state.canTransaction.activePage,
    loading: state.canTransaction.loading
})

export default connect( mapStateToProps, { getLedger } )( Ledger );