import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect  } from 'react-redux';
import { getProfile } from '../../actions/profile';

const ProfileCard = (props) =>{

    const [currentProfile, setCurrentProfile] = useState(false);
    const onClick = (e,id) => {
        props.getProfile(id)
        setCurrentProfile(id);
    }
    if(currentProfile!==false){
        return <Redirect to={`/inventory/v1.2/profiles/${currentProfile}`} />
    }
    return(
        <div className="col-xl-3 col-md-6 mb-4" style={{'cursor':'pointer'}} onClick={e => onClick(e,props.profile._id) }>
            <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
                <div className="row no-gutters align-items-center" style = {{textAlign: 'center'}}>
                <div className="col mr-2">                            
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                        Profile:   <span className='text-primary'>{ props.profile.name}</span>
                    </div>
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                        GST:   <span className='text-primary'>{ props.profile.gst}</span>
                    </div>
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                    Cans Limit: <span className='text-primary'>{ props.profile.cansLimit}</span>
                    </div>
                </div>
                <div className="col-auto">
                    <i className="fas fa-machine fa-2x text-gray-300"></i>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}

ProfileCard.propTypes = {
    getProfile: PropTypes.func,
}

export default connect(null, { getProfile } )(ProfileCard);
