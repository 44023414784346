import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getVendingMachine, updateVendingMachine, getVendingMachinesStock } from '../../actions/vendingMachine';
import {Container, Row, Col, Table} from "react-bootstrap";
import { getProfiles } from '../../actions/profile';
import { Button } from 'react-bootstrap';

const initialState = {
    name: '',
    vendingMachineId: '',
    location: '',
    active:'',
    qrCode:'',
    live:false,
    profileName:''
};

let inventoryState = {"available": 0, "reserved": 0, "empty" : 0, "failures" : 0, "unassigned": 0};
let brandStockState = {};

const VendingMachineDetails = ({
    match,
    vendingMachine : { vendingMachine, loading },
    getVendingMachine,
    updateVendingMachine,
    getVendingMachinesStock,
    stockInfo,
    profiles,
    getProfiles
}) => {
    const [formData, setFormData] = useState(initialState);
    const [inventoryDetails, setInventoryData] = useState(inventoryState);
    const [brandStockDetails, setStockData] = useState(brandStockState);
    const [redisBrandStockDetails, setRedisStockData] = useState({available:{}, containers:[], reserved:{}});
    const [lastUpdatedOn, setUpdatedOn] = useState(0);
    const [profileData, setProfileData] = useState({profile:"", profileId:""});

    useEffect(() => {
        if (!vendingMachine ){
            getVendingMachine(match.params.id);
            getVendingMachinesStock(match.params.id);
            getProfiles();
        } else if( vendingMachine._id !== match.params.id){
            getVendingMachine(match.params.id);
            getVendingMachinesStock(match.params.id);
            getProfiles();
        }

        if(vendingMachine){
            setProfileData({ profile : vendingMachine.profileName, profileId : vendingMachine.profile})
        }

        if ("brandStock" in stockInfo) {
            const inventoryData = { ...inventoryState };
            const brandStockData = { ...brandStockState};
            Object.keys(stockInfo.brandStock).forEach(brand => {
                brandStockData[brand] = stockInfo.brandStock[brand];
            });
            inventoryData["failures"] += stockInfo.containersInfo.failure;
            inventoryData["reserved"] += stockInfo.containersInfo.reserved;
            inventoryData["empty"] += stockInfo.containersInfo.empty;
            inventoryData["available"] += stockInfo.containersInfo.available;
            inventoryData["unassigned"] += stockInfo.containersInfo.unassigned;
            setInventoryData(inventoryData);
            setStockData(brandStockData);
            setRedisStockData(stockInfo.redisStock);
        }

        if("liveStatus" in stockInfo){
            if(stockInfo.liveStatus){
                setUpdatedOn(stockInfo.liveStatus.lastUpdatedOn);
            }
        }

        if(vendingMachine && vendingMachine._id === match.params.id){
            const vendingMachineData = { ...initialState };
            for (const key in vendingMachine) {
                if (key in vendingMachineData) vendingMachineData[key] = vendingMachine[key];
            }
            setFormData(vendingMachineData);
        }
    }, [loading, getVendingMachine, vendingMachine, match, getVendingMachinesStock, stockInfo, profiles]);

    const populateAllStock = () => {
        return (
            Object.keys(brandStockDetails).map((value, index) => {
                return <tr style={{textAlign:"center"}} key = { value } >
                    <td>{ value }</td>
                    <td>{ brandStockDetails[value] }</td>
                </tr>
            })
        )
    }

    const populateRedisAvailableStock = () => {
        return (
            Object.keys(redisBrandStockDetails.available).map((value, index) => {
                return <tr style={{textAlign:"center"}} key = { value } >
                    <td>{ value }</td>
                    <td>{ redisBrandStockDetails.available[value] }</td>
                </tr>
            })
        );
    }

    const populateRedisReservedStock = () =>{
        return (
            Object.keys(redisBrandStockDetails.reserved).map((key, index) => {
                return <tr style={{textAlign:"center"}} key = { key } >
                    <td>{ redisBrandStockDetails.reserved[key].productId }</td>
                    <td>{ redisBrandStockDetails.reserved[key].brand }</td>
                    <td>
                        { (Date.parse(new Date()) - redisBrandStockDetails.reserved[key].time)/1000 + " Sec ago" }
                    </td>
                </tr>
            })
        );
    }
    
    const {
        name,
        vendingMachineId,
        location,
        active,
        qrCode
    } = formData;

    const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });
  
    const onSubmit = e => {
      e.preventDefault();
      updateVendingMachine(vendingMachine._id ,formData);
    };

    const onProfileDataChange = e => {
        let newProfileData = { profile : e.target[e.target.selectedIndex].text, profileId : e.target.value }
        setProfileData(newProfileData);
    }

    const showProfiles = () => {
        return profiles.map((profile, index) => {
            return (
                <option key={`profile-${index}`} value={profile._id}>{profile.name}</option>
            )
        });
    }

    let renderLiveStatus = (lastUpdatedOn) => {
        if(!lastUpdatedOn){
            return <span className="form-control form-control-user" style={{'color': 'red'}}>Offline</span>;
        }else{
            let currenDatetime = new Date();
            let timeDelta = (Date.parse(currenDatetime) - lastUpdatedOn)/1000;
            if(timeDelta <= `${ Number(process.env.REACT_APP_VENDING_MACHINE_EXPIRE_TIME)}`)
            return <span className="form-control form-control-user" style={{'color': 'green'}}>Online</span>;
            return <span className="form-control form-control-user" style={{'color': 'red'}}>Offline</span>;
        }
    }

    const updateProfile = () =>{
        updateVendingMachine(vendingMachine._id , {profile: profileData.profileId, profileName: profileData.profile});
    }

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    return (
    <Fragment>
    
        <div className='d-flex flex-column justify-content-center'>
            <div className='text-primary d-flex flex-row'>
                <Link className='btn btn-sm btn-light text-primary ' to='/'>
                    Back
                </Link>
                <Link 
                    to={`/inventory/v1.2/vendingMachine/${vendingMachine?vendingMachine._id:"-"}/Inventory`}
                    style={{'width':'200px'}} 
                    className="btn btn-sm btn-primary ml-auto  ">
                        Manage Inventory
                </Link>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <Container>
                <Row>
                    <Col>
                        <div className='text-primary mt-2 d-flex flex-row'>
                            <h3 className='mx-auto'>Vending Machine Details</h3>
                        </div>
                        <div className='d-flex justify-content-center'>
                            {   qrCode ?
                                <img style={{width: "200px"}} alt="QR Code" src={`${process.env.REACT_APP_SERVER_PUBLIC_PATH}${process.env.REACT_APP_IMAGE_PATH_QRCODE}/${qrCode}`} />: null
                            }
                        </div>
                        <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "400px"}}>
                            <div className='form-group d-flex mr-2'>
                                <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Vending Name</label>
                                <input
                                    className="form-control form-control-user"
                                    type='text'
                                    placeholder='Vending Machine Name'
                                    name='name'
                                    value={name}
                                    onChange={e => onChange(e) }
                                    required
                                />
                            </div>

                            <div className='form-group d-flex mr-2'>
                                <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Vending Machine ID</label>
                                <input
                                    className="form-control form-control-user"
                                    type='text'
                                    placeholder='Vending Machine ID'
                                    name='vendingMachineId'
                                    value={vendingMachineId}
                                    required
                                    readOnly
                                />
                            </div>
                            <div className='form-group d-flex mr-2'>
                                <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Product Profile</label>
                                <input
                                    className="form-control form-control-user"
                                    type='text'
                                    placeholder='Profile Name'
                                    name='profileName'
                                    value={ vendingMachine? vendingMachine.profileName : "" }
                                    required
                                    readOnly
                                />
                            </div>
                            <div className='form-group d-flex mr-2'>
                                <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Location</label>
                                <input
                                    className="form-control form-control-user"
                                    type='text'
                                    placeholder='Location'
                                    name='location'
                                    value={location}
                                    onChange={e => onChange(e) }
                                    required
                                />
                            </div>
                            <div className='form-group d-flex mr-2'>
                                <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Live</label>
                                {renderLiveStatus(lastUpdatedOn)}
                            </div>
                            <div className='form-group d-flex mr-2'>
                                <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Status</label>
                                <select
                                className="form-control form-control-user"
                                value={active}
                                name='active'
                                onChange={e => onChange(e) }>
                                    <option value="true">Active</option>
                                    <option value="false">Deactivate</option>
                                </select>
                            </div>
                            <input type='submit' className='btn btn-primary mx-auto' value='Update' />
                        </form>
                        <Row style={{"marginTop": "15%"}}>
                            <Col>
                                <div className='text-primary mt-2 d-flex flex-row'>
                                    <h3 className='mx-auto'>Update Product Profile</h3>
                                </div>
                                <div className="form mt-3 mx-auto d-flex flex-column">
                                    <div className='form-group d-flex mr-2'>
                                        <select
                                            className="form-control form-control-user ml-auto"
                                            style={{width:"200px", margin:"auto"}}
                                            value = { profileData ? profileData.profileId : "" }
                                            name='profile'
                                            required
                                            onChange={e => onProfileDataChange(e) }>
                                            <option value="">Select Profile</option>
                                            { showProfiles() }
                                        </select>
                                    </div>
                                    <Button className="mx-auto" style={{marginLeft: "1%"}} onClick={ e => updateProfile() }>Update</Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <div className='text-primary mt-2 d-flex flex-row'>
                            <h3 className='mx-auto'>Vending Machine Stock</h3>
                        </div>
                        <br></br>
                        <Table striped bordered hover>
                            <thead>
                                <tr style={{textAlign:"center"}}>
                                    <th>Brand</th>
                                    <th>Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr style={{textAlign:"center"}}>
                                    <td>Brand 1</td>
                                    <td>3</td>
                                </tr>
                                <tr style={{textAlign:"center"}}>
                                    <td>Brand 2</td>
                                    <td>2</td>
                                </tr> */}
                                { populateAllStock() }
                            </tbody>
                        </Table>
                        <br></br>
                        <br></br>
                        <br></br>

                        <div className='text-primary mt-2 d-flex flex-row'>
                            <h3 className='mx-auto'>Vending Machine Containers</h3>
                        </div>
                        <br></br>
                        <Table striped bordered hover>
                            <thead>
                                <tr style={{textAlign:"center"}}>
                                    <th>Type</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{textAlign:"center"}}>
                                    <td>Available</td>
                                    <td>{inventoryDetails["available"]}</td>
                                </tr>
                                <tr style={{textAlign:"center"}}>
                                    <td>Reserved</td>
                                    <td>{inventoryDetails["reserved"]}</td>
                                </tr>
                                <tr style={{textAlign:"center"}}>
                                    <td>Empty</td>
                                    <td>{inventoryDetails["empty"]}</td>
                                </tr>
                                <tr style={{textAlign:"center"}}>
                                    <td>Failure</td>
                                    <td>{inventoryDetails["failures"]}</td>
                                </tr>
                                <tr style={{textAlign:"center"}}>
                                    <td>Unassigned</td>
                                    <td>{inventoryDetails["unassigned"]}</td>
                                </tr>
                            </tbody>
                        </Table>


                        <br></br>
                        <br></br>
                        <br></br>

                        <div className='text-primary mt-2 d-flex flex-row'>
                            <h3 className='mx-auto'>Redis Stock Info</h3>
                        </div>
                        <br></br>
                        <h4 style={{"textAlign": "center"}}> Available </h4>
                        <Table striped bordered hover>
                            <thead>
                                <tr style={{textAlign:"center"}}>
                                    <th>Brand</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                { populateRedisAvailableStock() }
                            </tbody>
                        </Table>
                        <br></br>
                        {
                            Object.keys(redisBrandStockDetails.reserved) === 0 ?
                            <h5 style={{"textAlign": "center"}}> No Reserved Container </h5> :
                            <div>
                                <h4 style={{"textAlign": "center"}}> Reserved </h4>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr style={{textAlign:"center"}}>
                                            <th>Container</th>
                                            <th>Brand</th>
                                            <th>Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { populateRedisReservedStock() }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    </Fragment>
  );
};

VendingMachineDetails.propTypes = {
    vendingMachine: PropTypes.object.isRequired,
    stockInfo: PropTypes.object.isRequired,
    getVendingMachine: PropTypes.func.isRequired,
    updateVendingMachine: PropTypes.func.isRequired,
    getVendingMachinesStock: PropTypes.func.isRequired,
    getProfiles:PropTypes.func.isRequired,
    profiles: PropTypes.array
};

const mapStateToProps = state => ({
    stockInfo: state.vendingMachine.stockInfo,
    vendingMachine: state.vendingMachine,
    profiles: state.profile.profiles
});

export default connect(
    mapStateToProps, 
    { getVendingMachinesStock,getVendingMachine,updateVendingMachine, getProfiles }
)(VendingMachineDetails);