import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTransaction, updateTransaction, openContainer, refundCan } from '../../actions/canTransaction';
import {Container, Row, Col, Button} from "react-bootstrap";

const Transaction = ({ match, getTransaction, transaction, updateTransaction, openContainer, refundCan }) => {
    const [formData, setFormData] = useState({});
    const [newData, setNewData] = useState({});
    useEffect( () => {
        if(transaction === undefined || transaction === null){
            getTransaction(match.params.id);
        }else {
            setFormData(transaction);
            setFormData({...transaction, ["product"]: transaction.brand});
        }
    }, [ transaction ] );

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if(e.target.name !== "comment"){
            setNewData({ ...newData, [e.target.name]: e.target.value === "true" ? true : false})
        }else{
            setNewData({ ...newData, [e.target.name]: e.target.value})
        }
    }

    const onSubmit = e => {
        e.preventDefault();
        updateTransaction(newData, formData._id);
    };

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }    

    const renderOrderData = () => {
        if(formData){
            return(
                <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "400px"}}>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Transaction Id</label>
                        <input
                            className = "form-control form-control-user"
                            type = 'text'
                            placeholder = 'Transaction Id'
                            name = 'transactionId'
                            value = { formData.transactionId || "" }
                            onChange = {e => onChange(e) }
                            disabled
                            />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Container Id</label>
                        <input
                            className = "form-control form-control-user"
                            type = 'text'
                            placeholder = 'Container Id'
                            name = 'containerId'
                            value = { formData.containerId || "" }
                            onChange = {e => onChange(e) }
                            disabled
                            />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Status</label>
                        <input
                            className = "form-control form-control-user"
                            type = 'text'
                            placeholder = 'Transaction Status'
                            name = 'status'
                            value = { formData.status || "" }
                            onChange = {e => onChange(e) }
                            disabled
                            />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Product</label>
                        <input
                            className = "form-control form-control-user"
                            type = 'text'
                            placeholder = 'Transaction Status'
                            name = 'product'
                            value = { formData.product || "" }
                            onChange = {e => onChange(e) }
                            disabled
                            />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Return Can</label>
                        <select
                            className="form-control form-control-user"
                            value={ formData.returnCan }
                            name='returnCan'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Deposit Used</label>
                        <select
                            className="form-control form-control-user"
                            value={ formData.depositUsed }
                            name='depositUsed'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Container Working</label>
                        <select
                            className="form-control form-control-user"
                            value={ formData.isContainerWorking }
                            name='isContainerWorking'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Container Open</label>
                        <select
                            className="form-control form-control-user"
                            value={ formData.containerOpen }
                            name='containerOpen'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Container Close</label>
                        <select
                            className="form-control form-control-user"
                            value={ formData.containerClose }
                            name='containerClose'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Return Can Verified</label>
                        <select
                            className="form-control form-control-user"
                            value={ formData.returnCanVerified }
                            name='returnCanVerified'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Date & Time</label>
                        <input
                            className = "form-control form-control-user"
                            type = 'text'
                            placeholder = 'Time'
                            name = 'time'
                            value = { new Date(formData.time) || "" }
                            onChange = {e => onChange(e) }
                            disabled
                            required/>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Comment</label>
                        <input
                            className = "form-control form-control-user"
                            type = 'text'
                            placeholder = 'Message'
                            name = 'comment'
                            value = { formData.comment || "" }
                            onChange = {e => onChange(e) }
                            required/>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Container Open Time</label>
                        <input
                            className = "form-control form-control-user"
                            type = 'text'
                            placeholder = 'Time'
                            name = 'containerOpenTime'
                            value = { new Date(formData.containerOpenTime) || "" }
                            onChange = {e => onChange(e) }
                            disabled
                            required/>
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Vending Machine Message</label>
                        <input
                            className = "form-control form-control-user"
                            type = 'text'
                            placeholder = 'Vending Machine Message'
                            name = 'vendingMachineMessage'
                            value = { formData.vendingMachineMessage || "" }
                            onChange = {e => onChange(e) }
                            disabled
                            required/>
                    </div>
                    <input type='submit' className='btn btn-primary mx-auto mt-2' value="Update" />
                </form>
            );
        }
    }

    const renderUserData = () => {
        if(formData.user){
            return (
                <div style={{textAlign:"center"}}>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Phone : </label>
                        <h6 style={{display:"inline-block"}}> { formData.user.phone } </h6>
                    </div>
                    <br></br>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Name : </label>
                        <h6 style={{display:"inline-block"}}> { formData.user.name } </h6>
                    </div>
                    <br></br>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Email : </label>
                        <h6 style={{display:"inline-block"}}> { formData.user.email } </h6>
                    </div>
                    <br></br>
                    <a className="btn btn-sm btn-primary ml-auto"
                        href={ '/inventory/v1.2/users/'+ formData.user._id }> View </a>
                </div>
            );
        }
    }

    const renderVendingMachineData = () => {
        if(formData.user){
            return (
                <div style={{textAlign:"center"}}>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Name : </label>
                        <h6 style={{display:"inline-block"}}> { formData.vendingMachine.name } </h6>
                    </div>
                    <br></br>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Profile : </label>
                        <h6 style={{display:"inline-block"}}> { formData.vendingMachine.profileName } </h6>
                    </div>
                    <br></br>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Region : </label>
                        <h6 style={{display:"inline-block"}}> { formData.vendingMachine.region } </h6>
                    </div>
                    <br></br>
                    <a className="btn btn-sm btn-primary ml-auto"
                        href={ '/inventory/v1.2/vendingMachine/'+ formData.vendingMachine._id }> View </a>
                </div>
            );
        }
    }

    const handleOpen = () => {
        openContainer({}, formData._id);
    }

    const handleRefundCan = () => {
        refundCan({}, formData._id);
    }

    const renderOpenContainer = () => {
        if(formData.status){
            return (
                <div style={{textAlign:"center"}}>
                    { formData.status !== "opened" ?
                        <div> <Button onClick={handleOpen} color="primary"> Open </Button> </div>
                    : <div> Already Opened </div> }
                </div>
            );
        }
    }

    const renderRefundCan = () => {
        if(formData.status){
            return (
                <div style={{textAlign:"center"}}>
                    { formData.status !== "opened" && formData.status !== "failed" ?
                        <div> <Button onClick={handleRefundCan} variant="success"> Refund </Button> </div>
                    : <div> {formData.status === "opened" ? "Transaction completed" : "Can already refunded"} </div> }
                </div>
            );
        }
    }

    return (
        <Fragment>
            {/* <div className="container-fluid"> */}
            <div className='d-flex flex-column justify-content-center'>
                <Container>
                    <Row>
                        <Col>
                            <div className='text-primary mt-2 d-flex flex-row'>
                                <h3 className='mx-auto'>Transaction Details</h3>
                            </div>
                            { renderOrderData() }
                        </Col>
                        <Col>
                            <Row>
                                <div className='text-primary mt-2 d-flex flex-row' style={{marginBottom:"20px"}}>
                                    <h3 className='mx-auto'>User Details</h3>
                                </div>
                                { renderUserData() }
                            </Row>
                            <Row style={{marginTop:"50px"}}>
                                <div className='text-primary mt-2 d-flex flex-row' style={{marginBottom:"20px"}}>
                                    <h3 className='mx-auto'>Vending Machine Details</h3>
                                </div>
                                { renderVendingMachineData() }
                            </Row>
                            <Row style={{marginTop:"50px"}}>
                                <div className='text-primary mt-2 d-flex flex-row' style={{marginBottom:"20px"}}>
                                    <h3 className='mx-auto'>Open Container</h3>
                                </div>
                                { renderOpenContainer() }
                            </Row>
                            <Row style={{marginTop:"50px"}}>
                                <div className='text-primary mt-2 d-flex flex-row' style={{marginBottom:"20px"}}>
                                    <h3 className='mx-auto'>Refund Can</h3>
                                </div>
                                { renderRefundCan() }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )

}

Transaction.PpropTypes = {
    getTransaction : PropTypes.func.isRequired,
    updateTransaction : PropTypes.func.isRequired,
    openContainer : PropTypes.func.isRequired,
    refundCan : PropTypes.func.isRequired
} 
const mapStateToProps = state => ({
    transaction: state.canTransaction.transaction,
    loading: state.canTransaction.loading
})

export default connect( mapStateToProps, { getTransaction, updateTransaction, openContainer, refundCan } )( Transaction );