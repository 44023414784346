import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken'

import {
    GET_PRODUCT,
    GET_ALL_PRODUCTS,
    GET_PRODUCT_REQUEST
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get all Products
export const getProducts = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    dispatch({
        type : GET_PRODUCT_REQUEST,
        payload: true
    });
    
    
    try {
        const res =  await axios.get(`${API_URL}/products`);

        if(res.data.success){            
            dispatch({
                type : GET_ALL_PRODUCTS,
                payload: res.data.products
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed to fetch Products', 'danger'));
    }
}

// Get a Product
export const getProduct = id => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    dispatch({
        type : GET_PRODUCT_REQUEST,
        payload: true
    });
    try {
        const res =  await axios.get(`${API_URL}/products/${id}`);
  
        if(res.data.success){  
            dispatch({
                type : GET_PRODUCT,
                payload: res.data.data
            });
            dispatch( setAlert('Successfully fetched Product details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get Product details', 'danger'));
    }
};


// Add a Product
export const addProduct = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/products`, formData, config);
        if(res.data.success){            
            // dispatch({
            //     type: ADD_PRODUCT,
            //     payload: res.data.data
            // });
            dispatch(getProducts);
            dispatch(setAlert('Successfully add a product', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to add a product', 'danger') );
    }
};

// Upload Product image
export const uploadProductImage = (payload) => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const data = new FormData()
    data.append('file', payload.file); 
    try{
        const res = await axios.put(`${API_URL}/products/${payload.product_id}/image`,data);
        if(res.data.success){ 
            dispatch(setAlert('Product image updated', 'success'));
            dispatch(getProducts());
            dispatch(getProduct(payload.product_id));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    }catch(err){
        dispatch( setAlert('Error: Failed to upload product image', 'danger') );
    }
}

// Update a Product
export const updateProduct = (id,formData) => async dispatch => {
    // dispatch({
    //     type : GET_PRODUCT_REQUEST,
    //     payload: true
    // });
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };  
    try {
        const res =  await axios.put(`${API_URL}/products/${id}`, formData, config);
        if(res.data.success){ 
            dispatch(setAlert('Product Updated', 'success'));
            dispatch(getProducts());
            dispatch(getProduct(id));

        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to update product', 'danger') );
    }
};

// Get a region
export const getregions = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    dispatch({
        type : GET_PRODUCT_REQUEST,
        payload: true
    });
    try {
        const res =  await axios.get(`${API_URL}/region/all`);
  
        if(res.data.success){  
            dispatch({
                type : GET_PRODUCT,
                payload: res.data.data
            });
            dispatch( setAlert('Successfully fetched Product details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get Product details', 'danger'));
    }
};

