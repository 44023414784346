import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addRegion, updateRegion, getRegion } from '../../actions/region';
import { getProfiles } from '../../actions/profile';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const Product = ({
    match,
    addRegion,
    getRegion,
    updateRegion,
    loading,
    region,
    profiles,
    getProfiles
}) => {
    const [regionName, setRegionName] = useState("");
    const [profileArray, setProfileData] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if(match.params.id !== 'new'){
            if (!region || (region._id !== match.params.id)){
                getProfiles();
                getRegion(match.params.id);
            }
            if (region) {
                if(Object.keys(region).length > 0){
                    setSelectedOptions(
                        region.profiles.map((profile, index) => ({value: profile.profile, label: profile.name}))
                    );
                    setRegionName(region.region);
                }
                if(profiles.length > 0){
                    setProfileData(
                        profiles.map((profile, index) => ({value: profile._id, label: profile.name}))
                    );
                }
            }
        }
    }, [ region ]);
    
    const isNewRegion =  (match.params.id==='new')? true : false ;
    const onChange = (e) => {
        setRegionName(e.target.value)

    }
    
    const handleChange = (options) => {
        setSelectedOptions(options);
    };

    const onSubmit = e => {
        e.preventDefault();
        if(isNewRegion){
            addRegion({region: regionName});
        }else{
            let data = {}
            data["region"] = regionName;
            data["profiles"] = selectedOptions.map((option, index) => ({profile: option.value, name: option.label}));
            updateRegion( region._id, data );
        }
    };

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    return (
        <Fragment>
            <div className='d-flex flex-column justify-content-center'>
                <div className='text-primary d-flex flex-row'>
                    <Link className='btn btn-sm btn-light text-primary ' to='/inventory/v1.2/regions'>
                        Back
                    </Link>
                </div> 
                <div className='text-primary d-flex flex-row'>
                    <h3 className='mx-auto'>Region</h3>
                </div>
                   
                <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) }>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle} className='mt-2 mr-2'>Region Name</label>
                        <input
                            id='region'
                            className="form-control form-control-user" 
                            type='text'
                            placeholder='Region'
                            name='region'
                            value={regionName}
                            onChange={e => onChange(e) }
                            required
                        />
                    </div>

                    { !isNewRegion ?
                        <div className='form-group d-flex mr-2'>
                            <label style={customLableStyle} className='mt-2 mr-2'>Profiles :</label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                value={selectedOptions}
                                isMulti
                                options={profileArray}
                                onChange={handleChange}
                            />
                        </div> : null
                    }


                    <input type='submit' className='btn btn-primary mx-auto mt-2' value={isNewRegion?'Add':'Update'} />
                </form>
            </div>
        </Fragment>
    );
}
Product.propTypes = {
    getRegion: PropTypes.func.isRequired,
    addRegion: PropTypes.func.isRequired,
    updateRegion: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    region: PropTypes.object,
    profiles: PropTypes.array,
    getProfiles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: state.region.loading,
    region: state.region.region,
    profiles: state.profile.profiles
});

export default connect(
    mapStateToProps, 
    { addRegion, getRegion, updateRegion, getProfiles }
)(Product);
