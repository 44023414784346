import axios from 'axios';
import { setAlert } from './alert';

import { 
    GET_ORDERS_DOWNLOAD,
    GET_TRANSACTIONS_DOWNLOAD
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get All Downloads
export const getOrders = (vendingMachine, startDate, endDate, reportType) => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/orders/report/download?vendingMachine=${vendingMachine}&startDate=${startDate}&endDate=${endDate}`);
        if(res.data.success){
            dispatch({
                type: GET_ORDERS_DOWNLOAD,
                payload: res.data.orders
            });
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth Orders' ) );
    }
}

// Get All Transactions
export const getTransactions = (vendingMachine, startDate, endDate, reportType) => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/transactions/report/download?vendingMachine=${vendingMachine}&startDate=${startDate}&endDate=${endDate}`);
        if(res.data.success){
            dispatch({
                type: GET_TRANSACTIONS_DOWNLOAD,
                payload: res.data.transactions
            });
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth Orders' ) );
    }
}
