import React , { useEffect, Fragment }from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ProductCard from './ProductCard';
import { getProducts } from '../../actions/product';

const Products = ({ getProducts, products, loading }) => {

    useEffect( () => {
        getProducts();
    }, [ ]);
    
    const getAllProductCards = () => {
        if(products && products.length>0){
            return products.map((product, index) => {                 
                return (
                    <ProductCard key={index} product={product} />
                );
            });
        }
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <Link
                    to="/inventory/v1.2/products/new"
                    style={{'width':'200px'}}
                    className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-5 ml-1 ">
                        Add New Product
                </Link>

                <div className='d-flex  mt-5 flex-wrap'>
                    {  getAllProductCards() }
                </div>
            </div>
        </Fragment>
    )
}

Products.propTypes = {
    products: PropTypes.array,
    getProducts: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}

const mapStateToProps = state => ({
    products: state.product.products,
    loading: state.product.loading
});
export default connect(mapStateToProps, { getProducts } )(Products);

