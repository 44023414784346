import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect  } from 'react-redux';
import { getVendingMachine } from '../../actions/vendingMachine';

const VendingMachine = (props) =>{

    const [currentVM, setCurrentVM] = useState(false);
    const onClick = (e,id) => {
        setCurrentVM(id);
    }
    if(currentVM!==false){
        return <Redirect to={`/inventory/v1.2/vendingMachine/${currentVM}`} />
    }
    return(   
        <div className="col-xl-3 col-md-6 mb-4" style={{'cursor':'pointer'}} onClick={e => onClick(e,props.vendingMachine._id) }>
            <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
                <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{ props.vendingMachine.name}</div>
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                        ID:   <span className='text-primary'>{ props.vendingMachine.vendingMachineId}</span>
                    </div>
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                        Location: <span className='text-primary'>{ props.vendingMachine.location}</span>
                    </div>
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                        Name: { props.vendingMachine.name?
                            <span className='text-primary'>{props.vendingMachine.name}</span> : null}
                    </div>
                    <div className="h6 mb-0 font-weight-normal text-gray-800 mt-1">
                        Status: { props.vendingMachine.active?
                            <span className='text-success'>active</span> : <span className='text-danger'>deactive</span>
                        }
                    </div>
                </div>
                <div className="col-auto">
                    <i className="fas fa-machine fa-2x text-gray-300"></i>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}

// vendingMachine: PropTypes.object.isRequired,
VendingMachine.propTypes = {
    getVendingMachine: PropTypes.func
}

// const mapStateToProps = state => ({
//     vendingMachine: state.vendingMachine
// });
export default connect(null, {getVendingMachine} )(VendingMachine);
