import {
    GET_SALES,
    DOWNLOAD_SALES
} from '../actions/types';

const initialState = {
    sales: undefined,
    loading: true,
    pageList : [],
    activePage: 0,
    pages: 0,
    downloadBlob: null
};

export default ( state = initialState, { type, payload }) => {
    switch (type) {

        case GET_SALES:
            return {
                ...state,
                sales: payload.sales,
                pages: payload.pages,
                pageList: payload.pageList,
                activePage: payload.activePage,
                loading: false
            };
        case DOWNLOAD_SALES:
            return {
                ...state,
                downloadBlob: payload,
                loading:false
            }
        default:
            return state;
    }
};