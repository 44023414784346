import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAllRegisteredVendingMachines, registerVendingMachine,
     deleteRegisteredVendingMachine } from '../../actions/vendingMachine';

// Import React Table
import {Container, Row, Col, Table} from "react-bootstrap";
import { Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"

const ListVM = ({ getAllRegisteredVendingMachines, deleteRegisteredVendingMachine, registeredVMList }) => {

    useEffect(() => {
        if(registeredVMList === null){
            getAllRegisteredVendingMachines();
        }
    }, [ registeredVMList ] );

    const deleteRequest = (e, serialNumber) => {
        deleteRegisteredVendingMachine({"serialNumber": serialNumber});
    }

    const populateAllRequest = () => {
        if(registeredVMList === null){
            return
        }
        return (
            registeredVMList.map((obj, index) => {
                return <tr style={{textAlign:"center"}} key = { index } >
                    <td>{ index + 1 }</td>
                    <td><a href={"/inventory/v1.2/list-registered-vending-machine/" + obj.serialNumber}>{ obj.serialNumber}</a></td>
                    <td>{obj.name}</td>
                    <td>{ obj.version }</td>
                    <td>{ new Date(obj.time).toString().split("GMT")[0] }</td>
                    <td>{ obj.authorised ? "Authorized" : "Unauthorized" }</td>
                    <td>
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-success"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}>
                            Registered
                        </Button>
                    </td>
                    <td>
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-danger"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => deleteRequest(e, obj.serialNumber) }>
                            Delete
                        </Button>
                    </td>
                </tr>
            })
        );
    }

    return (
        <Fragment>
            <div className="d-flex flex-column justify-content-center">
                <div className='text-primary mt-5 d-flex flex-row'>
                    <Link className='btn btn-light text-primary my-1' to='/'>
                        Back
                    </Link>
                    {/* <h3 className='mx-auto'>Register Vending Machine</h3> */}
                </div>
                <div>
                    <div className='text-primary mt-2 d-flex flex-row'>
                        <h3 className='mx-auto'>Registered Vending Machine</h3>
                    </div>
                    <br></br>
                    <Table striped bordered hover>
                        <thead>
                            <tr style={{textAlign:"center"}}>
                                <th>Index</th>
                                <th>Serial Number</th>
                                <th>Name</th>
                                <th>Version</th>
                                <th>Date</th>
                                <th>Permission</th>
                                <th>Register</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            { populateAllRequest() }
                        </tbody>
                    </Table>
                </div>
            </div>
        </Fragment>
    )
}

ListVM.PpropTypes = {
    getAllRegisteredVendingMachines:PropTypes.func.isRequired,
    registerVendingMachine:PropTypes.func.isRequired,
    deleteRegisteredVendingMachine: PropTypes.func.isRequired,
    registeredVMList: PropTypes.array
}

const mapStateToProps = state => ({
    registeredVMList: state.vendingMachine.registeredVMList,
    loading: state.vendingMachine.loading
});

export default connect( mapStateToProps, { getAllRegisteredVendingMachines,
     registerVendingMachine, deleteRegisteredVendingMachine } )( ListVM );