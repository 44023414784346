import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrder, updateOrder, verifyPayment } from '../../actions/order';
import {Container, Row, Col, Table, Button} from "react-bootstrap";


const Order = ({ match, getOrder,updateOrder, verifyPayment, order }) => {

    const [formData, setFormData] = useState({orderStatus: ""});
    const [checkButtonClick, setCheckButtonClick] = useState(false)
    useEffect( () => {
        if(order === undefined || order === null){
            getOrder(match.params.id);
        }else {
            setFormData({orderStatus: order.orderStatus});
        }
        if(order){
            setCheckButtonClick(false)
        }
    }, [ order] );

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {
        e.preventDefault();
        updateOrder(formData, order._id);
    };

    const checkPayment = () => {
        setCheckButtonClick(true)
        verifyPayment({_id: order._id});
    };

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const renderProductData = () => {
        return order ? (
            <tbody>
                { order.products.bundle.map(( element, index ) => {
                    return <tr key={index}>
                        <td> { "Bundle" } </td>
                        <td>{ element.brand }</td>
                        <td>{ element.quantity }</td>
                        <td>{ element.unitPrice }</td>
                        <td>{ element.totalPrice }</td>
                  </tr>
                })}
                { order.products.unit.map(( element, index ) => {
                    return <tr key={index}>
                        <td> { "Unit" } </td>
                        <td>{ element.brand }</td>
                        <td>{ element.quantity }</td>
                        <td>{ element.unitPrice }</td>
                        <td>{ element.totalPrice }</td>
                  </tr>
                })}                
            </tbody>            
        ) : null;
    }

    const renderOrderData = () => {
        return(
            <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "400px"}}>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Order ID</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'text'
                        placeholder = 'Order Id'
                        name = 'orderId'
                        value = { order ? order.orderId : "" }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Payment ID</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'text'
                        placeholder = 'Payment ID'
                        name = 'paymentId'
                        value = { order ? order.paymentId !== null ? order.paymentId: "" : "" }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Order Status</label>
                        <select name='orderStatus' value={ formData.orderStatus || "" }
                            type="text" className="custom-select"
                            disabled = {formData.orderStatus === "payment successful" ? true : false}
                            onChange={e => onChange(e)}>
                                <option value="payment successful">payment successful</option>
                                <option value="payment failed">payment failed</option>
                                <option value="payment canceled">payment canceled</option>
                                <option value="processing">processing</option>
                        </select>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Verify Payment</label>
                    <input onClick={e => checkPayment(e)}
                        disabled = {formData.orderStatus === "payment successful" || checkButtonClick === true ? true : false}
                        className = "form-control form-control-user btn btn-primary" type="button" value="Check"/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>GST</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'number'
                        placeholder = 'GST'
                        name = 'gst'
                        value = { order ? order.gst : "" }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Net Amount</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'number'
                        placeholder = 'Net Amount'
                        name = 'netAmount'
                        value = { order ? order.netAmount : "" }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Date & Time</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'text'
                        placeholder = 'Time'
                        name = 'time'
                        value = { order ? new Date(order.time) : "" }
                        disabled
                        required/>
                </div>
                <div className="form-group d-flex mr-2">
                        <input type='submit' className='btn btn-primary mx-auto mt-2'/>
                    </div>
                <div className='form-group d-flex mr-2'>
                    <h4>Products</h4>
                </div>
                <div className='form-group d-flex mr-2'>
                    {
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>Type</th>
                                <th>Brand</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Total Price</th>
                                </tr>
                            </thead>
                            { renderProductData() }
                        </Table>
                    }
                </div>
                <div className='form-group d-flex mr-2'>
                    <h4>Discount</h4>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Product Discount</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'number'
                        placeholder = 'Product Discount'
                        name = 'productDiscount'
                        value = { order ? order.discount ? order.discount.productDiscount : 0 : 0 }
                        onChange = {e => onChange(e) }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Coupon Discount</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'number'
                        placeholder = 'Coupon Discount'
                        name = 'couponDiscount'
                        value = { order? order.discount? order.discount.couponDiscount : 0 : 0}
                        onChange = {e => onChange(e) }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Total Discount</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'number'
                        placeholder = 'Total Discount'
                        name = 'totalDiscount'
                        value = { order ? order.discount? order.discount.totalDiscount : 0 : 0}
                        onChange = {e => onChange(e) }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <h4>Penalty</h4>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Quantity</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'number'
                        placeholder = 'Quantity'
                        name = 'quantity'
                        value = { order ? order.discount? order.penalty.quantity : 0 : 0 }
                        onChange = {e => onChange(e) }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Unit Price</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'number'
                        placeholder = 'Unit Price'
                        name = 'unitPrice'
                        value = { order ? order.discount? order.penalty.unitPrice : 0 : 0}
                        onChange = {e => onChange(e) }
                        disabled
                        required/>
                </div>
                <div className='form-group d-flex mr-2'>
                    <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Total Price</label>
                    <input
                        className = "form-control form-control-user"
                        type = 'number'
                        placeholder = 'Total Price'
                        name = 'totalPrice'
                        value = { order ? order.discount? order.penalty.totalPrice : 0 : 0}
                        onChange = {e => onChange(e) }
                        disabled
                        required/>
                </div>
            </form>
        );
    }

    const renderUserData = () => {
        if(order){
            return (
                <div style={{textAlign:"center"}}>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Phone : </label>
                        <h6 style={{display:"inline-block"}}> { order.user.phone } </h6>
                    </div>
                    <br></br>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Name : </label>
                        <h6 style={{display:"inline-block"}}> { order.user.name } </h6>
                    </div>
                    <br></br>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Email : </label>
                        <h6 style={{display:"inline-block"}}> { order.user.email } </h6>
                    </div>
                    <br></br>
                    <a className="btn btn-sm btn-primary ml-auto"
                        href={ '/inventory/v1.2/users/'+ order.user._id }> View </a>
                </div>
            );
        }
    }

    const renderVendingMachineData = () => {
        if(order){
            return (
                <div style={{textAlign:"center"}}>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Name : </label>
                        <h6 style={{display:"inline-block"}}> { order.vendingMachine.name } </h6>
                    </div>
                    <br></br>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Profile : </label>
                        <h6 style={{display:"inline-block"}}> { order.vendingMachine.profileName } </h6>
                    </div>
                    <br></br>
                    <div style={{display: "inline-block"}}>
                        <label style={{display:"inline-block", marginRight:"10px"}}> Region : </label>
                        <h6 style={{display:"inline-block"}}> { order.vendingMachine.region } </h6>
                    </div>
                    <br></br>
                    <a className="btn btn-sm btn-primary ml-auto"
                        href={ '/inventory/v1.2/vendingMachine/'+ order.vendingMachine._id }> View </a>
                </div>
            );
        }
    }

    return (
        <Fragment>
            {/* <div className="container-fluid"> */}
            <div className='d-flex flex-column justify-content-center'>
                <Container>
                    <Row>
                        <Col>
                            <div className='text-primary mt-2 d-flex flex-row'>
                                <h3 className='mx-auto'>Order Details</h3>
                            </div>
                            { renderOrderData() }
                        </Col>
                        <Col>
                            <Row>
                                <div className='text-primary mt-2 d-flex flex-row' style={{marginBottom:"20px"}}>
                                    <h3 className='mx-auto'>User Details</h3>
                                </div>
                                { renderUserData() }
                            </Row>
                            <Row style={{marginTop:"50px"}}>
                                <div className='text-primary mt-2 d-flex flex-row' style={{marginBottom:"20px"}}>
                                    <h3 className='mx-auto'>Vending Machine Details</h3>
                                </div>
                                { renderVendingMachineData() }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )

}

Order.PpropTypes = {
    getOrder : PropTypes.func.isRequired,
    updateOrder : PropTypes.func.isRequired,
    verifyPayment : PropTypes.func.isRequired
} 
const mapStateToProps = state => ({
    order: state.order.order,
    loading: state.order.loading
})

export default connect( mapStateToProps, { getOrder, updateOrder, verifyPayment } )( Order );