import {
    GET_CONTAINERS,
    ADD_CONTAINERS,
    ADD_STOCK,
    REMOVE_STOCK,
    GET_INVENTORY_STATUS,
    GET_CAN_STOCK,
    UPDATE_CONTAINER
 } from '../actions/types';


const initialState = {
    loading: true,
    containerinfo: {},
    stockData: [],
    uniqueBrands: [],
    cansInStock: {cans: null, pages: 1},
    brandsData : {},
    containersData: [],
    vmId: null
};

export default function( state = initialState, action){
    const { type, payload } = action;
    switch (type) {
        case GET_CONTAINERS:
            return{
                ...state,
                containerinfo : payload,
                brandsData : payload.brands,
                containersData : payload.containers,
                vmId : payload.vendingMachineId,
                loading : false,
            };
        case ADD_CONTAINERS:
            return{
                ...state,
                containersData: payload,
                loading : false
            };
        case ADD_STOCK:
            return {
                ...state,
                containerinfo : payload,
                loading: false
            };
        case REMOVE_STOCK:
            return {
                ...state,
                containerinfo : payload,
                loading: false
            }
        case UPDATE_CONTAINER:
            return {
                ...state,
                containersData: payload,
                loading: false
            }
        case GET_INVENTORY_STATUS:
            return {
                ...state,
                stockData: payload.stockData,
                uniqueBrands: payload.uniqueBrands,
                loading: false
            }
        case GET_CAN_STOCK:
            return {
                ...state,
                cansInStock: payload,
                loading: false
            }
        default:
            return state;
    }
}