import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken'


import {
   GET_REPORT,
   DOWNLOAD_PING_REPORT,
   LIST_REGISTERED_VENDING_MACHINE_PRODUCT,
   SESSION_ID_FOR_TESTING,
   PING_DATA_DOWNLOAD,
   LOCK_DATA_DOWNLOAD,
   SESSION_RESULTS

} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// // Get Report
// export const getReports = () => async dispatch => {
//     if( localStorage.token ){
//         setAuthToken( localStorage.token )
//     }
    
//     try {
//         const res =  await axios.get(`${API_URL}/regions`);

//         if(res.data.success){
//             dispatch({
//                 type : GET_REPORT,
//                 payload: res.data
//             });
//         }else{
//             dispatch( setAlert(res.data.error, 'danger') );
//         }
//     } catch (err) {
//         dispatch(setAlert('Failed to fetch report', 'danger'));
//     }
// }

// // Download Regions
// export const downloadReports = () => async dispatch => {
//     if( localStorage.token ){
//         setAuthToken( localStorage.token )
//     }

//     try {
//         const res =  await axios.get(`${API_URL}/regions`);

//         if(res.data.success){
//             dispatch({
//                 type : DOWNLOAD_PING_REPORT,
//                 payload: res.data
//             });
//         }else{
//             dispatch( setAlert(res.data.error, 'danger') );
//         }
//     } catch (err) {
//         dispatch(setAlert('Failed to download report', 'danger'));
//     }
// }

// Open Containers
export const openContainers = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/control-units/open`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfull', 'success'));
            dispatch({
                type : SESSION_ID_FOR_TESTING,
                payload: res.data.sessionId
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to send command', 'danger') );
    }
};


// Stock Update
export const stockUpdate = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/control-units/stock-update`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfully Opened Containers', 'success'));
            dispatch({
                type : SESSION_ID_FOR_TESTING,
                payload: res.data.sessionId
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to open containers', 'danger') );
    }
};

// Lock Close Verify For Stock Update
export const lockCloseVerify = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/control-units/lock-close-verify`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfully Opened Containers', 'success'));            
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to open containers', 'danger') );
    }
};

// Lock Status
export const lockStatus = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/control-units/lock-status`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfull', 'success'));
            dispatch({
                type : SESSION_ID_FOR_TESTING,
                payload: res.data.sessionId
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to send command', 'danger') );
    }
};


// Ping
export const ping = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/control-units/ping-for-testing`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfull', 'success'));
            dispatch({
                type : SESSION_ID_FOR_TESTING,
                payload: res.data.sessionId
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to send command', 'danger') );
    }
};

// Check Open
export const checkOpen = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/control-units/check-open`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfull', 'success'));
            dispatch({
                type : SESSION_ID_FOR_TESTING,
                payload: res.data.sessionId
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to send command', 'danger') );
    }
};

// Check Lock Status
export const checkLockStatus = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/control-units/check-lock-status`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfull', 'success'));
            dispatch({
                type : SESSION_ID_FOR_TESTING,
                payload: res.data.sessionId
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to send command', 'danger') );
    }
};

// Restart Vending Machhine
export const restartVendingMachine = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/control-units/vending-machine-restart`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfull', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to send command', 'danger') );
    }
};


// Get vending machine's which are already registered
export const getAllRegisteredVendingMachinesWithProducts = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.get(`${API_URL}/vending-machines/registered-vending-machines-products`, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : LIST_REGISTERED_VENDING_MACHINE_PRODUCT,
                payload: res.data.vendingMachines
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert('Failed to get vending machines list', 'danger'));
    }
}


// Get vending machine's ping report
export const getVendingMachinesPingReport = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.post(`${API_URL}/control-units/vending-machine-ping-report`,formData, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : PING_DATA_DOWNLOAD,
                payload: res.data.pigData
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert('Failed to get vending machines list', 'danger'));
    }
}


// Get vending machine's lock report
export const getVendingMachinesLockReport = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.post(`${API_URL}/control-units/vending-machine-lock-report`,formData, config);
        if(res.data.success){
            console.log(res.data.lockData)
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : LOCK_DATA_DOWNLOAD,
                payload: res.data.lockData
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert('Failed to get vending machines list', 'danger'));
    }
}

// Get vending machine's lock report
export const testingFeedback = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.post(`${API_URL}/control-units/testing-feedback`,formData, config);
        if(res.data.success){
            console.log(res)
            dispatch( setAlert('Success', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert('Failed to get vending machines list', 'danger'));
    }
}


// Get vending machine's which are already registered
export const getSessionResults = (sessionId) => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.get(`${API_URL}/control-units/session-results/${sessionId}`, config);
        if(res.data.success){
            dispatch( setAlert('Success', 'success') );
            dispatch({
                type : SESSION_RESULTS,
                payload: res.data.sessionResults
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert('Failed to get vending machines list', 'danger'));
    }
}