import { 
    GET_PROFILE,
    GET_PROFILES,
    GET_PROFILE_REQUEST,
    UPDATE_PROFILE_DATA,
    DELETE_PROFILE,
    DELETE_PROFILE_DATA,
    CREATE_PROFILE
} from '../actions/types';

const initialState = {
    profiles : [],
    profile : {},
    loading : false
}


export default (state = initialState, { type, payload }) => {    
    switch (type) {
        case GET_PROFILE:
            return {
                ...state,
                profile: payload,
                loading: false 
            };

        case CREATE_PROFILE:
            return {
                ...state,
                profile: payload,
                loading: true
            };
            
        case GET_PROFILES:
            return {
                ...state,
                profiles: payload,
                loading: false
            };
        
        case UPDATE_PROFILE_DATA:
            return {
                ...state,
                loading: true
            };
        
        case GET_PROFILE_REQUEST:
            return { 
                ...state,
                loading: payload
            };
        
        case DELETE_PROFILE:
            return { 
                ...state,
                loading: true
            };
            
        case DELETE_PROFILE_DATA:
            return { 
                ...state,
                loading: true
            };

        default:
            return state
    }
};