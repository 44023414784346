import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllUsers, getUserByQuery } from '../../actions/user';
import TableContainer from "./TableContainer";


const Users = ({getAllUsers, getUserByQuery, users, user, pages, activePage }) => {

    const [data, setData] = useState([])
    const [pageCount, setPage] = useState(0);
    const [currentPage, setActivePage] = useState(1);

    useEffect( () => {
        if(user !== undefined && user !== null && users === null){
            let userData = [{
                role: user.role,
                needDeposit: user.needDeposit ? "Yes" : "No",
                hasPenalty: user.hasPenalty ? "Yes" : "No",
                _id: user._id,
                needSecurityDeposit: user.needSecurityDeposit ? "Yes" : "No",
                phone: user.phone,
                name: user.name,
                email: user.email
            }];
            setData(userData);
            setPage(1);
            setActivePage(1);
            return;
        }
        if(users === undefined || users.length === 0){
            getAllUsers(currentPage);
        }else{
            let usersData = []
            users.forEach(user => {
                usersData.push({
                    role: user.role,
                    needDeposit: user.needDeposit ? "Yes" : "No",
                    hasPenalty: user.hasPenalty ? "Yes" : "No",
                    _id: user._id,
                    needSecurityDeposit: user.needSecurityDeposit ? "Yes" : "No",
                    phone: user.phone,
                    name: user.name,
                    email: user.email
                });
            });
            setData(usersData);
            setPage(pages);
            setActivePage(activePage);
        }
    }, [ users, user ] );

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                disableSortBy: true,
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Role',
                accessor: 'role',
            },
            {
                Header: 'Need Deposit',
                accessor: 'needDeposit',
            },
            {
                Header: 'Has Penalty',
                accessor: 'hasPenalty',
            },
            {
                Header: 'Security Deposit',
                accessor: 'needSecurityDeposit',
            }
        ],
        []
      );

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const initialState = {
        email:'',
        phone:''
    };

    const [formData, setFormData] = useState(initialState);

    const {
        email,
        phone
    } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const _filterUsers = () => {
        return(
            <form className='d-flex  mt-2 flex-wrap' onSubmit={ e => onSubmit(e) } >
                <div className='form-group d-flex mr-2'>
                    <label className='mt-2 mr-2'>Phone Number</label>
                    <input style={{maxWidth: '200px'}}
                        id='phone'
                        className="form-control form-control-user"
                        type='text'
                        placeholder='Phone Number'
                        name='phone'
                        value={phone}
                        onChange={e => onChange(e) }
                    />
                </div>
                
                <div className='form-group d-flex mr-2' style={{maxWidth: '450px'}}>
                    <label style={{...customLableStyle, width:'auto'}} className='mt-2 mr-2'>Email Id</label>
                    <input style={{maxWidth: '200px'}}
                        id='email'
                        className="form-control form-control-user"
                        type='text'
                        placeholder='User email id'
                        name='email'
                        value={email}
                        onChange={e => onChange(e) }
                    />
                </div>

                <div className='form-group d-flex mr-2' style={{width:'100%'}}>
                    <input type='submit' className='btn btn-primary mx-auto mt-2'/>
                </div>
            </form>
        )
    }

    const onSubmit = e => {
        e.preventDefault();
        console.log(formData);
        getUserByQuery(formData.phone, formData.email);        
    };

    const renderUserData = () => {
        if(data !== undefined && data.length > 0 ){
            return(
                <TableContainer columns={columns} data={data} pages={pageCount} activePage={currentPage} getAllUsers={getAllUsers} />
            );
        }
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className='d-flex  mt-5 flex-wrap'>

                {_filterUsers()}
                </div>
                <Link
                    to="/inventory/v1.2/users/new"
                    style={{'width':'200px'}}
                    className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-5 ml-1 ">
                        Add New User
                </Link>
                <div className='d-flex  mt-5 flex-wrap'>
                    {/* <!-- Users DataTales --> */}
                    <div className="card shadow mb-4 mt-2">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Users</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                { renderUserData() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

Users.PpropTypes = {
    getAllUsers : PropTypes.func.isRequired,
    getUserByQuery : PropTypes.func.isRequired,
    pages : PropTypes.Number,
    activePage : PropTypes.Number
} 
const mapStateToProps = state => ({
    users: state.user.users,
    user: state.user.user,
    pages: state.user.pages,
    activePage : state.user.activePage,
    loading: state.user.loading
})

export default connect( mapStateToProps, { getAllUsers, getUserByQuery } )( Users );