import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getVendingMachine } from '../../actions/vendingMachine';
import { bulkAddToStock , addContainers, getContainers } from '../../actions/inventory';
import Container from './Container';
import { Button } from 'react-bootstrap';
import { stockUpdate } from '../../actions/test'


const Inventory = ({
    match,
    vendingMachine,
    getVendingMachine,
    bulkAddToStock,
    addContainers,
    loading,
    getContainers,
    containerinfo,
    brandsData,
    containersData,
    vmId,
    stockUpdate
}) => {
    const [maxContainers, setMaxContainers] = useState(0)
    const [isNewInventory, setInventroyType] = useState(true);
    const [containers, setContainers] = useState({});
    const [brands, setBrands] = useState({});
    const [vendingMachineId, setVendingMachineId] = useState("");
    const [stockUpdateContainers, setStockUpdateContainers] = useState([]);
    const [emptyContainers, setEmptyContainers] = useState([]);
    const [formData, setBrandSelected] = useState({brandSelected:""});

    useEffect(() => {
        if (!vendingMachine ){
            getVendingMachine(match.params.id);
            getContainers(match.params.id);
        } else if( vendingMachine._id !== match.params.id){
            getVendingMachine(match.params.id);
            getContainers(match.params.id);
        } else if(vendingMachineId !== match.params.id){
            getContainers(match.params.id);
        }
        if(containersData.length){
            setInventroyType(false);
        }else{
            setInventroyType(true);
        }
        //Set vending machine id and brands
        if(vendingMachineId !== match.params.id){
            setVendingMachineId(match.params.id);
        }
        if(containersData){
            setBrands({ ...brandsData});
            setContainers([ ...containersData]);
            let emptyContainers = containersData.filter((container, index) => {
                return (container.isWorking && !container.isReserved && container.isEmpty && container.product != null) === true;
            })
            emptyContainers = emptyContainers.map(container => ({...container, selected:false}))
            setEmptyContainers(emptyContainers);
            setStockUpdateContainers(emptyContainers)
        }
    }, [ containersData ]);

    const  populateContainers = () => {
        if(vendingMachineId !== match.params.id){
            return;
        }
        return containers.map((container, index) => {
            return (
                <Container key={index} container={container} brands={brands} />
            );
        });
    }

    const populateContainersForStockUpdate = () =>{
        if(stockUpdateContainers.length === 0){
            return;
        }
        // console.log(containers)
        // let emptyContainers = containers.filter((container, index) => {
        //     return (container.isWorking && !container.isReserved && container.isEmpty && container.product != null) === true;
        // })
        // emptyContainers = emptyContainers.map(container => ({...container, selected:false}))
        // console.log(emptyContainers)
        // console.log(brands)
        // setStockUpdateContainers({...emptyContainers})
        return stockUpdateContainers.map((container, index) => {
            return(
                <div key = {index} className="col-xl-3 col-md-6 mb-4" style={{'cursor':'pointer', height:"200px"}} onClick= {() => selectContainersForStockUpdate(index)}>
                    <div className="card border-left-primary shadow h-100" style={{backgroundColor:"#f8f9fa!important"}}>
                        <div className="card-head" style={{textAlign: "center", fontSize: "25px", padding: ".75rem 1.25rem",
                            backgroundColor: "rgba(0,0,0,.03)", borderBottom: "1px solid rgba(0,0,0,.125)"}}>{container.containerId}</div>
                        <div className="card-body" style={{textAlign:"center", fontSize:"40px"}}>{brands[container.product]}</div>
                        <div className="card-text" style={{fontSize: "xx-large", display:"flex"}}>
                            {container.selected && <i className="fa fa-check-circle" style={{color:"green", marginLeft:"auto",
                                paddingRight: "3%", paddingBottom:"3%"}}></i>}
                        </div>
                    </div>
                </div>
            )
        });
    }

    const selectContainersForStockUpdate = (index) => {
        const containersList = [...stockUpdateContainers]
        containersList[index].selected = !containersList[index].selected;
        setStockUpdateContainers(containersList)
    }
    
    const onChange = e => {
        setMaxContainers(e.target.value);
    }

    const onSubmit = e => {
        e.preventDefault();
        if(maxContainers){
            let containerBody = { vendingMachine: vendingMachine._id, containers: maxContainers };
            addContainers(containerBody);
        }
    };
    const addContainerUI = () => {
        if( !isNewInventory ){
            return(                
                <Button style={{marginLeft: "1%"}} onClick={ e => addNewContainer() }>Add Container</Button>
            );
        }
    }
    const addNewInvntoryFormUI = () => {
        if( isNewInventory ) {
            return(
                <div className='d-flex flex-column justify-content-center'>
                    <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "400px"}}> 
                        <div className='form-group d-flex mr-2'>
                            <label style={{'whiteSpace': 'nowrap'}} className='mt-2 mr-2'>Max Containers</label>
                            <input
                                id='maxContainers'
                                className="form-control form-control-user" 
                                type='number'
                                placeholder='Max Containers'
                                name='maxContainers'
                                value={maxContainers}
                                onChange={e => onChange(e) }
                            />
                        </div>                
                        <input type='submit' className='btn btn-primary mx-auto' value={isNewInventory?'Add':'Update'} />
                    </form>
                </div>
            );
        }
    }


    const addNewContainer = () => {        
        let containerBody = { vendingMachine: vendingMachine._id, containers: 1 };
        addContainers(containerBody);
    }
    const clearSelectedContainers = () => {
        let containersList = [...stockUpdateContainers]
        containersList = containersList.map(container => ({...container, selected:false}))
        setStockUpdateContainers(containersList)

    }

    const addToStockBulk = () => {
        let data = {containers:[], vendingMachine: vendingMachine._id}
        stockUpdateContainers.forEach((container)=>{
            if(container.selected){
                data.containers.push(container._id)
            }
        })
        bulkAddToStock(data);
    }

    const filterBrand = (e) => {
        setBrandSelected({...formData, [e.target.name]: e.target.value})
        let filteredData = emptyContainers.filter((container, index) => {
            if(e.target.value === "all"){
                return true;
            }
            return container.product === e.target.value
        })
        setStockUpdateContainers(filteredData);
    }

    const openForStockUpdate = () => {
        let data = {vendingMachineId: vendingMachine.vendingMachineId, containers:[]}
        stockUpdateContainers.forEach((container)=>{
            if(container.selected){
                data.containers.push(container.containerId)
            }
        })
        stockUpdate(data)
    }

    const populateBrands = () => {
        if(!brands){
            return;
        }
        return Object.keys(brands).map((key) => {
            if(brands[key] === "Refundable Deposit"){
                return;
            }
            return (
                <option key={`brand-${key}`} value={key}>{brands[key]}</option>
            )
        });
    }

    const {
        brandSelected
    } = formData;


    return (
        <Fragment>
            <div className='text-primary d-flex flex-row'>
                <Link className='btn btn-sm btn-light text-primary '
                to={`/inventory/v1.2/vendingMachine/${vendingMachine? vendingMachine._id:""}`}>
                    Back
                </Link>
            </div> 
            
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'>{`Inventory - ${vendingMachine?vendingMachine.name:""}`}</h3>
            </div>
            { addNewInvntoryFormUI() }

            {/* Add New Containers */}
            { addContainerUI() }
            
            <div id='containers' className='d-flex  mt-5 flex-wrap'>
                {containers.length ?  populateContainers() : "" }
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'> Stock Update</h3>
                <br></br>
                <br></br>
                <br></br>
            </div>
            <div className='text-primary d-flex flex-row'>
                <form >
                    <div className='text-primary d-flex flex-row'>
                        <span style={{fontSize: "x-large", marginRight:"15px"}}>Brand Filter :</span>
                        <select
                            style={{ "width" : "150px" }}
                            className="form-control form-control-user ml-auto"
                            value={brandSelected ? brandSelected : ""}
                            name='brandSelected'
                            onChange={e => filterBrand(e) }>
                                <option value="all">All</option>
                                { populateBrands() }
                        </select>
                    </div>
                </form>
                <Button  style={{marginLeft: "auto"}} onClick={ e => openForStockUpdate() }>OPEN</Button>
                <Button  style={{marginLeft: "1%"}} onClick={ e => clearSelectedContainers() }>CLEAR</Button>
                <Button  style={{marginLeft: "1%"}} onClick={ e => addToStockBulk() }>ADD TO STOCK</Button>
            </div>
            <div className='d-flex  mt-5 flex-wrap'>
                {containers.length ? populateContainersForStockUpdate() : ""}
            </div>
            {/* <div>
                <Card
                    bg="light"
                    key="light"
                    text={"light" === 'light' ? 'dark' : 'white'}
                    style={{ width: '18rem', height: '9rem' }}
                    className="mb-2"
                    >
                <Card.Header style={{textAlign:"center", fontSize:"25px"}}> 3</Card.Header>
                <Card.Body>
                    <Card.Title style={{textAlign:"center"}}> GOKUL </Card.Title>
                    <Card.Text>
                        <i className="fa fa-check"></i>
                    </Card.Text>
                </Card.Body>
                </Card>
            </div>
            <div className="col-xl-3 col-md-6 mb-4" style={{'cursor':'pointer'}}>
                <div className="card border-left-primary shadow h-100" style={{backgroundColor:"#f8f9fa!important"}}>
                    <div className="card-head" style={{textAlign: "center", fontSize: "25px", padding: ".75rem 1.25rem",
                        backgroundColor: "rgba(0,0,0,.03)", borderBottom: "1px solid rgba(0,0,0,.125)"}}>3</div>
                    <div className="card-body" style={{textAlign:"center", fontSize:"40px"}}>GOKUL</div>
                    <div className="card-text" style={{fontSize: "xx-large", display:"flex"}}>
                        <i className="fa fa-check-circle" style={{color:"green", marginLeft:"auto",
                            paddingRight: "3%", paddingBottom:"3%"}}></i>
                    </div>
                </div>
            </div> */}
        </Fragment>
    );
}
Inventory.propTypes = {
    vendingMachine: PropTypes.object,
    getVendingMachine: PropTypes.func.isRequired,
    bulkAddToStock: PropTypes.func.isRequired,
    addContainers: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    getContainers: PropTypes.func.isRequired,
    containerinfo: PropTypes.object,
    brandsData: PropTypes.object,
    containersData: PropTypes.array,
    vmId: PropTypes.string
};

const mapStateToProps = state => ({
    vendingMachine: state.vendingMachine.vendingMachine,
    loading: state.vendingMachine.loading,
    containerinfo: state.inventory.containerinfo,
    brandsData: state.inventory.brandsData,
    containersData: state.inventory.containersData,
    vmId: state.inventory.vmId
});

export default connect(
    mapStateToProps, 
    { bulkAddToStock, getContainers, addContainers, getVendingMachine, stockUpdate }
)(Inventory);

