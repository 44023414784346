import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import {
    GET_CONTAINERS,
    ADD_CONTAINERS,
    ADD_STOCK,
    REMOVE_STOCK,
    GET_INVENTORY_STATUS,
    GET_CAN_STOCK,
    UPDATE_CONTAINER
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;


// Open Container at Vending Machine
// export const openContainer = (data) => async dispatch => {
//     try{
//         // @route    POST /api/v1/containers/open
//         const config = {
//             headers: { 'Content-Type' : 'application/json' }
//         };
//         const res = await axios.post( `${API_URL}/containers/open`, data, config );
//         if(res.data.success)
//             dispatch(setAlert(res.data.data, 'success'));
//         else
//             dispatch(setAlert(res.data.data, 'danger'));
//     }catch (err) {
//         dispatch( setAlert('Error: Failed to Open Container', 'danger') );
//     }
// }

export const openContainer = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.post(`${API_URL}/control-units/check-open`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfull', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to send command', 'danger') );
    }
};

//@desc     Add to stock (Redis)
//@route    post /api/v1.2/containers/add-stock
//@access   Private
export const addToStock = (data) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.post(`${API_URL}/containers/add-stock`, data, config);
        if(res.data.success){
            dispatch({
                type : UPDATE_CONTAINER,
                payload: res.data.containers
            });
            dispatch(setAlert('Inventory Updated', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update Inventory', 'danger') );
    }
};

//@desc     Bulk add to stock (Redis)
//@route    post /api/v1.2/containers/add-stock-bulk
//@access   Private
export const bulkAddToStock = (data) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.post(`${API_URL}/containers/add-stock-bulk`, data, config);
        if(res.data.success){
            dispatch({
                type : UPDATE_CONTAINER,
                payload: res.data.containers
            });
            dispatch(setAlert('Inventory Updated', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update Inventory', 'danger') );
    }
};

//@desc     Remove from stock (Redis)
//@route    post /api/v1.2/containers/remove-stock
//@access   Private
export const removeFromStock = (data) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.post(`${API_URL}/containers/remove-stock`, data, config);
        if(res.data.success){
            dispatch({
                type : UPDATE_CONTAINER,
                payload: res.data.containers
            });
            dispatch(setAlert('Inventory Updated', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update Inventory', 'danger') );
    }
};

//@desc     Update Container
//@route    PUT /api/v1.2/container/
//@access   Private
export const updateContainer = (payload) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };  
    try {
        const res =  await axios.put(`${API_URL}/containers?id=${payload.container}`, payload.data, config);
        if(res.data.success){
            dispatch({
                type : UPDATE_CONTAINER,
                payload: res.data.containers
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to update Container', 'danger') );
    }
};

//@desc     Add a container to a vending machine
//@route    POST /api/v1.2/containers
//@access   Private
export const addContainers = (data) => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const res =  await axios.post(`${API_URL}/containers/`, data, config);
        if(res.data.success){
            dispatch({
                type: ADD_CONTAINERS,
                payload: res.data.containers
            });
            dispatch(setAlert('Container Added', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to add Container', 'danger') );
    }
};


//@desc     Delete a container
//@route    DELETE /api/v1.2/container/
//@access   Private
export const deleteContainer = ( payload ) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    };
    try {
        const res =  await axios.delete(`${API_URL}/containers/`, config);
        if(res.data.success){
            dispatch(setAlert('Container Deleted', 'success'));
            dispatch({
                type: UPDATE_CONTAINER,
                payload: res.data.containers
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to delete Container', 'danger') );
    }
};

//@desc     Get Containers of a vending machine
//@route    GET /api/v1.2/containers
//@access   Private
export const getContainers = (id) => async dispatch => {
    try {
        const res =  await axios.get(`${API_URL}/containers?vendingMachine=${id}`);
        if(res.data.success){
            dispatch({
                type : GET_CONTAINERS,
                payload: res.data
            });
            dispatch( setAlert('Successfully fetched Vending Machine container details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get vending machine details', 'danger'));
    }
};

// Get inventory status of all vending machines
export const getInventoryStatus = () => async dispatch => {
    try {
        const res =  await axios.get(`${API_URL}/containers/all`);
        if(res.data.success){
            dispatch({
                type : GET_INVENTORY_STATUS,
                payload: res.data
            });
            dispatch( setAlert('Successfully fetched inventory details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get inventory details', 'danger'));
    }
};

// Get can stock of all vending machines
export const getCansInStock = (filters) => async dispatch => {
    try {
        const res =  await axios.get(`${API_URL}/containers/cans-in-stock?${filters}`);
        if(res.data.success){
            dispatch({
                type : GET_CAN_STOCK,
                payload: res.data.cansInStock
            });
            dispatch( setAlert('Successfully fetched can stock', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get can stock', 'danger'));
    }
};

//@desc     Remove product
//@route    PUT /api/v1.2/containers/remove-product
//@access   Private
export const removeProduct = (payload, vendingMachineId) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
        const res =  await axios.put(`${API_URL}/containers/remove-product`, payload, config);
        if(res.data.success){
            dispatch(setAlert('Container Updated', 'success'));
            dispatch({
                type: UPDATE_CONTAINER,
                payload: res.data.containers
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to remove product', 'danger') );
    }
};