import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken'
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    USER_LOADED,
    AUTH_ERROR
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get Current user
export const laodUser = () => async dispatch => {

    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    try {
        const res = await axios.get(`${API_URL}/auth/me`);
        if(res.data.success){
            dispatch({ type: USER_LOADED, payload: res.data.data });
        }else{
            dispatch({ type: AUTH_ERROR });
        }
    } catch (err) {
        dispatch({ type: AUTH_ERROR });
    }
}

//Login user
export const login = ({ email=null, password=null, phone =null, OTP=null }) =>  async dispatch =>{
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    var body= JSON.stringify({ email, password });
    if(OTP){
        body= JSON.stringify({ 'phone':phone, 'OTP': OTP });
    }
    try {
        const validUsersRes = await axios.post(`${API_URL}/auth/check`, body, config);
        if(validUsersRes.data.success){
            const res = await axios.post(`${API_URL}/auth/login`, body, config);
            if(!res.data.success){
                dispatch( setAlert(res.data.error,'danger'));
                dispatch({ type: LOGIN_FAIL });
            }else{
                dispatch( setAlert('Login Success','success') );
                //Set Common Auth header for axious
                setAuthToken( res.data.token )
                dispatch({ type: LOGIN_SUCCESS, payload: res.data });
            }
        }
    } catch (err) {
        dispatch( setAlert(err.response.data.error, 'danger') );
        dispatch({ type: LOGIN_FAIL });
        
    }
}

//Get OTP
export const getOTP = ({ phone }) => async dispatch => {
    const body = JSON.stringify({ 'phone':phone, 'resend':false });
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const validUsersRes = await axios.post(`${API_URL}/auth/check`, body, config);
        if(validUsersRes.data.success){
            const res = await axios.post(`${API_URL}/auth/otp`, body, config);
            if(!res.data.success){
                dispatch( setAlert(res.data.error,'danger') );
            }else{
                dispatch( setAlert('OTP sent', 'success', 1000) );
            }
        }
    } catch (err) {
        dispatch( setAlert(err.response.data.error, 'danger') );
    }
}

// Logout
export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
};