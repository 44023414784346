import {
    GET_TRANSACTION_LEDGER
} from '../actions/types';

const initialState = {
    transactions: undefined,
    loading: true,
    pageList : [],
    activePage: 0,
    pages: 0
};

export default ( state = initialState, { type, payload }) => {
    switch (type) {
        case GET_TRANSACTION_LEDGER:
            return {
                ...state,
                transactions: payload.transactions,
                pages: payload.pages,
                pageList: payload.pageList,
                activePage: payload.activePage,
                loading: false
            };

        default:
            return state;
    }
};