import { 
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USER_REQUEST,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    GET_USER_BY_QUERY,
    UPDATE_USER_CANS
} from '../actions/types';

const initialState = {
    users: [],
    user: null,
    deposit: null,
    cans: null,
    penalties: null,
    loading: true,
    activePage: 0,
    pages: 0,
    note: ""
};

export default ( state = initialState, { type, payload }) => {
    switch (type) {
        
        case GET_ALL_USER_REQUEST:
            return {
                ...state,
                loading: payload
            };

        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: payload.data,
                pages: payload.pages,
                activePage: payload.activePage,
                loading: false
            };

        case GET_USER_REQUEST:
            return {
                ...state,
                loading: payload
            };

        case GET_USER_SUCCESS:
            return {
                ...state,
                user: payload.user,
                deposit: payload.deposit,
                cans: payload.cans,
                penalties: payload.penalty,
                note: payload.note,
                loading: false
            };
        case ADD_USER_REQUEST:
            return {
                ...state,
                loading: payload
            };

        case ADD_USER_SUCCESS:
            return {
                ...state,
                user: payload,
                loading: false
            };

        case GET_USER_BY_QUERY:
            return{
                ...state,
                user: payload,
                users: null
            }

        case UPDATE_USER_CANS:
            return{
                ...state,
                cans : payload
            }

        default:
            return state;
    }
};