// TableContainer.js
import React from "react"
import { useTable, useFilters, useSortBy, useExpanded, usePagination } from "react-table"
import { Table, Input } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './Filters';
import Pagination from 'react-bootstrap/Pagination';

const TableContainer = ({ columns, data, pages, activePage, queryString, getTransactionLedger }) => {
  const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
    	pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
		defaultColumn: { Filter: DefaultColumnFilter },
		initialState: { pageIndex: 0, pageSize: 50 }
    },
    useFilters,
	useSortBy,
	useExpanded,
    usePagination
  	)

	const generateSortingIndicator = (column) => {
		return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
	};

	const onChangeInInput = event => {
		const pageNo = event.target.value ? Number(event.target.value) : 0
		let query = "";
		if(queryString){
			query = queryString.replace("page=" + activePage, "page=" + pageNo);
		}else{
			query = "page=" + pageNo + "&latest=true";
		}
		getTransactionLedger(query);
	}

	const onPageChange = event => {
		let pageNo = event.currentTarget.id;
		let query = "";
		if(queryString){
			query = queryString.replace("page=" + activePage, "page=" + pageNo);
		}else{
			query = "page=" + pageNo + "&latest=true";
		}
		getTransactionLedger(query);
	}

  return (
    // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
	// Feel free to use console.log()  This will help you better understand how react table works underhood.
	<React.Fragment>
		<Table bordered hover {...getTableProps()}>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
						<th {...column.getHeaderProps()}>
							<div {...column.getSortByToggleProps()}>
							{column.render('Header')}
							{generateSortingIndicator(column)}
							</div>
							<Filter column={column} />
						</th>
						))}
					</tr>
				))}
			</thead>

			<tbody {...getTableBodyProps()}>
				{page.map(row => {
				prepareRow(row)
				return (
					<tr {...row.getRowProps()}>
						{row.cells.map(cell => {
							if(cell.column.Header === "Vending Machine"){
								return <td {...cell.getCellProps()}> 
									<a href= {"vendingMachine/" + cell.row.original.vendingMachineId}>{cell.render("Cell")}</a></td>
							}else if(cell.column.Header === "Transaction Id"){
								return <td {...cell.getCellProps()}> 
									<a href= {"transactions/" + cell.row.original._id}>{cell.render("Cell")}</a></td>
							}
							else if(cell.column.Header === "Status"){
								return <td {...cell.getCellProps()} style={{color: row.values.status !== "opened" ? "red":"black"}}>{cell.render("Cell")}</td>
							}
							else if(cell.column.Header === "User"){
								return <td {...cell.getCellProps()}>
									<a href= {"Users/" + cell.row.original.userId}>{cell.render("Cell")}</a></td>
							}
							else return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
						})}
					</tr>
				)
				})}
			</tbody>
		</Table>
		<div className="pagination" style={{width: '100%'}}>
                <Pagination style={{margin: "auto", padding: "4% 1% 4% 1%"}}>
                    <Pagination.First id={1} onClick={onPageChange}/>
                    <Pagination.Prev disabled={activePage === 1? true : false} id={activePage -1} onClick={onPageChange}/>
					<div className="container" style={{ width: "320px" }}>
						<div className="row justify-content-center">
							<div className="col-8" style={{textAlign: "center", margin: "auto"}}>
								Page{" "} <strong> {activePage} of {pages} </strong>
							</div>
							<div className="col-4">
								<Input type="number" min={1} 
									style={{ width: 70 }} max={pages} 
									defaultValue={activePage} onChange={onChangeInInput} />
							</div>
						</div>
					</div>					
					<div>
						
					</div>                    
                    <Pagination.Next disabled={activePage === pages? true : false} id={activePage + 1}onClick={onPageChange}/>
                    <Pagination.Last id={pages} onClick={onPageChange}/>
                </Pagination>
            </div>		
	</React.Fragment>
  )
}

export default TableContainer