import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCansInStock } from '../../actions/inventory';

import TableContainer from "./TableContainer";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const CanStock = ({
    loading,
    cansInStock,
    getCansInStock
}) => {
    const initialState = {
        vendingMachineId: '',
        download: false,
        startDate: '',
        endDate: '',
        days: true
    };

    const [formData, setFormData] = useState(initialState);
    const [pages, setPages] = useState(0);
    const [canData, setCanData] = useState([]);
    const [queryString, setQueryString] = useState("");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    useEffect(() => {
        if(cansInStock.cans === null){
            getCansInStock("page=1&days=true");
        }
        const exportToCSV = (apiData) => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            const fileName = "cans_in_stock";
            FileSaver.saveAs(data, fileName + fileExtension);
            setFormData({ ...formData, ["download"]: false });
        };
        if(cansInStock.cans){
            let mappedCanData = [];
            cansInStock.cans.forEach(can => {
                let canAge = Math.ceil((new Date().getTime() - new Date(can.updatedAt).getTime())/(1000 * 3600 * 24));
                mappedCanData.push({name: can.vendingMachine.name, vendingMachineId: can.vendingMachine.vendingMachineId,
                containerId: can.containerId, brand: can.product.brand, days: canAge,
                updatedAt: new Date(can.updatedAt).toString().split("GMT")[0]});
            });
            setCanData(mappedCanData);
            if(cansInStock.currentPage ===1){
                setPages(cansInStock.pages);
            }
            if(formData.download){
                exportToCSV(mappedCanData);
            }
        }
        if(canData.length === 0 && cansInStock.cans !== null){
            getCansInStock("page=1&days=true");
        }
    }, [ cansInStock ]);

    const columns = useMemo(
        () => [
            {
                Header: 'VM Name',
                accessor: 'name',
            },
            {
                Header: 'VM ID',
                accessor: 'vendingMachineId',
                disableSortBy: true,
            },
            {
                Header: 'Container ID',
                accessor: 'containerId'
            },
            {
                Header: 'Product',
                accessor: 'brand',
            },
            {
                Header: 'Stocked On',
                accessor: 'updatedAt',
            },{
                Header: 'Days Old',
                accessor: 'days',
            },
        ],
        []
    );

    const  populateTable = () => {
        return(
            <TableContainer columns={columns} data={canData} pages={pages} activePage={cansInStock.currentPage} queryString={queryString} getRequest={getCansInStock} />
        );
    }    

    const {
        vendingMachineId,
        download,
        startDate,
        endDate,
        days
    } = formData;

    const onChange = (e) => {
        if(e.target.name === "days" || e.target.name === "download"){
            setFormData({ ...formData, [e.target.name]: e.target.value === "true" ? true : false });
        }else{
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }        
    }

    const onSubmit = e => {
        e.preventDefault();
        const endDateTime = new Date(formData.endDate).getTime();
        const statDateTime = new Date(formData.startDate).getTime();
        if(endDateTime < statDateTime){
            return alert("Invalid date")
        }
        let queryString = "page=1&days=" + formData.days + "&download=" + formData.download;
        if(formData.startDate && formData.endDate){
            queryString += "&startDate=" + formData.startDate + "&endDate=" + formData.endDate;
        }
        if(formData.vendingMachineId){
            queryString += "&vendingMachine=" + formData.vendingMachineId;
        }
        setQueryString(queryString)
        getCansInStock(queryString);
    };

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const seachForm = () => {
        return(
            <form className='d-flex  mt-2 flex-wrap'
                onSubmit={ e => onSubmit(e) }>
                <div className='form-group d-flex mr-2' style={{maxWidth: '350px'}}>
                    <label style={customLableStyle, {width:'auto'}} className='mt-2 mr-2'>VM ID</label>
                    <input style={{maxWidth: '200px'}}
                        id='vendingMachineId'
                        className="form-control form-control-user"
                        type='text'
                        placeholder='Vending Machine Id'
                        name='vendingMachineId'
                        value={vendingMachineId}
                        onChange={e => onChange(e) }
                    />
                </div>                
                <div className='form-group d-flex mr-2' style={{maxWidth: '350px'}}>
                    <label style={customLableStyle} className='mt-2 mr-2'>Start Date</label>
                    <input style={{maxWidth: '200px'}}
                        id='startDate'
                        className="form-control form-control-user"
                        type='date'
                        placeholder='Start Date'
                        name='startDate'
                        value={startDate}
                        onChange={e => onChange(e) }
                    />
                </div>
                <div className='form-group d-flex mr-2' style={{maxWidth: '350px'}}>
                    <label style={customLableStyle} className='mt-2 mr-2'>End Date</label>
                    <input style={{maxWidth: '200px'}}
                        id='endDate'
                        className="form-control form-control-user"
                        type='date'
                        placeholder='End Date'
                        name='endDate'
                        value={endDate}
                        onChange={e => onChange(e) }
                    />
                </div>
                <div className='form-group d-flex mr-2' style={{maxWidth: '350px'}}>
                    <label style={customLableStyle} className='mt-2 mr-2'>View By</label>
                    <select
                        id ='days'
                        className="custom-select mr-sm-2"
                        type ='text'
                        value = {days}
                        name ='days'
                        onChange={e => onChange(e) }>
                            <option value="true">Oldest</option>
                            <option value="false">Newest</option>
                    </select>
                </div>
                <div className='form-group d-flex mr-2' style={{maxWidth: '350px'}}>
                    <label style={customLableStyle} className='mt-2 mr-2'>Download</label>
                    <select
                        id ='download'
                        className="form-control form-control-user" 
                        type ='text'
                        value = {download}
                        name ='download'
                        onChange={e => onChange(e) }>
                            <option value="true">True</option>
                            <option value="false">False</option>
                    </select>
                </div>
                <div className='form-group d-flex mr-2' style={{width:'100%'}}>
                    <input type='submit' className='btn btn-primary mx-auto mt-2'/>
                </div>
            </form>
        )
    }

    return (
        <Fragment>
            <div className='text-primary d-flex flex-row'>
                
            </div>
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'>Cans In Stock</h3>
            </div>
            <div className='d-flex  mt-5 flex-wrap'>
                {  seachForm() }
            </div>
            
            <div id='containers' className='d-flex  mt-5 flex-wrap'>
                {cansInStock.cans && cansInStock.cans.length ?  populateTable() : "" }
            </div>
        </Fragment>
    );
}

CanStock.propTypes = {
    getCansInStock: PropTypes.func.isRequired,
    cansInStock: PropTypes.object
}

const mapStateToProps = state => ({
    cansInStock: state.inventory.cansInStock,
    loading: state.inventory.loading,
});

export default connect(mapStateToProps, { getCansInStock } )(CanStock);