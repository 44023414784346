import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Register from '../auth/Register';
import Login from '../auth/Login';
import Alert from '../layout/Alert';
import Dashboard from '../dashboard/Dashboard';
import NotFound from '../layout/NotFound';
import AddVendingMachine from '../vendingMachine/AddVendingMachine';
import VendingMachineDetails from '../vendingMachine/VendingMachineDetails';
import Inventory from '../vendingMachine/Inventory';
import Product from '../Product/Product';
import Products from '../Product/Products';
import Orders from '../Order/Orders';
import Order from '../Order/Order';
import Users from '../User/Users';
import User from '../User/User';
import Profile from '../Profile/Profile';
import Profiles from '../Profile/Profiles';
import Penalty from '../Penalty/Penalty';
import Regions from '../Region/Regions';
import Region from '../Region/Region';
import Transactions from '../CanTransactions/Transactions';
import Transaction from '../CanTransactions/Transaction';
import Downloads from '../Downloads/Downloads';
import Redis from '../Redis/Redis';
import LiveStatus from '../Live/LiveStatus';
import InventoryStatus from '../Inventory/Status';
import CanStock from '../Can/CanStock';
import PrivateRoute from '../routing/PrivateRoute';
import Ledger from '../Ledger/Ledger';
import RegisterVM from '../RegisterVM/Register';
import ListRegisteredVM from '../RegisterVM/List';
import RegistrationDetails from '../RegisterVM/RegistrationDetails';
import TransactionLedgers from '../TransactionLedger/Ledger';
import TestVM from '../TestVendingMachine/TestVM';
import Sales from '../Sales/Sales';
import AccountDeletion from '../DeleteAccount/Deletion';

const Routes = () => {
    return (
      <section className='container' style={{'maxWidth':'100%'}}>
        <Alert key='all-alert'/>
        <Switch>
          {/* <Route exact path='/inventory/v1.2/register' component={Register} /> */}
          <Route exact path='/inventory/v1.2/login' component={Login} />
          <PrivateRoute exact path='/inventory/v1.2/' component={Dashboard} />
          <PrivateRoute exact path='/inventory/v1.2/AddVendingMachine' component={AddVendingMachine} />
          <PrivateRoute exact path='/inventory/v1.2/vendingMachine/:id' component={VendingMachineDetails} />
          <PrivateRoute exact path='/inventory/v1.2/vendingMachine/:id/Inventory' component={Inventory} />
          <PrivateRoute exact path='/inventory/v1.2/products' component={Products} />
          <PrivateRoute exact path='/inventory/v1.2/products/:id' component={Product} />
          <PrivateRoute exact path='/inventory/v1.2/profiles' component={ Profiles } />
          <PrivateRoute exact path='/inventory/v1.2/profiles/:id' component={ Profile } />
          <PrivateRoute exact path='/inventory/v1.2/orders' component={Orders} />
          <PrivateRoute exact path='/inventory/v1.2/orders/:id' component={Order} />
          <PrivateRoute exact path='/inventory/v1.2/transactions' component={Transactions} />
          <PrivateRoute exact path='/inventory/v1.2/transactions/:id' component={Transaction} />
          <PrivateRoute exact path='/inventory/v1.2/users' component={Users} />
          <PrivateRoute exact path='/inventory/v1.2/users/:id' component={User} />
          <PrivateRoute exact path='/inventory/v1.2/penalty/:id' component={Penalty} />
          <PrivateRoute exact path='/inventory/v1.2/regions' component={Regions} />
          <PrivateRoute exact path='/inventory/v1.2/regions/:id' component={ Region } />
          <PrivateRoute exact path='/inventory/v1.2/downloads' component={ Downloads } />
          <PrivateRoute exact path='/inventory/v1.2/redis' component={Redis} />
          <PrivateRoute exact path='/inventory/v1.2/live-status' component={LiveStatus} />
          <PrivateRoute exact path='/inventory/v1.2/inventory-status' component={InventoryStatus} />
          <PrivateRoute exact path='/inventory/v1.2/cans-in-stock' component={CanStock} />
          <PrivateRoute exact path='/inventory/v1.2/ledger' component={Ledger} />
          <PrivateRoute exact path='/inventory/v1.2/register-vending-machine' component={RegisterVM} />
          <PrivateRoute exact path='/inventory/v1.2/list-registered-vending-machine' component={ListRegisteredVM} />
          <PrivateRoute exact path='/inventory/v1.2/list-registered-vending-machine/:id' component={RegistrationDetails} />
          <PrivateRoute exact path='/inventory/v1.2/transaction-ledger' component={TransactionLedgers} />
          <PrivateRoute exact path='/inventory/v1.2/test-vending-machine' component={TestVM} />
          <PrivateRoute exact path='/inventory/v1.2/sales' component={Sales} />
          <PrivateRoute exact path='/inventory/v1.2/account-deletion' component={AccountDeletion} />
          <Route component={NotFound} />
        </Switch>
      </section>
    );
};

export default Routes;