import React , {useState, useEffect, Fragment }from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"
import { openContainers, stockUpdate, lockStatus, getAllRegisteredVendingMachinesWithProducts, getSessionResults, getVendingMachinesLockReport,
    ping, checkOpen, checkLockStatus, restartVendingMachine, lockCloseVerify, getVendingMachinesPingReport } from '../../actions/test';
import { getAllRegisteredVendingMachines } from '../../actions/vendingMachine';
import {Container, Row, Col, Table} from "react-bootstrap";
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const TestVM = ({ getAllRegisteredVendingMachines, results, sessionId, getAllRegisteredVendingMachinesWithProducts, openContainers,
    ping, restartVendingMachine, checkOpen, checkLockStatus, lockStatus, stockUpdate, lockCloseVerify, getVendingMachinesPingReport,
    pingData, lockData, sessionResults, getSessionResults, getVendingMachinesLockReport  }) => {

    const initialState = {
        vendingMachineId: '',
        productId:'',
        vendingMachine: '',
        checkOpenContainersList: "",
        checkLockStatusContainersList: "",
        stockUpdateContainersList: "",
        lockStatusContainersList: "",
        openContainersList: "",
        failedContainerList: "",
        checkLockStatusChecked: false,
        checkOpenContainerChecked: false,
        lockStatusChecked: false,
        stockUpdateChecked: false,
        openContainerChecked: false
    };

    const [testData, setTestData] = useState(initialState);
    const [vendingMachines, setVM] = useState([]);
    const [products, setProducts] = useState([]);
    const [prevSessionId, setPrevSessionId] = useState("");
    const [allContainers, setAllContainers] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]);
    const [pingStartDate, setPingStartDate] = useState(new Date());
    const [lockStartDate, setLockStartDate] = useState(new Date());
    const [downloadRequestByPing, setPingDownloadRequest] = useState(false)
    const [downloadRequestByLock, setLockDownloadRequest] = useState(false)
    const [open, setFailedOpen] = useState(false)
    const [close, setFailedClose] = useState(false)
    const [operationTypeStockUpdate, setOperationTypeStockUpdate] = useState(false)
    const [operationTypeOpen, setOperationTypeOpen] = useState(false)
    // const [fileName, setFileName] = useState("");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    useEffect( () => {
        const exportToCSV = (fileName, apiData) => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        };
        if(pingData && downloadRequestByPing){
            exportToCSV("PingData", pingData);
            setPingDownloadRequest(false)
        }
        if(lockData && downloadRequestByLock){
            exportToCSV("LockData", lockData);
            setLockDownloadRequest(false)
        }
        if(results === null){
            // getAllRegisteredVendingMachines()
            getAllRegisteredVendingMachinesWithProducts()
        }else{
            let vm = [];
            let products = [];
            for (const key in results) {
                vm.push({name: results[key].name, _id: results[key]._id, vendingMachineId: results[key].vendingMachineId});
            }
            setVM(vm)
        }

    }, [ results, products, pingData, lockData]);


    const onChange = e => {
        if(e.target.name === "vendingMachines"){
            const vendingMachineId = e.target.value
            setTestData({...testData, ["vendingMachineId"]: e.target.value});
            for (const key in results) {
                if(results[key].vendingMachineId === vendingMachineId){
                    let brands = [];
                    for(const brand of results[key].brands){
                        if(brand.brand === "DEPOSIT"){
                            continue
                        }
                        brands.push(brand)
                    }
                    setProducts(brands)
                    return
                }
            }
            return
        }
        if(e.target.name === "products"){
            setTestData({...testData, ["productId"]: e.target.value});
        }
        if(e.target.name === "openContaier"){
            setTestData({...testData, ["openContainersList"]: e.target.value});
            return
        }
        if(e.target.name === "checkOpenContaier"){
            setTestData({...testData, ["checkOpenContainersList"]: e.target.value});
            return
        }
        if(e.target.name === "lockStatus"){
            setTestData({...testData, ["lockStatusContainersList"]: e.target.value});
            return
        }
        if(e.target.name === "checkLockStatus"){
            setTestData({...testData, ["checkLockStatusContainersList"]: e.target.value});
            return
        }
        if(e.target.name === "stockUpdateOpenContaier"){
            setTestData({...testData, ["stockUpdateContainersList"]: e.target.value});
            return
        }
        if(e.target.name === "checkOpenContaierAll"){
            setTestData({...testData, ["checkOpenContainerChecked"]: checkOpenContainerChecked? false:true});
            return
        }
        if(e.target.name === "openContaierAll"){
            setTestData({...testData, ["openContainerChecked"]: openContainerChecked? false:true});
            return
        }
        if(e.target.name === "stockUpdateOpenContaierAll"){
            setTestData({...testData, ["stockUpdateChecked"]: stockUpdateChecked? false:true});
            return
        }
        if(e.target.name === "lockStatusAll"){
            setTestData({...testData, ["lockStatusChecked"]: lockStatusChecked? false:true});
            return
        }
        if(e.target.name === "checkLockStatusAll"){
            setTestData({...testData, ["checkLockStatusChecked"]: checkLockStatusChecked? false:true});
            return
        }
        if(e.target.name === "prevSessionId"){
            setPrevSessionId(e.target.value)
        }
        if(e.target.value === "openContainer"){
            setFailedOpen(true)
            setFailedClose(false)
        }
        if(e.target.value === "closeContainer"){
            setFailedClose(true)
            setFailedOpen(false)
        }
        if(e.target.value === "operationOpen"){
            setOperationTypeStockUpdate(false)
            setOperationTypeOpen(true)
        }
        if(e.target.value === "operationStockUpdate"){
            setOperationTypeStockUpdate(true)
            setOperationTypeOpen(false)
        }
        if(e.target.name === "feedback"){
            setTestData({...testData, ["failedContainerList"]: e.target.value});
            return
        }
    }
    
    const sendPingRequest = e => {
        if(vendingMachineId === ""){
            alert("Select Vending Machine")
        }else{
            ping({vendingMachineId: vendingMachineId});
        }
    }

    const restartVendingMachineRequest = e => {
        restartVendingMachine({vendingMachineId: vendingMachineId});
    }

    const sessionResultsRequest = e =>{
        let requestSessionId = prevSessionId ? prevSessionId:sessionId;
        getSessionResults(requestSessionId);

    }

    const downloadPingDataRequest = e =>{
        if(vendingMachineId === ""){
            alert("Select Vending Machine")
        }else{
            let date = new Date(pingStartDate)
            date = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
            getVendingMachinesPingReport({vendingMachineId:vendingMachineId, startDate:date});
            setPingDownloadRequest(true)
        }
    }

    const downloadLockDataRequest = e =>{
        if(vendingMachineId === ""){
            alert("Select Vending Machine")
        }else{
            let date = new Date(lockStartDate)
            date = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
            getVendingMachinesLockReport({vendingMachineId:vendingMachineId, startDate:date});
            setLockDownloadRequest(true)
        }        
    }
    const openContainerFunction = () => {
        if(!vendingMachineId || !productId){
            alert("select Vending Machine and Product");
            return
        }
        let is_valid =  /^[0-9,.]*$/.test(openContainersList)
        if(is_valid){
            let containerList = openContainersList.split(",");
            containerList = containerList.filter(n => n);
            if(containerList.length === new Set(containerList).size){
                let data = {vendingMachineId: vendingMachineId, containers: containerList, productId: productId};
                if(openContainerChecked){
                    data.containers = allContainers;
                }
                openContainers(data);
            }else{
                alert("Container - Duplicates")
            }
        }else{
            alert("Invalid Input")
        }
    }
    const openContainerToCheck = () => {
        if(!vendingMachineId || !productId){
            alert("select Vending Machine and Product");
            return
        }
        let is_valid =  /^[0-9,.]*$/.test(checkOpenContainersList)
        if(is_valid){
            let containerList = checkOpenContainersList.split(",");
            containerList = containerList.filter(n => n);
            if(containerList.length === new Set(containerList).size){
                let data = {vendingMachineId: vendingMachineId, containers: containerList, productId: productId};
                if(checkOpenContainerChecked){
                    data.containers = allContainers;
                }
                // data.allContainers = checkOpenContainerChecked ? true: false;
                checkOpen(data);
            }else{
                alert("Container - Duplicates")
            }
        }else{
            alert("Invalid Input")
        }
    }

    const lockStatusToCheck = () => {
        if(!vendingMachineId || !productId){
            alert("select Vending Machine and Product");
            return
        }
        let is_valid =  /^[0-9,.]*$/.test(checkLockStatusContainersList)
        if(is_valid){
            let containerList = checkLockStatusContainersList.split(",");
            containerList = containerList.filter(n => n);
            if(containerList.length === new Set(containerList).size){
                let data = {vendingMachineId: vendingMachineId, containers: containerList};
                if(checkLockStatusChecked){
                    data.containers = allContainers;
                }
                checkLockStatus(data);
            }else{
                alert("Container - Duplicates")
            }
        }else{
            alert("Invalid Input")
        }
    }

    const lockStatusFunction = () =>{
        if(!vendingMachineId || !productId){
            alert("select Vending Machine and Product");
            return
        }
        let is_valid =  /^[0-9,.]*$/.test(lockStatusContainersList)
        if(is_valid){
            let containerList = lockStatusContainersList.split(",");
            containerList = containerList.filter(n => n);
            if(containerList.length === new Set(containerList).size){
                let data = {vendingMachineId: vendingMachineId, containers: containerList}
                if(lockStatusChecked){
                    data.containers = allContainers;
                }
                lockStatus(data);
            }else{
                alert("Container - Duplicates")
            }
        }else{
            alert("Invalid Input")
        }
    }

    const stockUpdateFunction = () => {
        if(!vendingMachineId || !productId){
            alert("select Vending Machine and Product");
            return
        }
        let is_valid =  /^[0-9,.]*$/.test(stockUpdateContainersList)
        if(is_valid){
            let containerList = stockUpdateContainersList.split(",");
            containerList = containerList.filter(n => n);
            if(containerList.length === new Set(containerList).size){
                let data = {vendingMachineId: vendingMachineId, containers: containerList}
                if(lockStatusChecked){
                    data.containers = allContainers;
                }
                stockUpdate(data);
            }else{
                alert("Container - Duplicates")
            }
        }else{
            alert("Invalid Input")
        }
    }

    const lockCloseVerifyForStockUpdate = () => {
        if(!vendingMachineId || !productId || !sessionId){
            alert("select Vending Machine and Product / Session Not found");
            return
        }
        let is_valid =  /^[0-9,.]*$/.test(stockUpdateContainersList)
        if(is_valid){
            let containerList = stockUpdateContainersList.split(",");
            containerList = containerList.filter(n => n);
            if(containerList.length === new Set(containerList).size){
                let data = {vendingMachineId: vendingMachineId, containers: containerList}
                if(lockStatusChecked){
                    data.containers = allContainers;
                }
                data.id = sessionId
                lockCloseVerify(data);
            }else{
                alert("Container - Duplicates")
            }
        }else{
            alert("Invalid Input")
        }
    }

    const showVendingMachine = () => {
        if(vendingMachines === null){
            return
        }
        return vendingMachines.map((vendingMachine, index) => {
            return (
                <option key={`vendingMachine-${index}`} value={vendingMachine.vendingMachineId}>{vendingMachine.name}</option>
            )
        });
    }

    const showProducts = () => {
        if(products === null){
            return
        }
        return products.map((product, index) => {
            return (
                <option key={`product-${index}`} value={product.product}>{product.brand}</option>
            )
        });
    }

    const sessionDataLoad = () => {
        if(sessionResults === null){
            return
        }
        return sessionResults.map((result, index) => {
            return (
                <tr key={index}>
                    <td >{result.transactionId}</td>
                    <td >{result.containerId}</td>
                    <td >{result.vendingMachineId}</td>
                    <td >{result.isOpened == true ? "True": "False"}</td>
                    <td >{result.isClosed == true ? "True": "False"}</td>
                    <td >{result.requestType}</td>
                    <td>{result.datetime}</td>
                </tr>
            )
        });
    }

    const sendFeedback = () => {
        if(!open && !close){
            alert("Select Lock Status")
            return
        }
        if(!operationTypeOpen && !operationTypeStockUpdate){
            alert("Select Operation Type")
            return
        }
        const operationType = operationTypeOpen ? "lockOpen" : "stock update"
        const lock = open ? "open" : "close"
        if(!vendingMachineId || !sessionId){
            alert("select Vending Machine / Session Not found");
            return
        }
        let is_valid =  /^[0-9,.]*$/.test(failedContainerList)
        if(is_valid){
            let containerList = failedContainerList.split(",");
            containerList = containerList.filter(n => n);
            if(containerList.length === new Set(containerList).size){
                let data = {vendingMachineId: vendingMachineId, containers: containerList}
                if(lockStatusChecked){
                    data.containers = allContainers;
                }
                data.id = sessionId
                data.operationType = operationType;
                data.lockFailedTo = lock;
            }else{
                alert("Container - Duplicates")
            }
        }else{
            alert("Invalid Input")
        }
    }

    const {
        vendingMachine,
        vendingMachineId,
        productId,
        checkOpenContainersList,
        checkLockStatusContainersList,
        lockStatusContainersList,
        openContainersList,
        stockUpdateContainersList,
        failedContainerList,
        checkLockStatusChecked,
        checkOpenContainerChecked,
        lockStatusChecked,
        stockUpdateChecked,
        openContainerChecked
    } = testData;

    return (
        <Fragment>
        <div className="container-fluid">
        <Container>
            <Row>
                <Col>
                    <div>
                        <h4>Vending Machine</h4>
                        <select
                            id ='vendingMachines'
                            className="form-control"
                            // value = {vendingMachine}
                            name ='vendingMachines'
                            onChange={e => onChange(e)}>
                            <option value="">Select Vending Machine</option>
                            { showVendingMachine() }
                        </select>
                    </div>
                </Col>
                <Col> 
                    <div>
                        <h4>Product</h4>
                        <select
                            id ='products'
                            className="form-control"
                            // value = {vendingMachine}
                            name ='products'
                            onChange={e => onChange(e)}>
                            <option value="">Select Product</option>
                            { showProducts() }
                        </select>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className='row' style={{marginTop: "4%"}}>
                    <h4 style={{maxWidth: "fit-content"}}>Session ID : </h4> <h4 style={{maxWidth: "fit-content"}}>{sessionId}</h4>
                </div>
            </Row>
            <Row>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Open Container</h4>
                        <label style={{maxWidth: "fit-content"}} htmlFor="openContaierAll">Open All Container </label>
                        <input style={{width: "90px", marginTop: "0.5%"}} id="openContaierAll" name="openContaierAll"
                            type="checkbox" checked={openContainerChecked? "checked" : ""} onChange={e => onChange(e) }/>
                        <label htmlFor="openContaier">Open Container </label>
                        <textarea id="openContaier" name="openContaier" rows="1" onChange={e => onChange(e) }/>
                        <Button className={"btn btn-primary"}
                            style={{"maxWidth": "fit-content", "marginTop": "2%"}}
                            onClick={e => openContainerFunction(e) }>
                            Open
                        </Button>
                    </div>
                </Col>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Lock Status</h4>
                        <label style={{maxWidth: "fit-content"}} htmlFor="lockStatusAll">All Locks? </label>
                        <input style={{width: "90px", marginTop: "0.5%"}} id="lockStatusAll" name="lockStatusAll"
                            type="checkbox" checked={lockStatusChecked? "checked" : ""} onChange={e => onChange(e) }></input>
                        <label htmlFor="lockStatus">Lock Status Of </label>
                        <textarea id="lockStatus" name="lockStatus" rows="1" onChange={e => onChange(e)}></textarea>
                        <Button className={"btn btn-primary"}
                            style={{"maxWidth": "fit-content", "marginTop": "2%"}}
                            onClick={e => lockStatusFunction(e) }>
                            Check
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Check Open Container</h4>
                        <label style={{maxWidth: "fit-content"}} htmlFor="checkOpenContaierAll">Open All Container </label>
                        <input style={{width: "90px", marginTop: "0.5%"}} id="checkOpenContaierAll"
                            name="checkOpenContaierAll" type="checkbox" checked={checkOpenContainerChecked? "checked" : ""} onChange={e => onChange(e) }></input>
                        <label htmlFor="checkOpenContaier">Open Container </label>
                        <textarea id="checkOpenContaier" name="checkOpenContaier" rows="1" cols="25" onChange={e => onChange(e) }></textarea>
                        <Button className={"btn btn-primary"}
                            style={{"maxWidth": "fit-content", "marginTop": "2%"}}
                            onClick={e => openContainerToCheck(e) }>
                            Open
                        </Button>
                    </div>
                </Col>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Check Lock Status</h4>
                        <label style={{maxWidth: "fit-content"}} htmlFor="checkLockStatusAll">All Locks? </label>
                        <input style={{width: "90px", marginTop: "0.5%"}} id="checkLockStatusAll"
                            name="checkLockStatusAll" type="checkbox" checked={checkLockStatusChecked? "checked" : ""} onChange={e => onChange(e) }></input>
                        <label htmlFor="checkLockStatus">Lock Status Of </label>
                        <textarea id="checkLockStatus" name="checkLockStatus" rows="1" cols="25" onChange={e => onChange(e)}></textarea>
                        <Button className={"btn btn-primary"}
                            style={{"maxWidth": "fit-content", "marginTop": "2%"}}
                            onClick={e => lockStatusToCheck(e) }>
                            Check
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className='row' style={{marginTop: "10%"}}>
                    <h4>Stock Update</h4>
                    <label style={{maxWidth: "fit-content"}} htmlFor="stockUpdateOpenContaierAll">Open All Container </label>
                    <input style={{width: "90px", marginTop: "0.5%"}} id="stockUpdateOpenContaierAll" name="stockUpdateOpenContaierAll"
                        type="checkbox" checked={stockUpdateChecked? "checked" : ""} onChange={e => onChange(e) }></input>
                    <label htmlFor="stockUpdateOpenContaier">Open Container </label>
                    <textarea id="stockUpdateOpenContaier" name="stockUpdateOpenContaier" rows="2" cols="25" onChange={e => onChange(e)}></textarea>
                    <Button className={"btn btn-primary"}
                        style={{"maxWidth": "fit-content", "marginTop": "2%"}}
                        onClick={e => stockUpdateFunction(e) }>
                        Open
                    </Button>
                    <Button className={"btn btn-primary"}
                        style={{"maxWidth": "fit-content", "marginTop": "2%", "marginLeft": "2%",}}
                        onClick={e => lockCloseVerifyForStockUpdate(e) }>
                        Verify Lock Close
                    </Button>
                </div>
                
            </Row>
            <Row>
                <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                    <h4>Testing Feedback</h4>
                    <div>
                        <h6>Operation type</h6>
                        <input type="radio" id="operationOpen" name="operation" value="operationOpen" onChange={e => onChange(e)}/>
                        <label style={{marginLeft:"1%"}} htmlFor="operationOpen">Open Container</label>
                        <input style={{marginLeft:"2%"}} type="radio" id="operationStockUpdate" name="operation" value="operationStockUpdate" onChange={e => onChange(e)}/>
                        <label style={{marginLeft:"1%"}} htmlFor="operationStockUpdate">Stock Update</label>
                    </div>
                    {/* <form id="feedbackForm"> */}

                    <div style={{marginTop:"1%"}}>
                        <h6>Lock Status</h6>
                        <input type="radio" id="open" name="lockStatusFailed" value="openContainer" onChange={e => onChange(e)}/>
                        <label style={{marginLeft:"1%"}} htmlFor="openContainer">Failed To Open</label>
                        <input style={{marginLeft:"2%"}} type="radio" id="close" value="closeContainer" name="lockStatusFailed" onChange={e => onChange(e)}/>
                        <label style={{marginLeft:"1%"}} htmlFor="closeContainer">Failed To Close</label><br></br>
                    </div>
                    {/* </form> */}
                    <textarea id="feedback" name="feedback" rows="1" cols="25" onChange={e => onChange(e)}></textarea>
                    <Button className={"btn btn-primary"}
                        style={{"maxWidth": "fit-content", "marginTop": "2%"}}
                        onClick={e => sendFeedback(e) }>
                        Send Feedback
                    </Button>
                </div>
            </Row>
            <Row>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Ping</h4>
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-primary"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => sendPingRequest(e) }>
                            Ping Vending Machine
                        </Button>
                    </div>
                </Col>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Restart</h4>
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-danger"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => restartVendingMachineRequest(e) }>
                            Restart Vending Machine
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Ping Data</h4>
                        <label style={{maxWidth: "fit-content"}} htmlFor="pingStartDate">Start date:</label>
                        {/* <input style={{maxWidth: "fit-content", textAlign: "center"}} type="date" id="pingStartDate"
                         name="pingStartDate" value="2023-11-01" min="2023-11-01" max='<%= new Date().toISOString().split("T")[0] %>' onChange={e => onChange(e) }  /> */}
                        <DatePicker selected={pingStartDate} onChange={(pingStartDate) => setPingStartDate(pingStartDate)}/>
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-success"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => downloadPingDataRequest(e) }>
                            Download
                        </Button>
                    </div>
                </Col>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Lock Data</h4>
                        <label style={{maxWidth: "fit-content"}} htmlFor="lockStartDate">Start date:</label>
                        {/* <input style={{maxWidth: "fit-content", textAlign: "center"}} type="date" id="pingStartDate"
                         name="pingStartDate" value="2023-11-01" min="2023-11-01" max='<%= new Date().toISOString().split("T")[0] %>' onChange={e => onChange(e) }  /> */}
                        <DatePicker selected={lockStartDate} onChange={(lockStartDate) => setLockStartDate(lockStartDate)}/>
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-success"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => downloadLockDataRequest(e) }>
                            Download
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='row' style={{marginTop: "10%", width: "inherit"}}>
                        <h4>Session Results</h4>
                        <label style={{maxWidth: "fit-content"}} htmlFor="prevSessionId">Session Id </label>
                        <input style={{width: "90px"}} id="prevSessionId" name="prevSessionId"
                            type="text" value={prevSessionId} onChange={e => onChange(e)} />
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-success"}
                            style={{"float": "center","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => sessionResultsRequest(e) }>
                            Get Results
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <table>
                    <thead>
                        <tr>
                            <th>Transaction Id</th>
                            <th>Container Id</th>
                            <th>Vending Machine Id</th>
                            <th>Opened</th>
                            <th>Closed</th>
                            <th>Request Type</th>
                            <th>Datetime</th>
                        </tr>
                    </thead>
                    <tbody>
                    { sessionDataLoad() }
                    </tbody>
                </table>
            </Row>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </Container>
        </div>
        </Fragment>
    )
}

TestVM.propTypes = {
    sessionId: PropTypes.string,
    pingData : PropTypes.array,
    lockData : PropTypes.array,
    sessionResults: PropTypes.array,
    results: PropTypes.object,
    vendingMachines: PropTypes.array,
    products: PropTypes.array,
    lockStatus: PropTypes.func,
    openContainers: PropTypes.func,
    stockUpdate: PropTypes.func,
    lockCloseVerify: PropTypes.func,
    ping: PropTypes.func,
    checkOpen: PropTypes.func,
    checkLockStatus: PropTypes.func,
    restartVendingMachine: PropTypes.func,
    getAllRegisteredVendingMachines: PropTypes.func,
    getAllRegisteredVendingMachinesWithProducts: PropTypes.func,
    getVendingMachinesPingReport: PropTypes.func,
    getSessionResults: PropTypes.func,
    getVendingMachinesLockReport: PropTypes.func,
}

const mapStateToProps = state => ({
    results: state.test.results,
    sessionId: state.test.sessionId,
    pingData: state.test.pingData,
    lockData: state.test.lockData,
    sessionResults: state.test.sessionResults
});
export default connect(mapStateToProps, { openContainers, stockUpdate, lockStatus, lockCloseVerify,
    ping, checkOpen, checkLockStatus, restartVendingMachine, getAllRegisteredVendingMachines, getAllRegisteredVendingMachinesWithProducts,
    getVendingMachinesPingReport, getSessionResults, getVendingMachinesLockReport} )(TestVM);
