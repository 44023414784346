import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrders, getTransactions } from '../../actions/downloads';

import { Button } from 'react-bootstrap';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Downloads = ({orders, getOrders, transactions, getTransactions}) => {

    const initialState = {
        vendingMachine: '',
        startDate:'',
        endDate:'',
        reportType:''
    };

    const [formData, setFormData] = useState(initialState);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [fileName, setFileName] = useState("");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    useEffect(() => {
        const exportToCSV = (apiData) => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        };
        if(orders.length > 0){
            exportToCSV(orders);
        }
        if(transactions.length > 0){
            exportToCSV(transactions);
        }
    }, [ orders, transactions ]);

    const handleOpen = (title, message) => {
        setDialogTitle(title);
        setDialogMessage(message);
        setOpen(true);
    };

    const handleOk = () => {
        setOpen(false);        
    };

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const {
        vendingMachine,
        startDate,
        endDate,
        reportType
    } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {
        e.preventDefault();
        const startDate = new Date(formData.startDate).getTime();
        const endDate = new Date(formData.endDate).getTime();
        if(endDate < startDate){
            handleOpen("Date", "Invalid Date Format")
        }else{
            let fname = [formatDate(new Date(formData.startDate)),
                "To",
                formatDate(new Date(formData.endDate)),
                "Downloaded On",
                formatDate(new Date())
            ].join('-');
            setFileName(fname);
            if(formData.reportType === "transaction"){
                getTransactions(formData.vendingMachine, formData.startDate, formData.endDate);
            }
            if(formData.reportType === "order"){
                getOrders(formData.vendingMachine, formData.startDate, formData.endDate);
            }
        }
    };

    const formatDate = (date) => {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    return (        
        <Fragment>
            <div className='d-flex flex-column justify-content-center'>
                <div className='text-primary d-flex flex-row'>
                </div> 
                <div className='text-primary d-flex flex-row' style={{marginTop: "5%"}}>
                    <h3 className='mx-auto'>Download Purchase Data</h3>
                </div>
                <div style={{margin:"2%"}}>
                    <h6 className="text-center">To download report across all vending machine. Set Vending Machine field as all</h6>
                </div>
                   
                <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "500px"}}>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle} className='mt-2 mr-2'>Vending Machine</label>
                        <input
                            id='vendingMachine'
                            className="form-control form-control-user" 
                            type='text'
                            placeholder='Vending Machine ID'
                            name='vendingMachine'
                            value={vendingMachine}
                            onChange={e => onChange(e) }
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Start Date</label>
                        <input
                            id='startDate'
                            className="form-control form-control-user" 
                            type='date'
                            name='startDate'
                            placeholder='Start Date'
                            value={startDate}
                            onChange={e => onChange(e) }
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>End Date</label>
                        <input
                            id='endDate'
                            className="form-control form-control-user" 
                            type='date'
                            placeholder='End Date'
                            name='endDate'
                            value={endDate}
                            onChange={e => onChange(e) }
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Report Type</label>
                        <select
                            id ='reportType'
                            className="form-control form-control-user" 
                            type ='text'
                            value = {reportType}
                            name ='reportType'
                            required
                            onChange={e => onChange(e) }>
                                <option value="">Select Report Type</option>
                                <option value="order">Order</option>
                                <option value="transaction">Transaction</option>
                        </select>
                    </div>            

                    <input type='submit' className='btn btn-primary mx-auto mt-2' value="Generate" />
                </form>                
            </div>
            <Dialog open={open}>
                <DialogTitle>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOk} color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

Downloads.PpropTypes = {
    getOrders : PropTypes.func.isRequired,
    orders : PropTypes.array,
    getTransactions : PropTypes.isRequired,
    transactions : PropTypes.array
} 
const mapStateToProps = state => ({
    orders: state.downloads.orders,
    transactions: state.downloads.transactions,
    loading: state.downloads.loading
})

export default connect( mapStateToProps, { getOrders, getTransactions } )( Downloads );