import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken'
import {
    GET_TRANSACTION_LEDGER
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;


// Get Transaction Ledger
export const getTransactionLedger = (filters) => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/ledger/all?${filters}`);
        if(res.data.success){
            dispatch({
                type: GET_TRANSACTION_LEDGER,
                payload: res.data
            });
            dispatch( setAlert('Successfully fetched transactions', 'success') );
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth Orders' ) );
    }
}