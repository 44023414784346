import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInventoryStatus } from '../../actions/inventory';

import { Table, Button} from "react-bootstrap";

import * as XLSX from "xlsx";

const Status = ({
    loading,
    stockData,
    uniqueBrands,
    getInventoryStatus
}) => {

    const [stockType, setStockType] = useState(["Alloted", "Empty", "In-stock", "Disabled"]);
    const [stockDataMaped, setStockDataMaped] = useState([]);

    useEffect(() => {
        if(stockData.length == 0){
            getInventoryStatus();
        }else{
            let data = [];
            let totalData = {
                _id: "",
                vendingMachineId: "",
                vendingMachineName: "Total",
                totalContainers: "",
                totalAssignedContainers: "",
                brands:{}
            };
            stockData.forEach(stock => {
                const brandsFound = Object.keys(stock.brands).sort();                
                let brands = {};
                uniqueBrands.forEach(brand => {
                    if(totalData.brands[brand] === undefined){
                        totalData.brands[brand] = {};
                        totalData.brands[brand]["assignedContainers"] = 0;
                        totalData.brands[brand]["empty"] = 0;
                        totalData.brands[brand]["inStock"] = 0;
                        totalData.brands[brand]["disabled"] = 0;
                    }
                    if(brandsFound.includes(brand)){
                        brands[brand] = stock.brands[brand];
                        totalData.brands[brand]["assignedContainers"] += stock.brands[brand].assignedContainers;
                        totalData.brands[brand]["empty"] += stock.brands[brand].empty;
                        totalData.brands[brand]["inStock"] += stock.brands[brand].inStock;
                        totalData.brands[brand]["disabled"] += stock.brands[brand].disabled;
                    }else{
                        brands[brand] = { assignedContainers: 0, empty: 0, inStock: 0, disabled: 0 }
                    }
                });
                data.push(
                    {
                        _id: stock._id,
                        vendingMachineId: stock.vendingMachineId,
                        vendingMachineName: stock.vendingMachineName,
                        totalContainers: stock.totalContainers,
                        totalAssignedContainers: stock.totalAssignedContainers,
                        brands:brands
                    }
                )                
            });
            data.push({_id:"", vendingMachineId:"", vendingMachineName: "", totalContainers:"",
                totalAssignedContainers:"", brands:{}})
            data.push(totalData);
            setStockDataMaped(data);

        }
    }, [ stockData ]);

    const exportToExcel = ()  => {
        let fileName = "Inventory Data_" + new Date().toJSON().slice(0,10).replace(/-/g,'_');
        var elt = document.getElementById('inventoryTable');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
        return XLSX.writeFile(wb, fileName  + '.xlsx');
    }

    return (
        <Fragment>
            <div className='text-primary d-flex flex-row'>
            </div> 
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'>Inventory</h3>
            </div>
            <div id='containers' className='d-flex  mt-5 flex-wrap'>                
                <Table id="inventoryTable" striped bordered hover style={{ display: "block", overflow: "auto", whiteSpace: "nowrap"}}>
                    <thead style={{position: "sticky", top: 0, background: "white"}}>
                        <tr>
                            <th rowSpan="2" scope="rowgroup" style={{textAlign:"center", verticalAlign: "middle"}}>VM</th>
                            <th rowSpan="2" scope="rowgroup" style={{textAlign:"center", verticalAlign: "middle"}}>VM ID</th>
                            <th rowSpan="2" scope="rowgroup" style={{textAlign:"center", verticalAlign: "middle"}}>Total Con</th>
                            <th rowSpan="2" scope="rowgroup" style={{textAlign:"center", verticalAlign: "middle"}}>Total Allot</th>
                            {
                                uniqueBrands.map((key, index) => {
                                    return <th key={index} colSpan="4" scope="colgroup" style={{textAlign:"center"}}>{key}</th>
                                })
                            }                            
                        </tr>
                        <tr>
                            {
                                uniqueBrands.map((brand, index) => {
                                    return stockType.map((key, index) => {
                                        return <th key={index} scope="col">{key}</th>
                                    });                                    
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>                        
                        {
                            stockDataMaped.map((vendingMachineData, index) => {
                                return <tr key={index}>
                                    <td key="vendingMachineName">{vendingMachineData.vendingMachineName}</td>
                                    <td key="vendingMachineId">{vendingMachineData.vendingMachineId}</td>
                                    <td key="totalContainers">{vendingMachineData.totalContainers}</td>
                                    <td key="totalAssignedContainers">{vendingMachineData.totalAssignedContainers}</td>                                    
                                    {
                                        Object.keys(vendingMachineData.brands).map((brand, index) => {
                                            return Object.keys(vendingMachineData.brands[brand]).map((attribute, i) => {
                                                return <td key={i}>{ vendingMachineData.brands[brand][attribute] }</td>
                                            })
                                        })
                                    }
                                </tr>
                            })
                        }
                    </tbody>                    
                </Table>
            </div>
            <div className="text-center"> <Button onClick={exportToExcel} variant="success"> Download </Button> </div>
        </Fragment>
    );
}

Status.propTypes = {
    getInventoryStatus: PropTypes.func,
    stockData: PropTypes.array,
    uniqueBrands: PropTypes.array
}

const mapStateToProps = state => ({
    stockData: state.inventory.stockData,
    uniqueBrands: state.inventory.uniqueBrands,
    loading: state.inventory.loading,
});

export default connect(mapStateToProps, { getInventoryStatus } )(Status);