import React , { useEffect, Fragment }from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import VendingMachine from '../vendingMachine/VendingMachine';
import { getVendingMachines } from '../../actions/vendingMachine';
import { getProducts } from '../../actions/product';

const Dashboard = ({ getVendingMachines, vendingMachines, getProducts }) => {

    useEffect( () => {
        getVendingMachines();
        // getProducts();
    }, [ ]);
    
    const getAllVendingMeachines = () => {
        if(vendingMachines && vendingMachines.length>0){
            return vendingMachines.map((vendingMachine, index) => {                 
                return (
                    <VendingMachine key={index} vendingMachine={vendingMachine} />
                );
            });
        }
    }

    return (
        <Fragment>
        <div className="container-fluid">
            <div className='text-primary d-flex flex-row'>
                <Link
                    to="/inventory/v1.2/AddVendingMachine"
                    style={{'width':'200px'}}
                    className="btn btn-sm btn-primary ">
                        Add Vending Machine
                </Link>
                <Link
                    to="/inventory/v1.2/list-registered-vending-machine"
                    style={{width:'200px', marginLeft:"auto", marginRight:"1%"}}
                    className="btn btn-sm btn-primary ">
                        Vending Machine List
                </Link>
                <Link
                    to="/inventory/v1.2/register-vending-machine"
                    style={{'width':'200px'}}
                    className="btn btn-sm btn-primary">
                        Register Vending Machine
                </Link>
            </div>
            <div className='d-flex  mt-5 flex-wrap'>
                { vendingMachines? getAllVendingMeachines():'' }
            </div>
        </div>
        </Fragment>
    )
}

Dashboard.propTypes = {
    vendingMachines: PropTypes.array,
    getVendingMachines: PropTypes.func.isRequired,
    getProducts: PropTypes.func
}

const mapStateToProps = state => ({
    vendingMachines: state.vendingMachine.vendingMachines
});
export default connect(mapStateToProps, { getVendingMachines, getProducts } )(Dashboard);

