import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect  } from 'react-redux';
import { updateContainer, openContainer ,
     deleteContainer, addToStock, removeFromStock, removeProduct } from '../../actions/inventory';
import { Button } from 'react-bootstrap';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';

const Container = (props) =>{
    const [container, setContainer] = useState({});
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");
    if( JSON.stringify(props.container) !== JSON.stringify(container)){
        setContainer({...props.container});
    }

    const handleOpen = (title, message, type) => {
        setDialogType(type);
        setDialogTitle(title);
        setDialogMessage(message);
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        if(dialogType === "open"){
            setOpen(false);
            openContainer();
        }
        if(dialogType === "delete"){
            setOpen(false);
            deleteContainer(container._id);
        }
    };

    const populateProducts = () => {
        if(props.brands){
            return Object.keys(props.brands).map((key, index) => {
                return ( <option key={`product-${index}`} value={key}>{props.brands[key]}</option> )
            });
        }
    }

    const onChangeContainerID = e => {
        setContainer({ ...container, [e.target.name]: e.target.value });
        let payload = {
            vendingMachine:  props.vendingMachine._id,
            container: props.container._id,
            data: {
                container_id : e.target.value ,
            }
        }
        props.updateContainer(payload);
    }

    const onProductSelect=(e)=>{
        setContainer({ ...container, [e.target.name]: e.target.value });
        let prevProduct = container.product;
        let payload = {
            vendingMachine:  props.vendingMachine._id,
            container: props.container._id,
            data: {
                product : e.target.value,
                _id : container._id,
                prevProduct : prevProduct
            }
        }
        props.updateContainer(payload);
    }

    const updateContainerWorkingStatus = (e) => {
        setContainer({ ...container, [e.target.name]: e.target.value });
        let payload = {
            container: props.container._id,
            data: {
                'isWorking' : e.target.value
            }
        }
        props.updateContainer(payload);
    }

    const updateContainerReservedStatus = (e) => {
        setContainer({ ...container, [e.target.name]: (e.target.value === 'true') });
        let payload = {
            container: props.container._id,
            data: {
                'isReserved' : e.target.value
            }
        }
        props.updateContainer(payload);
    }

    const openContainer = () => {
        // let payload = {
        //     vendingMachineId: props.vendingMachine.vendingMachineId,
        //     vendingMachine: props.vendingMachine._id,
        //     containers: {}
        // }
        // payload["containers"][props.container._id] = props.container.containerId;
        let payload = {vendingMachineId: props.vendingMachine.vendingMachineId, containers: [props.container.containerId]};
        props.openContainer( payload )
    }

    const deleteContainer = (containerId) => {
        var payload = {
            vendingMachine:  props.vendingMachine._id, container : containerId,
        }
        props.deleteContainer( payload )
    }

    const addStock = (e) => {
        let payload = { id: container._id, vendingMachine: container.vendingMachine }
        props.addToStock(payload);
    }

    const removeStock = (e) => {
        let payload = { id: container._id, vendingMachine: container.vendingMachine }
        props.removeFromStock(payload);
    }

    const removeProduct = (e) => {
        let payload = { _id: container._id}
        props.removeProduct(payload, container.vendingMachine);
    }

    return(
        <div className="col-xl-3 col-md-6 mb-4" style={{'cursor':'pointer'}}>
            <div className="card border-left-primary shadow h-100 py-2">
                <a  style={{ 'width': '60px' }}className='btn btn-sm btn-light text-primary d-flex ml-auto mr-2'
                 onClick={ e => handleOpen("Delete", "Deleting contianer " + container.containerId, "delete")}>
                    Delete
                </a>
            <div className="card-body">
                <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Container ID:</label>
                        <input
                            style={{ 'width' : '50px' }}
                            id={'container_id' + container.containerId}
                            className="form-control form-control-user ml-auto" 
                            type='text'
                            placeholder='Container ID'
                            name='container_id'
                            value={container.containerId}
                            onChange={e => onChangeContainerID(e) }
                        />
                    </div>
                    <div className="h6  font-weight-light text-gray-800 mt-1 d-flex align-items-center">
                        Door : { container.isLocked?
                            <span className='text-success ml-2'>Locked</span> : <span className='text-danger'>UnLocked</span>
                        }
                    </div>
                    <div className="h6  font-weight-light text-gray-800 mt-1 d-flex align-items-center">
                        
                        <span>Status :</span>
                        <select 
                            style={{ "width" : "120px" }}
                            className="form-control form-control-user ml-auto" 
                            value={container.isWorking}
                            name='isWorking'
                            disabled={container.isReserved ? "disabled":""}
                            onChange={e => updateContainerWorkingStatus(e) }>
                                <option value="true">Working</option>
                                <option value="false">Dissable</option>
                        </select>
                    </div>
                    <div className="h6 font-weight-light text-gray-800 mt-1">
                        { !container.product?
                            <span>Product : <span className='text-danger'>Not Assigned</span> </span>
                            : 
                            <span>Product : <span className='text-success'>{ props.brands[container.product]}</span> </span>
                        }
                        <Button className={"btn btn-primary shadow-sm ml-auto btn-danger"}
                            style={{"float": "right","marginTop": "2%", "marginBottom": "3%"}}
                            onClick={e => removeProduct(e) }
                            disabled={!container.isEmpty || !container.isWorking? "disabled":""} >
                            Remove
                        </Button>
                        <select style={{ marginTop: '15px' }}
                            className="form-control" 
                            value={container.product?container.product:""}
                            name='product'
                            disabled={ !container.isEmpty || !container.isWorking ? "disabled":""}
                            onChange={e => onProductSelect(e) }>
                                <option value="">Select Product</option>
                                {   populateProducts() }
                        </select>
                        
                    </div>

                    <div className="h6  font-weight-light text-gray-800 mt-1 d-flex align-items-center">
                        <span>Reserved : { container.isReserved?
                            <span className='text-danger ml-2'>Yes</span> : <span className='text-success'>No</span>}
                        </span>
                        <select
                            disabled = { container.isEmpty || !container.isWorking ? "disabled" : null  }
                            style={{ "width" : "120px"}}
                            className="form-control form-control-user ml-auto"
                            value={container.isReserved}
                            name='isReserved'
                            onChange={e => updateContainerReservedStatus(e) }>
                                <option value="true">Reserved</option>
                                <option value="false">Available</option>
                        </select>
                    </div>

                    <div className="h6  font-weight-light text-gray-800 mt-1 d-flex align-items-center" style={{paddingTop: "5%"}}>
                        <span>Stock :</span>
                        {container.product?
                            <Button className={"btn btn-primary shadow-sm ml-auto " + (container.isEmpty ? "btn-danger":"btn-success")} style={{"float": "right","marginTop": "2%"}}
                                onClick={e => container.isEmpty? addStock(e) : removeStock(e) }
                                disabled={container.isReserved || !container.isWorking ? "disabled":""} >
                                { container.isEmpty ? "ADD TO STOCK":"IN STOCK" }
                            </Button> : null
                        }
                    </div>
                    <div className="h6  font-weight-light text-gray-800 mt-1 d-flex align-items-center" style={{paddingTop: "5%"}}>
                        <a className="btn btn-primary shadow-sm ml-auto" style={{'cursor':'pointer'}}
                            onClick={e => handleOpen("Open", "Open container " + container.containerId, "open") }>
                            { container.isLocked?
                                <span className='text-white'>Open</span> : <span className='text-white'>Close</span>
                            }
                        </a>
                    </div>
                </div>
                <div className="col-auto">
                    <i className="fas fa-machine fa-2x text-gray-300"></i>
                </div>
                </div>
            </div>
            </div>
            <Dialog open={open}>
                <DialogTitle>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOk} color="primary">Ok</Button>
                    <Button onClick={handleCancel} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

Container.propTypes = {
    vendingMachine: PropTypes.object,
    updateContainer: PropTypes.func,
    openContainer: PropTypes.func,
    deleteContainer: PropTypes.func.isRequired,
    addToStock: PropTypes.func.isRequired,
    removeFromStock: PropTypes.func.isRequired,
    removeProduct: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    vendingMachine: state.vendingMachine.vendingMachine
});
export default connect(mapStateToProps,
     { updateContainer, openContainer, deleteContainer, addToStock,
        removeFromStock, removeProduct } )(Container);
