import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addProduct, updateProduct, getProduct, uploadProductImage, getregions } from '../../actions/product';

const initialState = {
    brand: '',
    tagline:'',
    description:'',
    image: '',
    productType:''
};

const Product = ({
    match,
    addProduct,
    getProduct,
    getregions,
    updateProduct,
    loading,
    product,
    uploadProductImage
    
}) => {
    const [formData, setFormData] = useState(initialState);
    useEffect(() => {
        if(match.params.id !== 'new'){
            if (!product || (product._id !== match.params.id)){
                    getProduct(match.params.id);
            } 
            const productData = { ...initialState };
            if (product) {
                for (const key in product) {
                    if (key in productData) productData[key] = product[key];
                }
                setFormData(productData);                
            }
        }
    }, [product]);
    
    
    const {
        brand,
        tagline,
        description,
        productType,
        image,
    } = formData;
    
    const isNewProduct =  (match.params.id==='new')? true : false ;
    const onChange = (e) => {
        if(e.target.name === 'image'){
            var payload={
                'product_id':match.params.id,
                'file':e.target.files[0]
            };
            uploadProductImage(payload);
            return;
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });

    }

    const onSubmit = e => {
        e.preventDefault();
        if(isNewProduct){
            if(!formData.productType){
                alert("Select product");
                return;
            }
            addProduct(formData );
        }else{
            updateProduct( product._id, formData );
        }
    };

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    return (
        <Fragment>
            <div className='d-flex flex-column justify-content-center'>
                <div className='text-primary d-flex flex-row'>
                    <Link className='btn btn-sm btn-light text-primary ' to='/inventory/v1.2/products'>
                        Back
                    </Link>
                </div> 
                <div className='text-primary d-flex flex-row'>
                    <h3 className='mx-auto'>Product</h3>
                </div>
                   
                <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "500px"}}>
                    <div className='form-group d-flex mr-2'>
                        <label style={customLableStyle} className='mt-2 mr-2'>Brand</label>
                        <input
                            id='brand'
                            className="form-control form-control-user" 
                            type='text'
                            // placeholder='Stock'
                            name='brand'
                            value={brand}
                            onChange={e => onChange(e) }
                            required
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Tagline</label>
                        <input
                            id='tagline'
                            className="form-control form-control-user" 
                            type='text'
                            name='tagline'
                            value={tagline}
                            onChange={e => onChange(e) }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Description</label>
                        <textarea
                            id='description'
                            className="form-control form-control-user" 
                            type='text'
                            placeholder='description'
                            name='description'
                            value={description}
                            onChange={e => onChange(e) }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Product Type</label>
                        <select
                            id ='productType'
                            className="form-control form-control-user" 
                            type ='text'
                            value = {productType}
                            name ='productType'
                            onChange={e => onChange(e) }>
                                <option value="">Select Product Type</option>
                                <option value="product">Product</option>
                                <option value="deposit">Deposit</option>
                        </select>
                    </div>
                    
                    { !isNewProduct ?
                        <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'> Product Image</label>
                        <input className="form-control form-control-user"
                            id='image'
                            type='file'
                            name='image'
                            style={{'cursor': 'pointer'}} 
                            onChange={e => onChange(e)}
                        />
                        </div>:null
                    }
                    { !isNewProduct && image ?
                        <div className='form-group d-flex mr-2'>
                            <div className='mx-auto' style={{'height': '150px', 'width': '150px' }} >
                                <img
                                    id='image-view' alt="Product Image"
                                    name='image-view'
                                    src={`${ process.env.REACT_APP_SERVER_PUBLIC_PATH }${ process.env.REACT_APP_IMAGE_PATH_PRODUCT  }/${image}`} 
                                    style={{'height': '100%', 'width': '100%'}}
                                />
                            </div>
                        </div>:null
                    }

                    <input type='submit' className='btn btn-primary mx-auto mt-2' value={isNewProduct?'Add':'Update'} />
                </form>
            </div>
        </Fragment>
    );
}
Product.propTypes = {
    getProduct: PropTypes.func.isRequired,
    addProduct: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    uploadProductImage: PropTypes.func.isRequired,
    product: PropTypes.object,
    getregions:PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    loading: state.product.loading,
    product: state.product.product
});

export default connect(
    mapStateToProps, 
    { addProduct, getProduct, updateProduct, uploadProductImage,getregions }
)(Product);
