import axios from 'axios';
import { setAlert } from './alert';
import {
    GET_ACCOUNT_DELETION_REQUEST
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get all Orders

export const getAccountDeleteRequests = (filters) => async dispatch => {

    try {
        const res = await axios.get(`${API_URL}/account-deletion/all?${filters}`);
        if(res.data.success){
            dispatch({
                type: GET_ACCOUNT_DELETION_REQUEST,
                payload: res.data
            });
            dispatch( setAlert('Successfully fetched sales data', 'success') );
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth sales data' ) );
    }
}