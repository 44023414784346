import {
    GET_REGIONS,
    GET_REGION,
    GET_REGION_REQUEST
} from '../actions/types';


const initialState = {
    regions : [],
    region : {},
    loading : false
}


export default (state = initialState, { type, payload }) => {    
    switch (type) {
        case GET_REGION_REQUEST:
            return { 
                ...state,
                loading: payload
            };

        case GET_REGIONS:
            return {
                ...state,
                regions: payload,
                loading: false
            };

        case GET_REGION:
            return {
                ...state, 
                region: payload,
                loading: false 
            };

        default:
            return state;
    }
};