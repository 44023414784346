import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPenalty, updatePenalty, addPenalty } from '../../actions/penalty';


const initialState = {
    paid: false,
    canceled: false,
    amount: 0,
    quantity: 0,
    user: "",
    message: ""
};

const Penalty = ( props ) => {
    const [ formData, setFormData ] = useState(initialState);
    const { state } = props.location;
    const [ userId, setUserId ] = useState("");
    useEffect(() => {
        if( props.match.params.id !== 'new' ){
            if(!props.penalty ||  props.penalty._id !== props.match.params.id){
                // Get User details of a id
                props.getPenalty( props.match.params.id );
            }
            if(props.penalty){
                setFormData({...props.penalty})
                setUserId(props.penalty.user);
            }
        }else{
            setUserId(state ? state.user : null);
        }
    }, [ props.penalty ]);

    const {
        paid,
        canceled,
        amount,
        quantity,
        user,
        message
    } = formData;

    const isNewPenalty  =  (props.match.params.id==='new')? true : false ;
    
    const onChange = (e, type) =>{
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    
    const onSubmit = e => {
        e.preventDefault();
        if(isNewPenalty){
            let penaltyData = {};
            penaltyData["amount"] = Number(formData.amount);
            penaltyData["message"] = formData.message;
            penaltyData["quantity"] = Number(formData.quantity);
            penaltyData["user"] = userId;
            props.addPenalty( userId, penaltyData );
        }else{
            let penaltyData = {};
            penaltyData["amount"] = Number(formData.amount);
            penaltyData["canceled"] = (formData.canceled === 'true');
            penaltyData["message"] = formData.message;
            penaltyData["paid"] = (formData.paid === 'true');
            penaltyData["quantity"] = Number(formData.quantity);
            props.updatePenalty( props.penalty._id, penaltyData );
        }
    };

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const renderPenalty = () => {
        return (
            <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "400px"}}>                     
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Amount</label>
                        <input
                            className="form-control form-control-user" 
                            type='number'
                            placeholder='Amount'
                            name='amount'
                            value={amount}
                            onChange={e => onChange(e) }
                        />
                    </div>
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Quantity</label>
                        <input
                            className="form-control form-control-user" 
                            type='number'
                            placeholder='Quantity'
                            name='quantity'
                            value={ quantity }
                            onChange={e => onChange(e) }
                        />
                    </div>
                    {  !isNewPenalty ?  <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Paid</label>
                        <select
                            className="form-control form-control-user"
                            value={ paid }
                            name='paid'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div> : null }
                    {  !isNewPenalty ? <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Cancel</label>
                        <select
                            className="form-control form-control-user"
                            value={ canceled }
                            name='canceled'
                            onChange={e => onChange(e) }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                        </select>
                    </div> : null }
                    <div className='form-group d-flex mr-2'>
                        <label style={{'whiteSpace': 'nowrap', ...customLableStyle}} className='mt-2 mr-2'>Message</label>
                        <input
                            className="form-control form-control-user" 
                            type='text'
                            placeholder='Message'
                            name='message'
                            value={ message }
                            onChange={e => onChange(e) }
                        />
                    </div>
                    
                    <input type='submit' className='btn btn-primary mx-auto' value={isNewPenalty?'Add':'Update'} />
                </form>
        );
    }

    return (
        <Fragment>
          <div className='d-flex flex-column justify-content-center'>
                <div className='text-primary d-flex flex-row'>
                    <Link className='btn btn-sm btn-light text-primary ' 
                        to={`/inventory/v1.2/users/${userId}`}>
                        Back
                    </Link>
                </div>
                <div className='text-primary d-flex flex-row'>
                    <h3 className='mx-auto'>Penalty</h3>
                </div>
                { renderPenalty() }
            </div>
        </Fragment>
    );

}

Penalty.propTypes = {
    getPenalty: PropTypes.func.isRequired,
    updatePenalty: PropTypes.func.isRequired,
    addPenalty: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    loading: state.penalty.loading,
    penalty: state.penalty.penalty
});

export default connect( mapStateToProps, { getPenalty, updatePenalty, addPenalty }) ( Penalty );
