import { 
    GET_PRODUCT,
    ADD_PRODUCT,
    GET_ALL_PRODUCTS,
    GET_PRODUCT_REQUEST
} from '../actions/types';

const initialState = {
    products:[],
    product:null,
    loading: true
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case ADD_PRODUCT:
        return { 
            ...state, 
            products: payload,
            loading: false 
        };
    case GET_ALL_PRODUCTS:
        return { 
            ...state, 
            products: payload,
            loading: false 
        };
    case GET_PRODUCT:
        return { 
            ...state, 
            product: payload,
            loading: false 
        };

    case GET_PRODUCT_REQUEST:
        return { 
            ...state, 
            loading: payload 
        };

    default:
        return state
    }
}

