import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addVendingMachine } from '../../actions/vendingMachine';
import { getProfiles } from '../../actions/profile';


const AddVendingMachine = ({ addVendingMachine, profiles, getProfiles }) => {
    useEffect(() => {
        if(profiles.length === 0){
            getProfiles();
        }
    }, [ profiles, getProfiles ])

    const [formData, setFormData] = useState({
        name: '',
        vendingMachineId: '',
        location: '',
        profileName:'',
        profile:''
    });

    const [profileSelected, setProfile] = useState("");

    const onChange = e => {
        if(e.target.name === "profile"){
            setProfile(e.target.value);
            let data = { ...formData };
            data.profileName = e.target[e.target.selectedIndex].text;
            data.profile = e.target.value;
            setFormData(data);
        }else{
            setFormData({ ...formData, [ e.target.name ] : e.target.value });
        }
    }

    const onSubmit =  e => {
        e.preventDefault();
        console.log(formData);
        addVendingMachine(formData);
    }

    const showProfiles = () => {
        return profiles.map((profile, index) => {
            return (
                <option key={`profile-${index}`} value={profile._id}>{profile.name}</option>
            )
        });
    }
    

return (
    <div className='d-flex flex-column justify-content-center'>
        <div className='text-primary mt-5 d-flex flex-row'>
            <Link className='btn btn-light text-primary my-1' to='/'>
                Back
            </Link>
          <h3 className='mx-auto'>New Vending Machine</h3>
        </div> 
        <form className='form mt-3 mx-auto d-flex flex-column' onSubmit={ e => onSubmit(e) } style={{"width": "400px"}}> 
            <div className='form-group'>
                <input
                    className="form-control form-control-user" 
                    type='text'
                    placeholder='Vending Machine Name'
                    name='name'
                    value={formData.name}
                    onChange={e => onChange(e) }
                    required
                />
            </div>

            <div className='form-group'>
                <input
                    className="form-control form-control-user" 
                    type='text'
                    placeholder='Vending Machine ID'
                    name='vendingMachineId'
                    value={formData.vendingMachineId}
                    onChange={e => onChange(e) }
                    required
                />
            </div>
            <div className='form-group'>
                <input
                    className="form-control form-control-user" 
                    type='text'
                    placeholder='Location'
                    name='location'
                    value={formData.location}
                    onChange={e => onChange(e) }
                    required
                />
            </div>
            <div className='form-group'>
                <select
                    className="form-control form-control-user ml-auto"
                    value= { profileSelected }
                    name='profile'
                    required
                    onChange={e => onChange(e) }>
                        <option value="">Select Profile</option>
                        { showProfiles() }
                </select>
            </div>
        <input type='submit' className='btn btn-primary mx-auto' value='Submit' />
      </form>
    </div>
  );
};

AddVendingMachine.propTypes = {
    addVendingMachine: PropTypes.func.isRequired,
    getProfiles:PropTypes.func.isRequired,
    profiles: PropTypes.array
};

const mapStateToProps = state => ({
    profiles: state.profile.profiles
});

export default connect( mapStateToProps, { addVendingMachine, getProfiles } )(AddVendingMachine);
