import { 
    UPDATE_PENALTY_REQUEST,
    ADD_PENALTY_REQUEST,
    GET_PENALTY_REQUEST
} from '../actions/types';

const initialState = {
    penalty: null,
    loading: true,
};

export default ( state = initialState, { type, payload }) => {
    switch (type) {
        
        case GET_PENALTY_REQUEST:
            return {
                ...state,
                penalty: payload,
                loading: false
            };

        case UPDATE_PENALTY_REQUEST:
            return {
                ...state,
                penalty: payload,
                loading: false
            };

        case ADD_PENALTY_REQUEST:
            return {
                ...state,
                penalty: payload,
                loading: false
            };

        default:
            return state;
    }
};