import axios from 'axios';
import { setAlert } from './alert';
import {
    GET_SALES,
    DOWNLOAD_SALES
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get all Orders

export const getSales = (filters) => async dispatch => {

    try {
        const res = await axios.get(`${API_URL}/sales/?${filters}`);
        if(res.data.success){
            dispatch({
                type: GET_SALES,
                payload: res.data
            });
            dispatch( setAlert('Successfully fetched sales data', 'success') );
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth sales data' ) );
    }
}

export const downloadSales = (filters) => async dispatch => {
    const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'arraybuffer'
    };
    try {
        const res = await axios.get(`${API_URL}/sales/download?${filters}`, config);
        if(res.data){
            dispatch({
                type: DOWNLOAD_SALES,
                payload: res.data
            });
            dispatch( setAlert('Successful', 'success') );
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to download sales data' ) );
    }
}