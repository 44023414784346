import { 
    GET_ALL_VENDING_MACHINES,
    GET_VENDING_MACHINE,
    ADD_VENDING_MACHINE,
    UPDATE_VENDING_MACHINE,
    GET_VENDING_MACHINE_STOCK,
    GET_REGIONS,
    GET_VENDING_MACHINE_LIVE_STATUS,
    VENDING_MACHINE_REGISTRATION,
    LIST_REGISTERED_VENDING_MACHINE,
    AVAILABLE_VENDING_MACHINE,
    REGISTERED_VENDING_MACHINE
} from '../actions/types';

const initialState ={
    vendingMachine: null,
    vendingMachines: [],
    loading: true,
    getVendingMachinesStock: [],
    getregions: [],
    stockInfo: {},
    liveStatus: [],
    registrationList: null,
    registeredVMList: null,
    registeredVendingMachine: null,
    vendingMachinesForRegistration: []
}

export default function( state = initialState, action){
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_VENDING_MACHINES:
            return {
                ...state,
                vendingMachines: payload,
                loading: false
            };
        case GET_VENDING_MACHINE:
            return{
                ...state,
                vendingMachine: payload,
                loading: false
            };
        case ADD_VENDING_MACHINE:
            return {
                ...state,
                vendingMachines: [...state.vendingMachines, payload]
            };
        case UPDATE_VENDING_MACHINE:
            return {
                ...state,
                vendingMachine: payload
            };
        case GET_VENDING_MACHINE_STOCK:
            return {
                ...state,
                stockInfo : payload
            };
        case GET_REGIONS:
            return {
                ...state,
                getregions : payload
            };
        case GET_VENDING_MACHINE_LIVE_STATUS:
            return {
                ...state,
                liveStatus: payload
            };
        case VENDING_MACHINE_REGISTRATION:
            return {
                ...state,
                registrationList : payload
            };
        case LIST_REGISTERED_VENDING_MACHINE:
            return {
                ...state,
                registeredVMList : payload
            };
        case REGISTERED_VENDING_MACHINE:
            return {
                ...state,
                registeredVendingMachine : payload
            };
        case AVAILABLE_VENDING_MACHINE:
            return {
                ...state,
                vendingMachinesForRegistration : payload
            };
        default:
            return state;
    }
}