import React, { Fragment, useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Switch,
  Route 
} from 'react-router-dom';
import './App.css';
import './components/layout/Navbar';
import './components/layout/Landing';
import Navbar from './components/layout/Navbar';
import Routes from './components/routing/Routes';

//Redux
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { Redirect } from 'react-router-dom';

if(localStorage.token){
  setAuthToken(localStorage.token)
}

const App = () => {
  // useEffect runs infinite , [] -> to make run once on load
  useEffect(() => {
  }, []);

  return(
    <Provider store={ store }>
      <Router>
        <Fragment>
          <Navbar />
          <Switch>
          <Route exact path="/">
            <Redirect to={`/inventory/${process.env.REACT_APP_BUILD_VERSION}/`} />
          </Route>
          <Route component={ Routes }></Route>
          </Switch>
        </Fragment>
      </Router>
    </Provider> 
  );
};

export default App;
