import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSales, downloadSales } from '../../actions/sales';
import { saveAs } from 'file-saver';


// Import React Table
import "bootstrap/dist/css/bootstrap.min.css"

import TableContainer from "./TableContainer"; 

const Sales = ({location, sales, downloadBlob, getSales, downloadSales, pageList, pages, activePage}) => {

    const initialState = {
        startDate:'',
        endDate:'',
        latest: true
    };

    const [formData, setFormData] = useState(initialState);
    const [data, setData] = useState([])
    const [pageCount, setPage] = useState(0);
    const [currentPage, setActivePage] = useState(1);
    const [queryString, setQueryString] = useState("page=1&latest=true");
    const [download, setDownload] = useState(false);

    useEffect(() => {
        if(downloadBlob && download){
            const blob = new Blob([downloadBlob], { type: 'application/pdf' })
            saveAs(blob, "sales.pdf");
            setDownload(false);
        }
        if(!sales){
            let query = queryString;
            setQueryString(query);
            getSales(query);
        }else{
            let salesData = [];
            sales.forEach(sale => {
                salesData.push({
                    month : new Date(sale.date).toLocaleString('default', { month: 'long' }),
                    date : sale.date,
                    sales : sale.sales,
                    refundableDeposit: sale.refundableDeposit,
                    securityDeposit : sale.securityDeposit,
                    gst : sale.gst,
                    download : sale._id
                });
            });
            setQueryString(queryString.replace("page=" + currentPage, "page=" + activePage));
            setData(salesData);
            setPage(pages);
            setActivePage(activePage);
        }
    }, [ sales, downloadBlob ] );


    const columns = useMemo(
        () => [
            {
                Header: 'Month',
                accessor: 'month',
                disableSortBy: true,
            },
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Sales',
                accessor: 'sales',
            },
            {
                Header: 'Refundable Deposit',
                accessor: 'refundableDeposit',
            },
            {
                Header: 'Security Deposit',
                accessor: 'securityDeposit'
            },
            {
                Header: 'GST',
                accessor: 'gst'
            },
            {
                Header: 'Download',
                accessor: 'download'
            }
        ],
        []
    );

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const {
        startDate,
        endDate,
        latest
    } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {
        e.preventDefault();
        const startDate = new Date(formData.startDate).getTime();
        const endDate = new Date(formData.endDate).getTime();
        if(endDate < startDate){
            return alert("Invalid date")
        }
        let queryString = "page=1&latest=" + formData.latest;
        if(formData.startDate && formData.endDate){
            queryString += "&startDate=" + formData.startDate + "&endDate=" + formData.endDate;
        }
        setQueryString(queryString);
        getSales(queryString);
    };

    const downloadSalesPDF = (e, id) => {
        downloadSales("id=" + id)
        setDownload(true);
    }

    const seachForm = () => {
        return(
            <form style={{width:"100%"}} onSubmit={ e => onSubmit(e) }>
                <div className="form-row">
                    <div className="col-2">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">Start Date</label>
                        <input name='startDate' value={startDate} onChange={e => onChange(e)}
                        type="date" className="form-control" placeholder="Start Date"/>
                    </div>
                    <div className="col-2">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">End Date</label>
                        <input name='endDate' value={endDate} onChange={e => onChange(e)}
                        type="date" className="form-control" placeholder="End Date"/>
                    </div>
                    <div className="col-2">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">View By</label>
                        <select name='latest' value={latest} type="text" className="custom-select mr-sm-2"
                            onChange={e => onChange(e)}>
                            <option value="false">Old</option>
                            <option value="true">New</option>
                        </select>
                    </div>
                    <div className="col-2" style={{display: "flex",alignItems: "flex-end"}}>
                        <input type='submit' className='btn btn-primary mx-auto mt-2'/>
                    </div>
                </div>
            </form>
        )
    }

    const renderSalesData = () => {        
        if(data !== undefined && data.length > 0 ){
            return(
                <TableContainer columns={columns} data={data} pages={pageCount} activePage={currentPage}
                    queryString={queryString} getSales={getSales} downloadSalesPDF={downloadSalesPDF} />
            );
        }
    }

    return (
        <Fragment>
        <div className="container-fluid">
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'>Sales</h3>
            </div>
            <div className='d-flex  mt-5 flex-wrap'>
                {  seachForm() }
            </div>
            <div className='d-flex  mt-5 flex-wrap'>                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">sales</h6>
                    </div>                    
                    <div className="card-body">
                        <div className="table-responsive">
                            { renderSalesData() }
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        </Fragment>
    )
}

Sales.PpropTypes = {
    getSales : PropTypes.func.isRequired,
    downloadSales: PropTypes.func.isRequired,
    sales : PropTypes.array,
    pages : PropTypes.Number,
    activePage : PropTypes.Number
}

const mapStateToProps = state => ({
    sales: state.sales.sales,
    downloadBlob: state.sales.downloadBlob,
    pages: state.sales.pages,
    activePage: state.sales.activePage,
    loading: state.sales.loading
})

export default connect( mapStateToProps, { getSales, downloadSales } )( Sales );