import axios from 'axios';
import { setAlert } from './alert';
import {
    GET_PENALTY_REQUEST
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get penalty
export const getPenalty = (id) => async dispatch => {

    try {
        const res = await axios.get(`${API_URL}/penalty/${id}`);
        if(res.data.success){
            dispatch({
                type: GET_PENALTY_REQUEST,
                payload: res.data.penalty
            });
            dispatch(setAlert('Penalty retrieved successfully ', 'success'));
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to users penalty' ) );
    }
};


// Add a penalty
export const addPenalty = (id, formData) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/penalty/${id}`, formData, config);        
        if(res.data.success){
            dispatch(setAlert('Successfully add a penalty', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        console.log(err)
        dispatch( setAlert('Error: Failed to add penalty', 'danger') );
    }
};

// Update penalty
export const updatePenalty = (id, formData) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res =  await axios.put(`${API_URL}/penalty/${id}`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Penalty Updated', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update penalty', 'danger') );
    }
};