import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrders } from '../../actions/order';

// Import React Table
import "bootstrap/dist/css/bootstrap.min.css"



import TableContainer from "./TableContainer"


const Orders = ({location, orders, getOrders, pageList, pages, activePage}) => {

    const initialState = {
        vendingMachineId: '',
        paymentId:'',
        startDate:'',
        endDate:'',
        phone:'',
        orderId:'',
        latest: true
    };

    const [formData, setFormData] = useState(initialState);
    const [data, setData] = useState([])
    const [pageCount, setPage] = useState(0);
    const [currentPage, setActivePage] = useState(1);
    const [queryString, setQueryString] = useState("page=1&latest=true");
    
    useEffect(() => {
        if(orders === undefined ){
            let query = queryString;
            if(location.search){
                let phoneNumber = location.search.split("=");
                if(phoneNumber.length === 2 & phoneNumber[1].length === 10){
                    query += "&phone=" + phoneNumber[1];
                }
            }
            getOrders(query);
        }else{
            let orderData = []
            orders.forEach(order => {
                //If users gets deleted
                if(!order.user){
                    return
                }
                orderData.push({
                    penalty: order.penalty.totalPrice,
                    discount: order.discount.totalDiscount,
                    orderStatus: order.orderStatus,
                    _id: order._id,
                    gst: order.gst,
                    netAmount: order.netAmount,
                    userName: order.user.name,
                    userId: order.user._id,
                    userPhone: order.user.phone,
                    vendingMachineId: order.vendingMachine._id,
                    vendingMachine: order.vendingMachine.name,
                    orderId: order.orderId,
                    time: new Date(order.time).toString().split("GMT")[0]
                });
            });
            setQueryString(queryString.replace("page=" + currentPage, "page=" + activePage));
            setData(orderData)
            setPage(pages);
            setActivePage(activePage)
        }
    }, [ orders ] );


    const columns = useMemo(
        () => [
            {
                Header: 'Order Id',
                accessor: 'orderId',
                disableSortBy: true,
            },
            {
                Header: 'User',
                accessor: 'userName',
            },
            {
                Header: 'Phone',
                accessor: 'userPhone',
            },
            {
                Header: 'Vending Machine',
                accessor: 'vendingMachine',
            }, 
            {
                Header: 'Amount',
                accessor: 'netAmount',
            },
            {
                Header: 'Penalty',
                accessor: 'penalty',
            },
            {
                Header: 'Discount',
                accessor: 'discount',
            },
            {
                Header: 'Order Status',
                accessor: 'orderStatus',
            },
            {
                Header: 'GST',
                accessor: 'gst',
            },                     
            {
                Header: 'Time',
                accessor: 'time',
            },
        ],
        []
      );

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    const {
        vendingMachineId,
        paymentId,
        startDate,
        endDate,
        phone,
        orderId,
        latest
    } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {
        e.preventDefault();
        const startDate = new Date(formData.startDate).getTime();
        const endDate = new Date(formData.endDate).getTime();
        if(endDate < startDate){
            return alert("Invalid date")
        }
        let queryString = "page=1&latest=" + formData.latest;
        if(formData.startDate && formData.endDate){
            queryString += "&startDate=" + formData.startDate + "&endDate=" + formData.endDate;
        }
        if(formData.vendingMachineId){
            queryString += "&vendingMachine=" + formData.vendingMachineId;
        }
        if(formData.paymentId){
            queryString += "&paymentId=" + formData.paymentId;
        }
        if(formData.orderId){
            queryString += "&orderId=" + formData.orderId;
        }
        if(formData.phone){
            queryString += "&phone=" + formData.phone;
        }
        setQueryString(queryString);
        getOrders(queryString);
    };

    const seachForm = () => {
        return(
            <form style={{width:"100%"}} onSubmit={ e => onSubmit(e) }>
                <div className="form-row">
                    <div className="col-3">
                        <input name='vendingMachineId' value={vendingMachineId} onChange={e => onChange(e)}
                        type="text" className="form-control" placeholder="Vending Machine ID"/>
                    </div>
                    <div className="col-3">
                        <input name='orderId' value={orderId} onChange={e => onChange(e)}
                        type="text" className="form-control" placeholder="Order ID"/>
                    </div>
                    <div className="col-3">
                        <input name='phone' value={phone} onChange={e => onChange(e)}
                        type="text" className="form-control" placeholder="Phone"/>
                    </div>
                    <div className="col-3">
                        <input name='paymentId' value={paymentId} onChange={e => onChange(e)}
                        type="text" className="form-control" placeholder="Payment ID"/>
                    </div>
                    <div className="col-3">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">Start Date</label>
                        <input name='startDate' value={startDate} onChange={e => onChange(e)}
                        type="date" className="form-control" placeholder="Start Date"/>
                    </div>
                    <div className="col-3">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">End Date</label>
                        <input name='endDate' value={endDate} onChange={e => onChange(e)}
                        type="date" className="form-control" placeholder="End Date"/>
                    </div>
                    <div className="col-3">
                        <label style={{paddingTop:"5%"}} className="mr-sm-2">View By</label>
                        <select name='latest' value={latest} type="text" className="custom-select mr-sm-2"
                        onChange={e => onChange(e)}>
                            <option value="false">Old</option>
                            <option value="true">New</option>
                        </select>
                    </div>
                    <div className="col-3" style={{display: "flex",alignItems: "flex-end"}}>
                        <input type='submit' className='btn btn-primary mx-auto mt-2'/>
                    </div>
                </div>
            </form>
        )
    }

    const renderOrderData = () => {        
        if(data !== undefined && data.length > 0 ){
            return(
                <TableContainer columns={columns} data={data} pages={pageCount} activePage={currentPage} queryString={queryString} getOrders={getOrders} />
            );            
        }
    }

    return (
        <Fragment>
            <div className='text-primary d-flex flex-row'>
            </div>
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'>Orders</h3>
            </div>
            <div className='d-flex  mt-5 flex-wrap'>
                {  seachForm() }
            </div>
            <div id='containers' className='d-flex  mt-5 flex-wrap'>
                { renderOrderData() }
            </div>            
        </Fragment>
    )
}

Orders.PpropTypes = {
    getOrders : PropTypes.func.isRequired,
    orders : PropTypes.array,
    pages : PropTypes.Number,
    activePage : PropTypes.Number
} 
const mapStateToProps = state => ({
    orders: state.order.orders,
    pages: state.order.pages,
    pageList : state.order.pageList,
    activePage : state.order.activePage,
    loading: state.order.loading
})

export default connect( mapStateToProps, { getOrders } )( Orders );