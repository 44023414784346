import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken'
import {
    GET_TRANSACTIONS_REQUEST,
    GET_ALL_TRANSACTIONS,
    UPDATE_TRANSACTIONS,
    OPEN_CONTAINER,
    REFUND_CAN,
    GET_LEDGER
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;


// Get All Transactions
export const getTransactions = (filters) => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/transactions/all?${filters}`);
        if(res.data.success){
            dispatch({
                type: GET_ALL_TRANSACTIONS,
                payload: res.data
            });
            dispatch( setAlert('Successfully fetched transactions', 'success') );
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth Orders' ) );
    }
}


// Get User Transactions
export const getTransaction = (transactionId) => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/transactions/${transactionId}`);
        if(res.data.success){
            dispatch({
                type: GET_TRANSACTIONS_REQUEST,
                payload: res.data.canTransaction
            });
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth Orders' ) );
    }
}

// Update Transaction Info
export const updateTransaction = (formData, transactionId) => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.put(`${API_URL}/transactions/${transactionId}`, formData, config);
        if(res.data.success){
            dispatch({
                type: UPDATE_TRANSACTIONS,
                payload: res.data
            });
            dispatch(setAlert('Transaction Updated', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to updated a transaction', 'danger') );
    }
};


// Open Container
export const openContainer = (formData, transactionId) => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.post(`${API_URL}/transactions/${transactionId}`, formData, config);
        if(res.data.success){
            dispatch({
                type: OPEN_CONTAINER,
                payload: res.data
            });
            dispatch(setAlert('Container Open Message Sent', 'success'));
        }else{
            dispatch( setAlert(res.data.message, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to open container', 'danger') );
    }
};

// Refund Can
export const refundCan = (formData, transactionId) => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
        }
    }
    try {
        const res =  await axios.put(`${API_URL}/transactions/${transactionId}/refund-can`, formData, config);
        if(res.data.success){
            dispatch({
                type: REFUND_CAN,
                payload: res.data
            });
            dispatch(setAlert('Can Refund Successful', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to refund can', 'danger') );
    }
};

// Get Uer's Ledger
export const getLedger = (userId) => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/transactions/ledger?${userId}`);
        if(res.data.success){
            dispatch({
                type: GET_LEDGER,
                payload: res.data
            });
            dispatch( setAlert('Successfully fetched ledger', 'success') );
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth ledger info' ) );
    }
}