import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getVendingMachine, updateVendingMachine, getVendingMachinesStock } from '../../actions/vendingMachine';
import {Container, Row, Col, Table} from "react-bootstrap";
import { getProfiles } from '../../actions/profile';
import { Button } from 'react-bootstrap';

const Redis = ({ }) => {    

    useEffect(() => {
        
    }, [ ]);

    const customLableStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        textAlign: 'right',
        width: '400px',
        lineHeight: '26px',
        marginBottom: '10px'
    }

    return (
    <Fragment>
        <div className='d-flex flex-column justify-content-center'>            
            <br></br>
            <br></br>
            <br></br>
            <Container>
                <Row>
                    <Col>
                        <div className='text-primary mt-2 d-flex flex-row'>
                            <h3 className='mx-auto'>Redis Details</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Fragment>
  );
};

Redis.propTypes = {
    
};

const mapStateToProps = state => ({
    
});

export default connect(
    mapStateToProps, 
    {  }
)(Redis);