import React , { useEffect, Fragment }from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getRegions } from '../../actions/region';
import RegionCard from './RegionCard';


const Regions = ({ getRegions, regions, loading }) => {

    useEffect( () => {
        getRegions();
    }, [ ]);
    
    const populateRegions = () => {
        if(regions && regions.length>0){
            return regions.map((region, index) => {                 
                return (
                    <RegionCard key={index} region={region} />
                );
            });
        }
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <Link
                    to="/inventory/v1.2/regions/new"
                    style={{'width':'200px'}}
                    className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-5 ml-1 ">
                        Add New Region
                </Link>

                <div className='d-flex  mt-5 flex-wrap'>
                    {  populateRegions() }
                </div>
            </div>
        </Fragment>
    )
}


Regions.propTypes = {
    regions: PropTypes.array,
    getRegions: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}

const mapStateToProps = state => ({
    regions: state.region.regions,
    loading: state.region.loading
});

export default connect(mapStateToProps, { getRegions } )(Regions);
