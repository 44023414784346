import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken'


import {
    GET_REGIONS,
    GET_REGION,
    GET_REGION_REQUEST,
    UPDATE_REGION
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// Get Regions
export const getRegions = () => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    dispatch({
        type : GET_REGION_REQUEST,
        payload: true
    });
    
    
    try {
        const res =  await axios.get(`${API_URL}/regions`);

        if(res.data.success){
            dispatch({
                type : GET_REGIONS,
                payload: res.data.regions
            });
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Failed to fetch Regions', 'danger'));
    }
}


// Get Region
export const getRegion = id => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    dispatch({
        type : GET_REGION_REQUEST,
        payload: true
    });
    try {
        const res =  await axios.get(`${API_URL}/regions/${id}`);
  
        if(res.data.success){  
            dispatch({
                type : GET_REGION,
                payload: res.data.region
            });
            dispatch( setAlert('Successfully fetched Region details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get Region details', 'danger'));
    }
};

// Add Region
export const addRegion = formData => async dispatch => {
    if( localStorage.token ){
        setAuthToken( localStorage.token )
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/regions`, formData, config);
        if(res.data.success){
            dispatch(getRegions);
            dispatch(setAlert('Successfully add a region', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to add new region', 'danger') );
    }
};


// Update Region
export const updateRegion = (id, data) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };  
    try {
        const res =  await axios.put(`${API_URL}/regions/update-profile/${id}`, data, config);
        if(res.data.success){
            dispatch({
                type : UPDATE_REGION,
                payload: res.data.region
            });
            dispatch(setAlert('Region Updated', 'success'));

        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }      
    } catch (err) {
        dispatch( setAlert('Error: Failed to update region', 'danger') );
    }
};