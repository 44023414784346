import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect  } from 'react-redux';
import { updateBrandProfileData, deleteBrandProfileData } from '../../actions/profile';

const Card = (props) =>{

    const [brand, setBrandData] = useState(props.brand);
    const profileId = props.profileId;

    const showProducts = () => {
        if(props.brand){
            return props.products.map((product, index) => {
                return (
                    <option key={`product-${index}`} value={product._id}>{product.brand}</option>
                )   
            });
        }
    }

    const checkProduct = (productId) => {
        return props.brands.some(brand => brand.product === productId);
    }

    const onProductSelect=(e, objectId)=>{
        if(checkProduct(e.target.value)){
            props.handleOpen(e.target[e.target.selectedIndex].text, "Brand already assigned");
            return;
        }
        let brandData = { ...brand };
        brandData[e.target.name] = e.target.value;
        brandData["brand"] = e.target[e.target.selectedIndex].text;
        brandData["productType"] = props.products[e.target.selectedIndex - 1].productType;
        setBrandData(brandData);
        let payload = {
            type: null,
            query: { brand: brandData.brand, product: brandData.product }
        }
        props.updateBrandProfileData(profileId, objectId, payload);
    }

    const updateActiveStatus = (e, objectId, isActive, productType) => {
        let brandData = { ...brand };
        brandData[productType][e.target.name] = e.target.value;
        setBrandData(brandData);
        let payload = {
            type: productType,
            field: e.target.name,
            value : isActive
        }
        props.updateBrandProfileData(profileId, objectId, payload);
    }

    const updateProfileData = (e, productType, objectId) => {
        //Run loop and check if selected priority is already set to other brand bundle/unit
        if(e.target.name === "priority"){
            let priorityMatched = props.profile.brands.find(brand => brand[productType].priority === Number(e.target.value));
            if(priorityMatched){
                props.handleOpen("Priority", "Priority " + e.target.value + " already assigned");
                return;
            }
        }

        //Check Increment By
        if(e.target.name === "incrementBy"){
            if(e.target.value > brand[productType]["maxPurchase"]){
                props.handleOpen("Increment By", "Should be lesser than max purchase");
                return;
            }
        }
        let brandData = { ...brand };
        let message = "";
        let name = e.target.name;
        let value = Number(e.target.value);
        brandData[productType][e.target.name] = Number(e.target.value);
        setBrandData(brandData);
        if(name === "maxPurchase"){
            if(brandData[productType]["minPurchase"] > value){
                message = "Max Purchase should be greater than Min Purchase"
                props.handleOpen(e.target.placeholder, message);
                return;
            }
        }
        if(name === "discountPrice"){
            if(brandData["price"] < value){
                message = "Discount Price should be lesser than Product Price"
                props.handleOpen(e.target.placeholder, message);
                return;
            }
        }else if(name === "minPurchase"){
            if(brandData[productType]["maxPurchase"] < value){
                message = "Min Purchase should be lesser than Max Purchase"
                props.handleOpen(e.target.placeholder, message);
                return;
            }
        }
        let payload = {
            type: productType,
            field: e.target.name,
            value : e.target.value
        }
        props.updateBrandProfileData(profileId, objectId, payload)
    }

    const updatePriceData = (e, objectId) => {
        setBrandData({...brand, [e.target.name] : e.target.value});
        let payload = {
            type: null,
            query: { price : e.target.value }
        }
        props.updateBrandProfileData(profileId, objectId, payload);
        // props.update(profileId, payload);
    }

    const deleteProfileData = (e, objectId) => {
        props.deleteBrandProfileData( profileId, objectId )
    }

    return(        
        <div style={{width: "25%", padding: "2%"}}>
            <div className="card border-left-primary shadow h-100 py-2">
                <a href="#/" style={{ 'width': '60px' }}className='btn btn-sm btn-light text-primary d-flex ml-auto mr-2' onClick={ e => deleteProfileData(e, brand._id)}>
                    Delete
                </a>
            <div className="card-body">
                <div className="no-gutters align-items-center">
                    <div className="form-group d-flex">
                        <label className='mt-2 mr-2'>Brand :</label>
                        <select style={{ width: '150px' }}
                            className="form-control form-control-user ml-auto" 
                            value={brand?brand.product:""}
                            name='product'
                            onChange={e => onProductSelect(e, brand._id) }>
                                <option value="">Select Product</option>
                                { showProducts() }
                        </select>
                    </div>

                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Price</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'price-' + brand.product}
                            className="form-control form-control-user ml-auto" 
                            type='number'
                            placeholder='Price'
                            name='price'
                            value={brand.price}
                            min='1'
                            onChange={ e => updatePriceData(e, brand._id)}
                        />
                    </div>

                    {/* Bundle */}
                    <div style={{textAlign: "center", margin: "20px" }}>
                        <h5>Bundle</h5>
                    </div>
                    <div className="form-group d-flex">                        
                        <label className='mt-2 mr-2'>Status</label>
                        <select 
                            style={{ "width" : "150px" }}
                            className="form-control form-control-user ml-auto" 
                            value={brand.bundle.active}
                            name='active'
                            onChange={e => updateActiveStatus( e, brand._id, !brand.bundle.active, "bundle" ) }>
                                <option value="true">Active</option>
                                <option value="false">Dissable</option>
                        </select>
                    </div>
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Discount Price</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'bundleDiscountPrice-' + brand.product}
                            className="form-control form-control-user ml-auto" 
                            type='number'
                            placeholder='Discount Price'
                            name='discountPrice'
                            value={brand.bundle.discountPrice}
                            min='0'
                            onChange={ e => updateProfileData(e, "bundle", brand._id)}
                        />
                    </div>
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Min Purchase</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'bundleMinPurchase-' + brand.product}
                            className="form-control form-control-user ml-auto" 
                            type='number'
                            placeholder='Min Purchase'
                            name='minPurchase'
                            value={brand.bundle.minPurchase}
                            min='1'
                            onChange={ e => updateProfileData(e, "bundle", brand._id)}
                        />
                    </div>                    
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Max Purchase</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'bundleMaxPurchase-' + brand.product}
                            className="form-control form-control-user ml-auto"
                            type='number'
                            placeholder='Max Purchase'
                            name='maxPurchase'
                            value={brand.bundle.maxPurchase}
                            min='1'
                            onChange={ e => updateProfileData(e, "bundle", brand._id)}
                        />
                    </div>
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Increment By</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'bundleIncrementBy-' + brand.product}
                            className="form-control form-control-user ml-auto" 
                            type='number'
                            placeholder='Increment'
                            name='incrementBy'
                            value={brand.bundle.incrementBy}
                            min='1'
                            onChange={ e => updateProfileData(e, "bundle", brand._id)}
                        />
                    </div>
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Priority</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'priority-' + brand.product}
                            className="form-control form-control-user ml-auto" 
                            type='number'
                            placeholder='Priority'
                            name='priority'
                            value={brand.bundle.priority}
                            min='1'
                            onChange={ e => updateProfileData(e, "bundle", brand._id)}
                        />
                    </div>

                    {/* Unit */}
                    <div style={{textAlign: "center", margin: "20px" }}>
                        <h5>Unit</h5>
                    </div>
                    <div className="form-group d-flex">
                        <label className='mt-2 mr-2'>Status</label>
                        <select 
                            style={{ "width" : "150px" }}
                            className="form-control form-control-user ml-auto" 
                            value={brand.unit.active}
                            name='active'
                            onChange={e => updateActiveStatus( e, brand._id, !brand.unit.active , "unit") }>
                                <option value="true">Active</option>
                                <option value="false">Dissable</option>
                        </select>
                    </div>
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Discount Price</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'unitDiscountPrice-' + brand.product}
                            className="form-control form-control-user ml-auto" 
                            type='number'
                            placeholder='Discount Price'
                            name='discountPrice'
                            value={brand.unit.discountPrice}
                            min='0'
                            onChange={ e => updateProfileData(e, "unit", brand._id)}
                        />
                    </div>
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Min Purchase</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'unitMinPurchase-' + brand.product}
                            className="form-control form-control-user ml-auto"
                            type='number'
                            placeholder='Min Purchase'
                            name='minPurchase'
                            value={brand.unit.minPurchase}
                            min='1'
                            onChange={ e => updateProfileData(e, "unit", brand._id)}
                        />
                    </div>                    
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Max Purchase</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'unitMaxPurchase-' + brand.product}
                            className="form-control form-control-user ml-auto"
                            type='number'
                            placeholder='Max Purchase'
                            name='maxPurchase'
                            value={brand.unit.maxPurchase}
                            min='1'
                            onChange={ e => updateProfileData(e, "unit", brand._id)}
                        />
                    </div>
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Increment By</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'unitIncrementBy-' + brand.product}
                            className="form-control form-control-user ml-auto"
                            type='number'
                            placeholder='Increment'
                            name='incrementBy'
                            value={brand.unit.incrementBy}
                            min='1'
                            onChange={ e => updateProfileData(e, "unit", brand._id)}
                        />
                    </div>
                    <div className='form-group d-flex'>
                        <label className='mt-2 mr-2'>Priority</label>
                        <input
                            style={{ 'width' : '150px' }}
                            id={'unitPriority-' + brand.product}
                            className="form-control form-control-user ml-auto"
                            type='number'
                            placeholder='Priority'
                            name='priority'
                            value={brand.unit.priority}
                            min='1'
                            onChange={ e => updateProfileData(e, "unit", brand._id)}
                        />
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

Card.propTypes = {
    products: PropTypes.array,
    profile: PropTypes.object,
    updateBrandProfileData: PropTypes.func.isRequired,
    deleteBrandProfileData: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    products: state.product.products,
    profile: state.profile.profile
});

export default connect(mapStateToProps, { updateBrandProfileData, deleteBrandProfileData } )(Card);