import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVendingMachineLiveStatus } from '../../actions/vendingMachine';

import TableContainer from "./TableContainer"
import { Button } from 'react-bootstrap';

const LiveStatus = ({
    loading,
    liveStatus,
    getVendingMachineLiveStatus
}) => {
    const [filteredData ,setFilteredData] = useState([]);
    useEffect(() => {
        if(liveStatus.length == 0){
            getVendingMachineLiveStatus();
        }else if(liveStatus.length !== 0){
            // setFilteredData([...liveStatus])
            let newData = liveStatus.filter( function(item){
                return item.status === "Offline"
            });
            setFilteredData(newData)
        }
    }, [ liveStatus ]);

    const columns = useMemo(
        () => [
            {
                Header: 'SL NO.',
                accessor: 'slno'
            },
            {
                Header: 'Vending Machine',
                accessor: 'name',
            },
            {
                Header: 'Vending Machine Id',
                accessor: 'vendingMachineId',
                disableSortBy: true,
            },            
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Last Seen Online',
                accessor: 'lastSeenOnline',
            },
            {
                Header: 'Strength',
                accessor: 'strength',
            }
        ],
        []
    );

    const  populateTable = () => {
        return(
            // <TableContainer columns={columns} data={liveStatus} pages={1} activePage={1} getStatus={getVendingMachineLiveStatus} />
            <TableContainer columns={columns} data={filteredData} pages={1} activePage={1} getStatus={getVendingMachineLiveStatus} />
        );
    }

    const filterStatus = (status) =>{
        if(status === "All"){
            return setFilteredData(liveStatus)
        }
        let newData = liveStatus.filter( function(item){
            return item.status === status
        });
        setFilteredData(newData)
    }

    return (
        <Fragment>
            <div className='text-primary d-flex flex-row'>
                
            </div> 
            
            <div className='text-primary d-flex flex-row'>
                <h3 className='mx-auto'>Vending Machine Live Status</h3>
            </div>
            <div className='d-flex flex-row'>
            <h5>Status </h5>
            <Button  style={{marginLeft: "1%"}} onClick={ e => filterStatus("All") }>All</Button>
            <Button  style={{marginLeft: "1%"}} onClick={ e => filterStatus("Online") }>Online</Button>
            <Button  style={{marginLeft: "1%"}} onClick={ e => filterStatus("Offline") }>Offline</Button>
            <Button  style={{marginLeft: "1%"}} onClick={ e => filterStatus("Unavailable") }>Unavailable</Button>
            </div>
            <div id='containers' className='d-flex  mt-5 flex-wrap'>
                {liveStatus.length ?  populateTable() : "" }
            </div>
        </Fragment>
    );
}

LiveStatus.propTypes = {
    getVendingMachineLiveStatus: PropTypes.func,
    liveStatus: PropTypes.array
}

const mapStateToProps = state => ({
    liveStatus: state.vendingMachine.liveStatus,
    loading: state.vendingMachine.loading,
});

export default connect(mapStateToProps, { getVendingMachineLiveStatus } )(LiveStatus);