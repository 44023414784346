import uuid from 'uuid' 
import { SET_ALERT, REMOVE_ALERT } from './types';

export const setAlert = ( msg, alertType, timeout = 1500 ) => dispacth => {
    const id = uuid.v4();
    dispacth({
        type: SET_ALERT,
        payload: {
            id,
            msg,
            alertType
        }
    });

    setTimeout(() => dispacth({
        type: REMOVE_ALERT,
        payload: id
    }), timeout);
};