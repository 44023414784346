import React , { useEffect, Fragment }from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfiles } from '../../actions/profile';
import ProfileCard from './ProfileCard';

const Profiles = ({ getProfiles, profiles, loading }) => {

    useEffect( () => {
        getProfiles();
    }, [ ]);
    
    const getAllProfileCards = () => {
        if(profiles && profiles.length>0){
            return profiles.map((profile, index) => {                 
                return (
                    <ProfileCard key={index} profile={profile} />
                );
            });
        }
    }

    return (
        <Fragment>
        <div className="container-fluid">
            <Link 
                to="/inventory/v1.2/profiles/new"
                style={{'width':'200px'}} 
                className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-5 ml-1 ">
                    Add New Profile
            </Link>
            
            <div className='d-flex  mt-5 flex-wrap'>
                {  getAllProfileCards() }
            </div>
        </div>
        </Fragment>
    )
}

Profiles.propTypes = {
    profiles: PropTypes.array,
    getProfiles: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}

const mapStateToProps = state => ({
    profiles: state.profile.profiles,
    loading: state.profile.loading
});
export default connect(mapStateToProps, { getProfiles } )(Profiles);

