import axios from 'axios';
import { setAlert } from './alert';
import { 
    GET_ALL_USER_REQUEST,
    GET_ALL_USERS_SUCCESS,
    GET_USER_SUCCESS,
    GET_USER_BY_QUERY,
    UPDATE_USER_CANS
} from './types';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

// GET all Users

export const getAllUsers = (page) => async dispatch => {

    dispatch({
        type: GET_ALL_USER_REQUEST,
        payload: true
    });

    try {
        const res = await axios.get(`${API_URL}/users/all?page=${page}`);
        if(res.data.success){
            dispatch({
                type: GET_ALL_USERS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch( setAlert( res.data.error, 'danger' ) );
        }
    } catch ( err ) {
        dispatch( setAlert( 'Failed to feth Users' ) );
    }
}



// Get a User
export const getUser = id => async dispatch => {    
    try {
        const res =  await axios.get(`${API_URL}/users/${id}/all`);
  
        if(res.data.success){            
            dispatch({
                type : GET_USER_SUCCESS,
                payload: res.data.data
            });
            dispatch( setAlert('Successfully fetched User details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get User details', 'danger'));
    }
};

// Get user by phone number or email id
export const getUserByQuery = (phone, email) => async dispatch => {
    try {
        const res =  await axios.get(`${API_URL}/users?phone=${phone}&email=${email}`);
        if(res.data.success){
            dispatch({
                type : GET_USER_BY_QUERY,
                payload: res.data.data
            });
            dispatch( setAlert('Successfully fetched User details', 'success') );
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch(setAlert('Error: Failed to get User details', 'danger'));
    }
};



// Add a User
export const addUser = formData => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
        const res =  await axios.post(`${API_URL}/auth/register`, formData, config);        
        if(res.data.success){
            dispatch(getAllUsers);
            dispatch(setAlert('Successfully add a user', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to add a user', 'danger') );
    }
};

// Add note
export const addNote = (id,formData) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
        const res =  await axios.put(`${API_URL}/users/${id}/note`, formData, config);
        if(res.data.success){
            dispatch(setAlert('Successfully add note', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to add note', 'danger') );
    }
};


// Update a User
export const updateUser = (id,formData) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res =  await axios.put(`${API_URL}/users/${id}`, formData, config);
        if(res.data.success){
            dispatch(setAlert('User Updated', 'success'));
            dispatch(getAllUsers());
            dispatch(getUser(id));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update user', 'danger') );
    }
};

// Update User's Deposit
export const updateDeposit = (id, userId, formData) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res =  await axios.put(`${API_URL}/deposit/${id}`, formData, config);
        if(res.data.success){
            dispatch(setAlert('User deposit updated', 'success'));
            dispatch(getUser(userId));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update user depost', 'danger') );
    }
};


// Update User's Cans
export const updateCans = (id, formData) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res =  await axios.put(`${API_URL}/cans/${id}`, formData, config);
        if(res.data.success){
            dispatch({
                type : UPDATE_USER_CANS,
                payload: res.data.cans
            });
            dispatch(setAlert('User cans updated', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update user cans', 'danger') );
    }
};


// Update User's Cans Limit
export const updateCansLimit = (id, formData) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        const res =  await axios.put(`${API_URL}/cans/limit/${id}`, formData, config);
        if(res.data.success){
            dispatch({
                type : UPDATE_USER_CANS,
                payload: res.data.cans
            });
            dispatch(setAlert('User cans updated', 'success'));
        }else{
            dispatch( setAlert(res.data.error, 'danger') );
        }
    } catch (err) {
        dispatch( setAlert('Error: Failed to update user cans', 'danger') );
    }
};
