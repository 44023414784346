import React , { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import { setAlert } from '../../actions/alert';
import { login, getOTP } from '../../actions/auth';


const Login = (props) => {

    const [ formData, setFormData ] = useState({
        phone: '',
        OTP:''
    });
    const { phone, OTP } = formData;
    const [ reSendOTP, setReSendOTP ] = useState(false);

    const onChange = e => {
        setFormData({ 
            ...formData,
            [ e.target.name ] : e.target.value 
        });
    }

    const onSubmit =  e => {
        e.preventDefault();
        props.login({
            phone,
            OTP
        });
    }
    const requestOTP = e => {
        e.preventDefault();
        if (phone.length!==10){
            props.setAlert(
                'Invalid phone no',
                'danger'
            );
        }else{
            setReSendOTP(true);
            props.getOTP({ 
                phone:formData.phone
            });
        }
    }

    if (props.isAuthenticated) {
        return <Redirect to="/inventory/v1.2/" />
    }
    return (
        <React.Fragment>
        <div className='d-flex flex-column justify-content-center align-items-center mt-3 p-md-2'>
            <h2 className= 'mt-5'>  Log in </h2>

            <form className='form mt-5' onSubmit={ e => onSubmit(e) } style={{"width": "400px"}}>                
                <div className='form-group'>
                    <input
                        className="form-control form-control-user" 
                        type='number'
                        placeholder='Mobile No'
                        name='phone'
                        value={phone}
                        onChange={e => onChange(e) }
                        minLength='10'
                    />
                </div>

                <div className='form-group'>
                    <input
                        className="form-control form-control-user" 
                        type='number'
                        placeholder='OTP'
                        name='OTP'
                        onChange={e => onChange(e) }
                        minLength='4'
                        maxLength='4'
                    />
                </div>
                <div className='d-flex flex-row'>
                    <button type='button' className='btn btn-primary btn-user btn-block mx-1 mt-2' onClick={e => requestOTP(e)}> {reSendOTP?'Re Send OTP':'Request OTP'} </button>
                    <input type='submit' className='btn btn-primary btn-user btn-block mx-1 mt-2' value='Login' />
                </div>
            </form>
        </div>
        </React.Fragment>
    )
}

Login.propTypes ={
    isAuthenticated: PropTypes.bool,
    setAlert: PropTypes.func.isRequired,
    getOTP: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect( mapStateToProps , { setAlert, getOTP, login })(Login);